import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import drawer
import ReactDrawer from 'react-drawer';

// import store and actions
import { connect } from 'react-redux'
import {
    showOrder, listOrders, resetOrders, setOrders, filterOrders, listAgents, listUsers, resetUsers
} from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import {
    APP_NAME, AGENTS_LIST, ORDERS_UNCOMPLETED, ORDERS_LIST, ORDERS_SHOW,
    ORDERS_TRANSFERT, USER_AGENT, USER_FACTOR, USER_KEY, USER_SUPERVISOR, FACTORS_LIST, ORDERS_ASSIGN
} from 'helpers/constants'

// import custom components
// import DayFactors from './DayFactors'
import OrderList from 'pages/orders/OrderList';
import OrderDetails from 'pages/orders/OrderDetails';
import OrderAssign from 'pages/orders/OrderAssign';
import OrderForward from 'pages/orders/OrderForward';

// import socket
import { socket } from 'helpers/socket';

// import utilities
import { consoleErrorMessage } from 'helpers/utilities';


class DailyOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            detailsVisible: false,
            assignVisible: false,
            forwardVisible: false,
            factorsVisible: false,
            position: 'right'
        }
    }

    componentDidMount() {
        try {
            // Load orders
            this.onLoaded()
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {// Check state updates
            if ((!equal(prevProps, this.props))) {

            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            let { onResetOrders, onResetFactors } = this.props
            onResetOrders(true)
            onResetFactors(true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "detailsVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
    * On drawer opened.
    * 
    */
    onDrawerOpened = () => {
        this.setState({
            position: 'right',
            factorsVisible: !this.state.factorsVisible
        });
    }

    /**
    * On drawer closed.
    * 
    */
    onDrawerClosed = () => {
        this.setState({ factorsVisible: false });
    }

    /**
     * On Loaded.
     * 
     */
    onLoaded = () => {
        try {
            // Get props values
            let { onListOrders, onResetOrders } = this.props

            // Reset values
            onResetOrders(true)

            // Load orders
            onListOrders(ORDERS_UNCOMPLETED.key, ORDERS_LIST)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On filtered.
     * 
     * @param {*} selected 
     */
    onFiltered = (selected) => {
        try {
            //Get props
            let { filter, onFilterOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Save filter
            onFilterOrders(selected, (selected.key === filter.key))
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On searched.
     *  
     * @param {*} searches 
     * @returns 
     */
    onSearched = (searches) => {
        try {
            //Get props
            let { filter, onSetOrders, onFilterOrders } = this.props

            //Reset filters
            if (filter && filter.key) onFilterOrders(filter, true)

            //Search orders
            onSetOrders({ filtereds: searches })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On showed.
     * 
     * @param {*} selected 
     */
    onShowed = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ order: { _id: selected }, option: ORDERS_SHOW })

            //Show modal
            this.onToggled("detailsVisible", true);
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On assigned.
     * 
     */
    onAssigned = () => {
        try {
            //Get props
            let { onResetOrders, onSetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init option
            onSetOrders({ option: ORDERS_ASSIGN })

            //Show modal
            this.onToggled("assignVisible", true);
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
    * On transferred.
    * 
    */
    onTransferred = () => {
        try {
            //Get props
            let { onResetOrders, onSetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init option
            onSetOrders({ option: ORDERS_TRANSFERT })

            //Show modal
            this.onToggled("forwardVisible", true);
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
    * On list factors.
    *  
    */
    onListed = () => {
        try {
            //Get props
            let { onListFactors, factors } = this.props

            //Load factors
            if (factors.length <= 0) onListFactors(FACTORS_LIST)

            //Show drawer
            this.onDrawerOpened();
        } catch (error) {
            consoleErrorMessage(error)
        }
    }


    render() {
        const {
            t, alls, orders, order, filter, loading, success, error, option, factors, fac_loading, fac_option, fac_error, fac_success
        } = this.props
        const { detailsVisible, assignVisible, forwardVisible, factorsVisible, position } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Courses à traiter")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Courses à traiter")}
                            subtitle={(alls.length > 0) ? (`(${alls.length})`) : ""}
                        />

                        <Row className="mb-2">
                            {((error != "") && (option === ORDERS_LIST)) ? (
                                <Col xl="12">
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                </Col>
                            ) : (loading && (option === ORDERS_LIST)) ? (
                                <Col xl="12">
                                    <Loader color="primary" />
                                </Col>
                            ) : (
                                <OrderList
                                    alls={alls}
                                    orders={orders}
                                    order={order}
                                    filter={filter}
                                    onShowed={this.onShowed}
                                    onSearched={this.onSearched}
                                    onFiltered={this.onFiltered}
                                    onRefreshed={this.onLoaded}
                                    showRefresh
                                />
                            )}
                        </Row>
                    </Container>
                </div>
                {(assignVisible) ? (
                    <OrderAssign
                        opened={assignVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
                {(forwardVisible) ? (
                    <OrderForward
                        opened={forwardVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
                {(detailsVisible) ? (
                    <OrderDetails
                        opened={detailsVisible}
                        onClosed={this.onToggled}
                        onTransferred={this.onTransferred}
                        onAssigned={this.onAssigned}
                    />
                ) : null}
                {/* <ReactDrawer
                    open={factorsVisible}
                    position={position}
                    onClose={this.onDrawerClosed}
                >
                    <DayFactors
                        factors={factors}
                        loading={[AGENTS_LIST].includes(option) ? loading : false}
                        option={[AGENTS_LIST].includes(option) ? option : ""}
                        error={[AGENTS_LIST].includes(option) ? error : ""}
                        onClosed={this.onDrawerClosed}
                    />
                </ReactDrawer> */}
            </React.Fragment>
        )
    }

}
DailyOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    alls: PropTypes.array,
    orders: PropTypes.array,
    order: PropTypes.object,
    filter: PropTypes.object,
    onListAgents: PropTypes.func,
    onListOrders: PropTypes.func,
    onSetOrders: PropTypes.func,
    onResetOrders: PropTypes.func,
    onFilterOrders: PropTypes.func,
    onShowOrder: PropTypes.func
}
const mapStateToProps = state => ({
    alls: state.commandes.orders,
    orders: state.commandes.filtereds,
    agents: state.commandes.agents,
    order: state.commandes.order,
    filter: state.commandes.filter,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
    factors: state.utilisateurs.factors,
    fac_option: state.utilisateurs.option,
    fac_loading: state.utilisateurs.loading,
    fac_error: state.utilisateurs.error,
    fac_success: state.utilisateurs.success
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onListOrders: (filter, option) => dispatch(listOrders(filter, false, option)),
    onFilterOrders: (filter, reset) => dispatch(filterOrders(filter, reset)),
    onShowOrder: (id, option) => dispatch(showOrder(id, option)),
    onListAgents: (option) => dispatch(listAgents(USER_AGENT, true, option)),
    onListFactors: (option) => dispatch(listUsers(USER_FACTOR, "", true, false, option)),
    onResetFactors: (all) => dispatch(resetUsers(all))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(DailyOrders)))