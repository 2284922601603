// LIST, GET, INSERT, UPDATE
export const ISSUES_SELECT = "ISSUES_SELECT"
export const ISSUES_GET = "ISSUES_GET"
export const ISSUES_INSERT = "ISSUES_INSERT"
export const ISSUES_UPDATE = "ISSUES_UPDATE"

// ERROR, SUCCESS, SET, RESET
export const ISSUES_ERROR = "ISSUES_ERROR"
export const ISSUES_SUCCESS = "ISSUES_SUCCESS"
export const ISSUES_SET = "ISSUES_SET"
export const ISSUES_RESET = "ISSUES_RESET"