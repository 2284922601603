import { LOGGING_ERROR, LOGGING_RESET, LOGGING_SELECT, LOGGING_SET, LOGGING_SUCCESS } from "./constants"


export const listLogging = (start_date, end_date, user_id, is_root, list, option) => ({
    type: LOGGING_SELECT,
    payload: { start_date, end_date, user_id, is_root, list, option },
})

export const successLogging = (data, option) => ({
    type: LOGGING_SUCCESS,
    payload: { data, option },
})

export const errorLogging = (error = "", option = "") => ({
    type: LOGGING_ERROR,
    payload: { error, option },
})

export const setLogging = (data) => ({
    type: LOGGING_SET,
    payload: { ...data },
})

export const resetLogging = (all) => ({
    type: LOGGING_RESET,
    payload: { all },
})