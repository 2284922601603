import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet";

// import link
import { Link } from "react-router-dom"

// import components
import { Container, Row, Col } from "reactstrap"

// import constants
import { APP_NAME } from 'helpers/constants'

// import images
import error from "../../assets/images/error.png"


const NoMatch = () => {
    return (
        <React.Fragment>
            <div className="pt-3">
                <Helmet>
                    <title>{APP_NAME} | Page introuvable</title>
                </Helmet>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-1">
                                <h1 className="display-2 fw-medium">
                                    4
                                    <i className="bx bx-buoy bx-spin text-primary display-3" />
                                    4
                                </h1>
                                <h4 className="text-uppercase">
                                    Oups!
                                </h4>
                                <p className="mb-0 mt-3">
                                    Il semblerait que vous tentez d'accéder à
                                    une page inexistante sur la plateforme
                                </p>
                                <div className="mt-3 text-center">
                                    <Link className="btn btn-primary" to="/dashboard">
                                        Tableau de bord
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="8" xl="6">
                            <img src={error} alt="" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default NoMatch