import React from "react"
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from "reactstrap"

// import utilities
import { formatPhoneNumberForDisplay, upperFirst } from "helpers/utilities"


const AgencyCard = React.memo(({ opened, option, classes, error, loading, onClosed, agency }) => {

    return (
        <Modal isOpen={opened} centered={true} className={classes}>
            <ModalHeader tag="h4">
                Détails d'une agence
            </ModalHeader>
            <ModalBody>
                <Row className="mb-3">
                    <Col xl={3} sm={12}>
                        <span className="text-muted fs-12">
                            Nom
                        </span>
                    </Col>
                    <Col xl={9} sm={12}>
                        <span className="text-justify fs-14">
                            {agency.name}
                        </span> {" "}
                        {agency.enabled ? (
                            <span className={`badge bg-soft bg-success text-success fw-bold fs-12`}>
                                Active
                            </span>
                        ) : (
                            <span className={`badge bg-soft bg-secondary text-secondary fw-bold fs-12`}>
                                Inactive
                            </span>
                        )} {" "}
                        {agency.contact_point ? (
                            <span className={`badge bg-soft bg-primary text-primary fw-bold fs-12`}>
                                Point contact
                            </span>
                        ) : (
                            <span className={`badge bg-soft bg-secondary text-secondary fw-bold fs-12`}>
                                Point relais
                            </span>
                        )}
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xl={3} sm={12}>
                        <span className="text-muted fs-12">
                            Description
                        </span>
                    </Col>
                    <Col xl={9} sm={12}>
                        <p className="text-justify mb-0 fs-14">
                            {upperFirst(agency.description)}
                        </p>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xl={3} sm={12}>
                        <span className="text-muted fs-12">
                            Contact
                        </span>
                    </Col>
                    <Col xl={9} sm={12}>
                        <span className="text-justify fs-14">
                            {formatPhoneNumberForDisplay(agency.phone)}
                        </span>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col xl={3} sm={12}>
                        <span className="text-muted fs-12">
                            Identifiant
                        </span>
                    </Col>
                    <Col xl={9} sm={12}>
                        <span className="text-justify fs-14">
                            {agency.username}
                        </span>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-light"
                    onClick={() => onClosed("cardVisible", false)}
                >
                    Fermer
                </button>
            </ModalFooter>
        </Modal>
    );

});
AgencyCard.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func,
    agency: PropTypes.object,
}
AgencyCard.defaultProps = {
    opened: false,
    classes: "agencies-card-modal"
}
export default AgencyCard