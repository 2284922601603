import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Label } from "reactstrap"

// import form components
import { AvForm, AvField } from "availity-reactstrap-validation"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import store and actions
import { connect } from 'react-redux'
import { assignOrder, listUsers, resetOrders, setOrders } from 'store/actions'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import Loader, Selecter
import Selecter from "components/common/Selecter"
import Loader from "components/common/Loader"

// import constants
import {
    ORDERS_UNCOMPLETED, ORDERS_ASSIGN, ORDERS_SHOW, USER_FACTOR, FACTORS_LIST
} from 'helpers/constants'

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'


class OrderAssign extends React.Component {

    componentDidMount() {
        try {//Load assignables factors
            let { onListFactors, assignables } = this.props
            if (assignables.length <= 0) onListFactors(FACTORS_LIST)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onClosed } = this.props

                if (success && (option === ORDERS_ASSIGN)) {
                    // Display toasts
                    toast.success("Nouvelle assignation effectuée avec succès.")

                    // Close modals
                    onClosed("assignVisible", false)
                }
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            let { onResetOrders, onSetOrders } = this.props
            onResetOrders(false)
            onSetOrders({ option: ORDERS_SHOW })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {
            let { order, option, onAssignOrder } = this.props

            if (option === ORDERS_ASSIGN) {
                if (order.selected_user) {
                    onAssignOrder({
                        to: order.selected_user._id
                    }, order._id, option)
                }
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On selected.
     * 
     * @param {*} selected 
     * @param {*} name 
     */
    onSelected = (selected, name) => {
        try {
            //Get props
            let { order, onSetOrders, onResetOrders } = this.props

            //Reset values
            onResetOrders(false)

            //Store values
            onSetOrders({
                order: { ...order, [name]: selected },
                option: ORDERS_ASSIGN
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }


    render() {
        const { opened, option, classes, error, loading, success, order, assignables, fac_loading, fac_option, onClosed } = this.props
        const { code, created_at, start_point, delivery_point, delivery_auth, current_status } = order

        return (
            <Modal isOpen={opened} centered={true} className={classes}>
                <AvForm onValidSubmit={this.onSubmited}>
                    <ModalHeader tag="h4">
                        Assigner une course
                        {(created_at && code) ? (
                            <span className="d-block fs-12 text-secondary">
                                Course {" "}
                                <strong> #{code} </strong>
                                du {" "}
                                <strong>
                                    {`${moment.unix(created_at).format('DD MMM YYYY HH:mm')}`}
                                </strong>
                            </span>
                        ) : null}
                    </ModalHeader>
                    <ModalBody>
                        <Row className="my-2">
                            {(error && (error !== "") && (option === ORDERS_ASSIGN)) ? (
                                <Col sm="12">
                                    <Alert color="danger" className="mb-0">
                                        {error}
                                    </Alert>
                                </Col>
                            ) : null}

                            <Col sm="12">
                                <div className="form-group">
                                    <Label htmlFor="selected_user">
                                        Nouveau facteur en charge de la course
                                    </Label>
                                    <Selecter
                                        selected={order.selected_user || null}
                                        onSelected={this.onSelected}
                                        options={assignables}
                                        id="selected_user"
                                        name="selected_user"
                                        placeholder="Sélectionner le facteur..."
                                        loading={fac_loading && (fac_option === FACTORS_LIST)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {(loading && (option === ORDERS_ASSIGN)) ? (
                            <Loader color="primary" />
                        ) : (
                            <>
                                <button
                                    type="button" data-dismiss="modal" className="btn btn-light"
                                    onClick={() => onClosed("assignVisible", false)}
                                >
                                    Fermer
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary save-assign"
                                >
                                    Assigner
                                </button>
                            </>
                        )}
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

}
OrderAssign.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func,
    order: PropTypes.object,
    assignables: PropTypes.array
}
OrderAssign.defaultProps = {
    opened: false,
    classes: "order-assign-modal"
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
    assignables: state.utilisateurs.factors,
    fac_option: state.utilisateurs.option,
    fac_loading: state.utilisateurs.loading
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onAssignOrder: (data, id, option) => dispatch(assignOrder(data, id, ORDERS_UNCOMPLETED.key, option)),
    onListFactors: (option) => dispatch(listUsers(USER_FACTOR, "", true, false, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderAssign)
