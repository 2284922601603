import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Card, CardBody } from "reactstrap"

// import custom table component, constants
import CustomDatatable, { CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM } from 'components/lists/CustomDatatable';

// import utilities
import { formatExportedFilename, formatPhoneNumberForDisplay } from 'helpers/utilities';


const ActiveAgencyTable = ({ items, start, end }) => {

    const columns = [
        {
            prop: "name",
            title: "Nom",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className="wrapped-text fw-bold">
                        {row.name}
                    </span>
                );
            }
        },
        {
            prop: "phone",
            title: "Contact",
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span>
                        {formatPhoneNumberForDisplay(row.phone)}
                    </span>
                );
            }
        },
        {
            prop: "contact_point",
            title: "",
            cellProps: {
                className: row => "text-center"
            },
            cell: row => {
                return (
                    <>
                        {row.contact_point ? (
                            <span className="badge bg-soft bg-primary text-primary fw-bold fs-12">
                                Point contact
                            </span>
                        ) : (
                            <span className="badge bg-soft bg-secondary text-secondary fw-bold fs-12">
                                Point relais
                            </span>
                        )}
                    </>
                );
            }
        },
    ];

    const headers = [
        { label: "Nom", key: "name" },
        { label: "Contact", key: "phoneNumber" },
        { label: "Adresse", key: "description" },
        { label: "Type", key: "type" }
    ];

    const formatedData = items.map((item) => ({
        ...item,
        phoneNumber: formatPhoneNumberForDisplay(item.phone),
        type: (item.contact_point ? "Point Contact" : "Point Relais")
    }))

    const formatedFilename = formatExportedFilename("agences_actives", start, end)

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CustomDatatable
                        tableHeaders={columns}
                        tableBody={items}
                        classes={CLASSES}
                        rowsPerPage={TOTAL_PER_PAGE_SM}
                        rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                        initialSort={{ prop: '', isAscending: true }}
                        labels={{ ...LABELS, entries: `sur ${items.length} agences actives` }}
                        exportOptions={{
                            exportAction: true,
                            label: "Exporter",
                            filename: `${formatedFilename}.csv`,
                            headers: headers,
                            data: formatedData
                        }}
                        leftOptions={{ leftAction: false, label: "", onClicked: {} }}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
ActiveAgencyTable.propTypes = {
    items: PropTypes.array,
    onExporting: PropTypes.func,
}
export default ActiveAgencyTable