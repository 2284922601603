import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

// import translations data
import translationFr from "./locales/fr/translation.json"
import translationENG from "./locales/en/translation.json"

// import contants
import { I18N_KEY } from "helpers/constants"

// the translations
const resources = {
  fr: {
    translation: translationFr,
  },
  eng: {
    translation: translationENG,
  },
}

const language = localStorage.getItem(I18N_KEY)
if (!language) localStorage.setItem(I18N_KEY, "fr")

i18n.use(detector).use(initReactI18next).init({
  resources,
  lng: localStorage.getItem(I18N_KEY) || "fr",
  fallbackLng: "fr", // use fr if detected lng is not available

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n
