import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useKKiaPay } from 'kkiapay-react';

// import utilities
import { consoleErrorMessage } from 'helpers/utilities';

const PayButton = ({ label, amount, email, phone, orderCode, classes, onPayed }) => {
    const { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } = useKKiaPay();

    function successHandler(response) {
        onPayed(response)
    }

    function errorHandler(error) {
        consoleErrorMessage(error)
    }

    useEffect(() => {
        addKkiapayListener('success', successHandler)
        addKkiapayListener('failed', errorHandler)
        return () => {
            removeKkiapayListener('success', successHandler)
            removeKkiapayListener('failed', errorHandler)
        };
    }, [addKkiapayListener, removeKkiapayListener]);

    const open = () => {
        openKkiapayWidget({
            amount: process.env.REACT_APP_ENV_PROD === "true" ? amount : 1,
            api_key: process.env.REACT_APP_KKIAPAY_API_KEY,
            sandbox: process.env.REACT_APP_ENV_PROD === "false",
            email: email,
            phone: phone,
            partnerId: orderCode
        });
    }

    return (
        <React.Fragment>
            <button type="button" onClick={open} className={classes}>
                {label}
            </button>
        </React.Fragment>
    )

}

PayButton.propTypes = {
    label: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    orderCode: PropTypes.string,
    classes: PropTypes.string,
    amount: PropTypes.number,
    onPayed: PropTypes.func,
}
PayButton.defaultProps = {
    label: "Payer",
    email: "",
    phone: "",
    orderCode: "",
    classes: "btn btn-primary",
    amount: 1,
}
export default PayButton