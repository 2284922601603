import React from 'react'
import PropTypes from 'prop-types'

// import deep-equal
import equal from "deep-equal"

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
	if ((to - from + 1) < 0) return []
	return Array(to - from + 1).fill().map((_, idx) => from + idx)
}

class Paginator extends React.Component {

	constructor(props) {
		super(props)

		//Init pagination
		this.setPaginationValues()

		//Launch pagination
		this.state = { currentPage: 1 }
	}

	componentDidMount() {
		try {
			//Got to page
			this.gotoPage(1);
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	componentDidUpdate(prevProps) {
		try {
			if ((!equal(prevProps, this.props))) {
				//Init pagination
				this.setPaginationValues()

				//Launch pagination
				this.setState({ currentPage: 1 }, () => this.gotoPage(1))
			}
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	/**
	 * Init pagination values.
	 * 
	 */
	setPaginationValues() {
		const { totalItems, itemsPerPage, pageNeighbours } = this.props

		this.itemsPerPage = typeof (itemsPerPage === 'number') ? itemsPerPage : 10
		this.totalItems = typeof (totalItems === 'number') ? totalItems : 0
		this.pageNeighbours = typeof (pageNeighbours === 'number') ? Math.max(0, Math.min(pageNeighbours, 2)) : 0
		this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage)
	}

	/**
	 * Go to page [page].
	 * 
	 * @param {*} page 
	 */
	gotoPage = (page) => {
		const { onPageChanged } = this.props
		const currentPage = Math.max(0, Math.min(page, this.totalPages))
		const paginationData = { currentPage, totalPages: this.totalPages }

		this.setState({ currentPage }, () => onPageChanged(paginationData))
	}

	/**
	 * On Clicked.
	 * 
	 * @param {*} evt 
	 * @param {*} page 
	 */
	onClicked = (evt, page) => {
		evt.preventDefault();
		this.gotoPage(page);
	}

	/**
	 * On nexted.
	 * 
	 * @param {*} evt 
	 */
	onNexted = (evt) => {
		evt.preventDefault();
		this.gotoPage(this.state.currentPage - (this.pageNeighbours * 2) - 1);
	}

	/**
	 * On previoused.
	 * 
	 * @param {*} evt 
	 */
	onPrevioused = (evt) => {
		evt.preventDefault();
		this.gotoPage(this.state.currentPage + (this.pageNeighbours * 2) + 1);
	}

	/**
	 * Get pages numbers array.
	 * 
	 * @returns array
	 */
	fetchPageNumbers = () => {
		const totalPages = this.totalPages;
		const currentPage = this.state.currentPage;
		const pageNeighbours = this.pageNeighbours;

		/**
		 * totalNumbers: the total page numbers to show on the control
		 * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
		 */
		const totalNumbers = (this.pageNeighbours * 2) + 3;
		const totalBlocks = totalNumbers + 2;

		if (totalPages > totalBlocks) {
			const startPage = Math.max(2, currentPage - pageNeighbours);
			const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
			let pages = range(startPage, endPage);

			/**
			 * hasLeftSpill: has hidden pages to the left
			 * hasRightSpill: has hidden pages to the right
			 * spillOffset: number of hidden pages either to the left or to the right
			 */
			const hasLeftSpill = startPage > 2;
			const hasRightSpill = (totalPages - endPage) > 1;
			const spillOffset = totalNumbers - (pages.length + 1);

			if (hasLeftSpill && !hasRightSpill) {
				const extraPages = range(startPage - spillOffset, startPage - 1);
				pages = [LEFT_PAGE, ...extraPages, ...pages];
			} else if (!hasLeftSpill && hasRightSpill) {
				const extraPages = range(endPage + 1, endPage + spillOffset);
				pages = [...pages, ...extraPages, RIGHT_PAGE];
			} else {
				pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
			}

			return [1, ...pages, totalPages];
		}
		return range(1, totalPages)
	}

	render() {

		if (!this.totalItems || this.totalPages === 1) return null;

		const { currentPage } = this.state;

		const { paginationClasses } = this.props;

		const pages = this.fetchPageNumbers();

		return (
			<React.Fragment>
				<nav aria-label="Paginator">
					<ul className={`pagination ${paginationClasses}`}>
						{pages.map((page, index) => {
							if (page === LEFT_PAGE) return (
								<li key={index} className="page-item">
									<a className="page-link" href="#" aria-label="Previous" onClick={this.onPrevioused}>
										<span aria-hidden="true">&laquo;</span>
									</a>
								</li>
							);

							if (page === RIGHT_PAGE) return (
								<li key={index} className="page-item">
									<a className="page-link" href="#" aria-label="Next" onClick={this.onNexted}>
										<span aria-hidden="true">&raquo;</span>
									</a>
								</li>
							);

							return (
								<li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
									<a className="page-link" href="#" onClick={(e) => this.onClicked(e, page)}>
										{page}
									</a>
								</li>
							);
						})}
					</ul>
				</nav>
			</React.Fragment>
		)
	}

}
Paginator.propTypes = {
	totalItems: PropTypes.number.isRequired,
	itemsPerPage: PropTypes.number,
	pageNeighbours: PropTypes.number,
	paginationClasses: PropTypes.string,
	onPageChanged: PropTypes.func
}
export default Paginator