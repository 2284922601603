import React from "react"
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Input, Label } from "reactstrap"

// import form components
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"

// Form Mask
import InputMask from "react-input-mask"

// import custom components
import Loader from '../../components/common/Loader'
import Selecter from "components/common/Selecter"

// import constants
import {
    AGENCIES_ADD, AGENCIES_CONTACT, AGENCIES_EDIT, AGENCIES_RELAY, PASSWORD_MESSAGE, MAX_TEXT_INPUT, MIN_TEXT_INPUT
} from "helpers/constants"


const AgencyForm = React.memo(({ opened, option, classes, error, loading, onClosed, onSubmited, onChecked, onChanged, onSelected, onGenerated, agency }) => {

    return (
        <Modal isOpen={opened} centered={true} className={classes}>
            <ModalHeader tag="h4">
                {(option === AGENCIES_EDIT) ? "Modifier une agence" : "Ajouter une agence"}
            </ModalHeader>
            <AvForm onValidSubmit={onSubmited}>
                <ModalBody>
                    <Row>
                        {(error && error !== "") ? (
                            <Col xl="12">
                                <Alert color="danger">{error}</Alert>
                            </Col>
                        ) : null}

                        <Col xl="7" className="mb-3">
                            <AvField
                                name="name"
                                label="Nom"
                                type="text"
                                value={agency.name || ""}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Le nom est obligatoire.',
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`Le nom a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                        </Col>

                        <Col xl="5" className="mb-3">
                            <AvField
                                type="text"
                                name="phone"
                                label="Numéro de téléphone"
                                value={agency.phone || ""}
                                mask="(+22\9) 99 99 99 99"
                                maskChar="-"
                                tag={[Input, InputMask]}
                                validate={{
                                    minLength: {
                                        value: 8,
                                        errorMessage: 'Le numéro de téléphone a au moins 8 caractères.'
                                    },
                                    required: {
                                        value: true,
                                        errorMessage: 'Le numéro de téléphone est obligatoire.',
                                    },
                                }}
                            />
                        </Col>

                        <Col xl="7" className="mb-3">
                            <div className="form-group">
                                <label htmlFor="selected_type">
                                    Type de l'agence
                                </label>
                                <Selecter
                                    selected={agency.selected_type || null}
                                    onSelected={onSelected}
                                    options={[AGENCIES_RELAY, AGENCIES_CONTACT]}
                                    id="selected_type"
                                    name="selected_type"
                                    placeholder="Sélectionner un type..."
                                    valueAttribut="key"
                                    labelAttribut="name"
                                />
                            </div>
                        </Col>

                        <Col xl="5" className="mb-3">
                            <AvField
                                name="username"
                                label="Identifiant"
                                type="text"
                                value={agency.username || ""}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'L\'identifiant est obligatoire.',
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`L\'identifiant a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                        </Col>

                        <Col xl="12" className="mb-3">
                            <AvField
                                name="description"
                                label="Description"
                                type="textarea"
                                rows="3"
                                value={agency.description || ""}
                                onChange={(e) => onChanged(e)}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "La description est obligatoire.",
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`La description a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    },
                                    maxLength: {
                                        value: MAX_TEXT_INPUT,
                                        errorMessage: (`La description a au plus ${MAX_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                            {(agency.description && (agency.description != "")) ? (
                                <span className="badgecount badge bg-secondary">
                                    {" "}
                                    {`${agency.description.length}`} / {MAX_TEXT_INPUT}{" "}
                                </span>
                            ) : null}
                        </Col>

                        <Col xl="12" className="mb-3">
                            <AvGroup>
                                <Label> Mot de passe </Label>
                                <div className="input-group">
                                    <AvInput
                                        required={(option === AGENCIES_ADD)}
                                        name="password"
                                        label="Mot de passe"
                                        type="text"
                                        aria-describedby="password-help-block"
                                        aria-describedby="addon-generate-password"
                                        value={agency.password || ""}
                                    />
                                    <span
                                        id="addon-generate-password"
                                        className="input-group-text h-cursor"
                                        onClick={() => onGenerated()}
                                    >
                                        <i className="text-muted bx bx-xs bx-revision" />
                                    </span>
                                    <span id="password-help-block" className="form-text">
                                        {PASSWORD_MESSAGE}
                                    </span>
                                </div>
                            </AvGroup>
                        </Col>

                        <Col xl="12" className="mb-1">
                            <div className="form-group">
                                <div className={`custom-check${(agency.enabled) ? " active" : ""}`}>
                                    <div className="form-check form-switch form-switch-md check-control">
                                        <input
                                            name="enabled"
                                            type="checkbox"
                                            className="form-check-input"
                                            id="enabledSwitch"
                                            checked={agency.enabled || false}
                                            onChange={(e) => onChecked(e)}
                                        />
                                        <label className="form-check-label" htmlFor="enabledSwitch">
                                            <span className={`${(agency.enabled) ? "fw-bolder" : "fw-light"}`}>
                                                {(agency.enabled) ? "Active" : "Active"}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {(loading) ? (
                        <Loader color={`${(option === AGENCIES_EDIT) ? "warning" : "info"}`} />
                    ) : (
                        <>
                            <button type="button" data-dismiss="modal" className="btn btn-light" onClick={() => onClosed()}>
                                Fermer
                            </button>
                            <button type="submit" className={`btn btn-${(option === AGENCIES_EDIT) ? "warning" : "info"} save-agency`}>
                                {
                                    (option == AGENCIES_EDIT) ? "Modifier" : "Ajouter"
                                }
                            </button>
                        </>
                    )}
                </ModalFooter>
            </AvForm>
        </Modal>
    );

});
AgencyForm.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onChanged: PropTypes.func,
    onChecked: PropTypes.func,
    onClosed: PropTypes.func,
    onSubmited: PropTypes.func,
    onGenerated: PropTypes.func,
    onSelected: PropTypes.func,
    agency: PropTypes.object
}
AgencyForm.defaultProps = {
    opened: false,
    classes: "agencies-form-modal"
}
export default AgencyForm