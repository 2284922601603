import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorUsers, successUsers } from "./actions"
import { isUnauthorized } from "store/actions"

// import constants
import { USERS_GET, USERS_INSERT, USERS_SELECT, USERS_UPDATE } from "./constants"

// import helpers constants
import { USER_FACTOR, USER_KEY } from "helpers/constants"

// import errors
import { errorMessage, isUnauthorizedError } from "helpers/erreurs"

// import utilities
import { consoleErrorMessage, formatUserWithName, isSuccessfulRequest, isUnauthorizedRequest } from "helpers/utilities"

// import services
import { getUser, insertAgency, insertUser, selectUsers, updateAgency, updateUser } from "services/userService"

// Users errors messages
const USERS_ERRORS = {
    "LIST": {
        403: "Aucun utilisateur retrouvé."
    },
    "FORM": {
        403: "Opération non autorisée.",
        404: "L'utilisateur n'existe pas.",
        409: {
            email: "Un utilisateur ayant cet email existe déjà.",
            phone: "Un utilisateur ayant ce numéro de téléphone existe déjà.",
            username: "Un utilisateur ayant cet identifiant existe déjà.",
            "409": "Un utilisateur ayant ces informations existe déjà."
        },
    }
}


function* onSelectUsers({ payload }) {
    try {
        let { type, extraType, enabled_only, extra_enabled_only, option } = payload
        const response = yield call(selectUsers, type.key)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            let formated_list = (response.data ? enabled_only ? response.data.filter((item) => (item.enabled)) : response.data : [])
            formated_list = formated_list.map((item) => formatUserWithName(item))
            if (extraType === "") {
                yield put(successUsers({ [type.tag]: formated_list }, option))
            } else {
                const extra = yield call(selectUsers, extraType.key)
                let formated_extra = (extra.data ? extra_enabled_only ? extra.data.filter((item) => (item.enabled)) : extra.data : [])
                if (isSuccessfulRequest(extra.status, extra.statusText)) {
                    yield put(successUsers({ [type.tag]: formated_list, [extraType.tag]: formated_extra }, option))
                } else {
                    consoleErrorMessage(extra, "SELECT USERS ERROR")
                    let { unauth, message } = errorMessage(extra, USERS_ERRORS.LIST)
                    yield put(errorUsers((unauth ? "" : message), (unauth ? "" : option)))
                    if (unauth) yield put(isUnauthorized())
                }
            }
        } else {
            consoleErrorMessage(response, "SELECT USERS ERROR")
            let { unauth, message } = errorMessage(response, USERS_ERRORS.LIST)
            yield put(errorUsers((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "SELECT USERS ERROR")
        yield put(errorUsers(errorMessage(error).message, payload.option))
    }
}


function* onGetUser({ payload }) {
    try {
        const response = yield call(getUser, payload.id)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successUsers((response.data ? response.data : {}), payload.option))
        } else {
            consoleErrorMessage(response, "GET USER ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorUsers((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET USER ERROR")
        yield put(errorUsers(errorMessage(error).message, payload.option))
    }
}


function* onInsertUser({ payload }) {
    try {
        const response = yield call(insertUser, payload.data, payload.type.tag)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectUsers, payload.type.key)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successUsers({ [payload.type.tag]: (list.data ? list.data : []) }, payload.option))
            } else {
                consoleErrorMessage(list, "INSERT USER ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorUsers((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "INSERT USER ERROR")
            let { unauth, message } = errorMessage(response, USERS_ERRORS.FORM)
            yield put(errorUsers((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "INSERT USER ERROR")
        yield put(errorUsers(errorMessage(error).message, payload.option))
    }
}


function* onUpdateUser({ payload }) {
    try {
        const response = yield call(updateUser, payload.id, payload.data, payload.type.tag)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            if (!payload.refresh_list) {
                yield put(successUsers((response.data ? response.data : {}), payload.option))
            } else {
                const list = yield call(selectUsers, payload.type.key)
                if (isSuccessfulRequest(list.status, list.statusText)) {
                    yield put(successUsers(
                        { [payload.type.tag]: (list.data ? list.data : []) }, payload.option
                    ))
                } else {
                    consoleErrorMessage(list, "UPDATE USER ERROR")
                    let { unauth, message } = errorMessage(list)
                    yield put(errorUsers((unauth ? "" : message), (unauth ? "" : payload.option)))
                    if (unauth) yield put(isUnauthorized())
                }
            }
        } else {
            consoleErrorMessage(response, "UPDATE USER ERROR")
            let { unauth, message } = errorMessage(response, USERS_ERRORS.FORM)
            yield put(errorUsers((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "UPDATE USER ERROR")
        yield put(errorUsers(errorMessage(error).message, payload.option))
    }
}


function* UserSaga() {
    yield takeEvery(USERS_SELECT, onSelectUsers)
    yield takeEvery(USERS_GET, onGetUser)
    yield takeEvery(USERS_INSERT, onInsertUser)
    yield takeEvery(USERS_UPDATE, onUpdateUser)
}

export default UserSaga