import React, { useState } from 'react'
import PropTypes from 'prop-types'

// import components
import { Alert, Col, Row } from 'reactstrap'

// import router link
import { Link } from 'react-router-dom'

// import constants
import { CLIPBOARD_MSG_DURATION, ORDERS_ADD } from 'helpers/constants'

// import custom common components
import Loader from 'components/common/Loader'
import PayButton from 'components/common/PayButton'

// import utilities
import { consoleErrorMessage, copyTextToClipboard, formatPhoneNumberForDisplay, getDeliveryAuthCode } from 'helpers/utilities'


const SaveTab = React.memo(({ order, loading, option, error, success, onRestarted, onPayed }) => {

    const { customer, customer_name, customer_type, customer_contact, code, delivery_auth, cost, payment } = order

    const orderDeliveryCode = getDeliveryAuthCode(delivery_auth)

    const [copies, setCopies] = useState({ isCodeCopied: false, isDeliveryCodeCopied: false });

     /**
     * on copy clicked.
     * 
     * @param {*} copyText 
     */
    const onCopyClicked = (copyText, option = 'isDeliveryCodeCopied') => {
        copyTextToClipboard(copyText).then(() => {
            setCopies({[option]: true});
            setTimeout(() => {
                setCopies({[option]: false});
            }, CLIPBOARD_MSG_DURATION);
        }).catch((error) => {
            consoleErrorMessage(error)
        });
    }

    return (
        <React.Fragment>
            <div style={{ minHeight: '350px' }}>
                {(loading && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <Loader color="primary" />
                            <center>
                                <p className="m-0 p-2">
                                    Enregistrement de la course. Veuillez patienter...
                                </p>
                            </center>
                        </Col>
                    </Row>
                ) : ((error !== "") && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <Alert color="danger" className="m-0 d-flex align-items-center justify-content-between">
                                {error}{" "}
                                <Link 
                                    to="#" 
                                    className="alert-link" 
                                    onClick={() => onPayed({transactionId: cost.transaction_id})}
                                >
                                    Réessayer
                                </Link>
                            </Alert>
                        </Col>
                    </Row>
                ) : (success && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title rounded-circle bg-success bg-soft">
                                        <i className="bx bx-check-double h1 mb-0 text-success" />
                                    </div>
                                </div>

                                <div className="p-2 mt-4">
                                    <h3>
                                        <small className="d-block text-muted fs-14 mt-1">
                                            Une course a été enregistrée {payment ? "et payée" : ""} pour le client 
                                            {" "} <strong>{customer_name}</strong>
                                        </small>
                                        {code ? (
                                            <span className="d-block mt-3" onClick={() => onCopyClicked(code, "isCodeCopied")}>
                                                <small className="d-block text-muted fs-12 mb-1">
                                                    Code de la course
                                                </small>
                                                <span 
                                                    className="fw-bolder fs-3 text-decoration-underline h-cursor" 
                                                    title="Cliquer pour copier" 
                                                >
                                                    {code}
                                                </span>
                                                {copies.isCodeCopied ? (
                                                    <small className="d-block text-success fs-12 mt-1">
                                                        *Code de la course copié avec succès
                                                    </small>
                                                ) : null}
                                            </span>
                                        ) : null}
                                        {orderDeliveryCode ? (
                                            <span className="d-block mt-3" onClick={() => onCopyClicked(orderDeliveryCode, "isDeliveryCodeCopied")}>
                                                <small className="d-block text-muted fs-12 mb-1">
                                                    Code de livraison
                                                </small>
                                                <span 
                                                    className="fw-bolder fs-3 text-decoration-underline h-cursor" 
                                                    title="Cliquer pour copier" 
                                                >
                                                    {orderDeliveryCode}
                                                </span>
                                                {copies.isDeliveryCodeCopied ? (
                                                    <small className="d-block text-success fs-12 mt-1">
                                                        *Code de livraison copié avec succès
                                                    </small>
                                                ) : null}
                                            </span>
                                        ) : null}
                                        <small className="d-block text-muted fs-12 mt-4">
                                            Un sms de notification sera envoyé au client sur le numéro {" "}
                                            <strong>{formatPhoneNumberForDisplay(customer_contact)}</strong>.
                                        </small>
                                    </h3>
                                </div>

                                {Boolean(!payment) && (
                                    <div className="mt-2">
                                        <p>Procéder au paiement de la course enregistrée</p>
                                        <PayButton
                                            amount={cost.total}
                                            orderCode={code}
                                            onPayed={onPayed}
                                            label="Payer et Continuer"
                                            classes="btn btn-accent"
                                        />
                                    </div>
                                )}

                                {Boolean(payment) && (
                                    <div className="d-flex align-items-center justify-content-center mt-2">
                                        <Link to="/agency-orders" className="btn btn-primary me-1">
                                            Liste des courses
                                        </Link>
                                        <Link
                                            to="#" className="btn btn-outline-primary ms-1"
                                            onClick={() => onRestarted()}
                                        >
                                            Nouvelle course
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                ) : null}
            </div>
        </React.Fragment>
    );
    
});
SaveTab.propTypes = {
    order: PropTypes.object,
}
SaveTab.defaultProps = {
    order: {},
}
export default SaveTab