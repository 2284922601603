import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import { addIssue, editIssue, listIssues, resetIssues, setIssues } from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import { APP_NAME, ISSUES_ADD, ISSUES_EDIT, ISSUES_LIST } from 'helpers/constants'

// import custom components
import IssueTable from './IssueTable'
import IssueForm from './IssueForm'

// import utilities
import { consoleErrorMessage, getConstantObject, getIssueGravity } from 'helpers/utilities'


class Issues extends React.Component {

    constructor(props) {
        super(props)

        this.state = { formVisible: false }
    }

    componentDidMount() {
        try {// Load issues
            this.onLoaded()
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onResetIssues } = this.props

                if (success) {
                    // Close modals
                    if ([ISSUES_ADD, ISSUES_EDIT].includes(option)) this.onToggled()

                    // Display toasts
                    if (option === ISSUES_ADD) {
                        toast.success("Nouvel incident ajouté avec succès.")
                    } else if (option === ISSUES_EDIT) {
                        toast.success("Incident mis à jour avec succès.")
                    }

                    //Reset global state
                    onResetIssues(false)
                }
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetIssues(true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "formVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On checked.
     * 
     * @param {*} event 
     */
    onChecked = (event) => {
        try {
            //Get target
            let { name, checked } = event.target

            //Get props
            let { issue, onSetIssues } = this.props

            //Store values
            onSetIssues({
                issue: { ...issue, [name]: checked }
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On selected.
     * 
     * @param {*} event 
     */
    onSelected = (selected, name) => {
        try {
            //Get props
            let { issue, onSetIssues } = this.props

            //Store values
            onSetIssues({
                issue: { ...issue, [name]: selected }
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            //Get target
            let { value, name } = event.target

            //Get props
            let { issue, onSetIssues } = this.props

            //Store values
            onSetIssues({
                issue: { ...issue, [`${name}Count`]: `${value.length}` }
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On submited.
     * 
     * @param {*} e 
     * @param {*} values 
     */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {//Start treatment
            let { onAddIssue, onEditIssue, option, issue } = this.props

            switch (option) {
                case ISSUES_ADD:
                    if (issue.selected_gravity) {
                        onAddIssue({
                            name: values.name.trim(),
                            severity: issue.selected_gravity.key,
                            enabled: issue.enabled
                        }, option)
                    }
                    break;
                case ISSUES_EDIT:
                    if (issue.selected_gravity) {
                        onEditIssue({
                            name: values.name.trim(),
                            severity: issue.selected_gravity.key,
                            enabled: issue.enabled
                        }, issue._id, option)
                    }
                    break;
                default:
                    console.log("UNKNOW MODAL OPTION " + option)
                    break;
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Add.
     * 
     */
    onAdding = () => {
        try {
            //Get props
            let { onSetIssues, onResetIssues } = this.props

            //Reset state
            onResetIssues(false)

            //Init state
            onSetIssues({
                issue: { enabled: true, nameCount: "" },
                option: ISSUES_ADD
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Edit.
     * 
     * @param {*} item 
     */
    onEditing = (item) => {
        try {
            //Get props
            let { onSetIssues, onResetIssues } = this.props

            //Reset state
            onResetIssues(false)

            //Init state
            onSetIssues({
                issue: {
                    ...item,
                    nameCount: `${item.name.length}`,
                    selected_gravity: item.gravity
                },
                option: ISSUES_EDIT
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On reload.
     * 
     */
    onLoaded = () => {
        try {// Load issues
            let { issues, onResetIssues, onListIssues } = this.props
            if (issues.length > 0) onResetIssues(true)
            onListIssues(ISSUES_LIST)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }


    render() {
        const { t, issues, issue, error, option, loading } = this.props
        const { formVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Incidents")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Incidents")}
                            subtitle={(issues.length > 0) ? (`(${issues.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === ISSUES_LIST)) ? (
                                    <Alert color="danger" className="d-flex align-item-center justify-content-between">
                                        {error} {" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (loading && (option === ISSUES_LIST)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <IssueTable
                                        items={issues}
                                        onAdding={this.onAdding}
                                        onEditing={this.onEditing}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(formVisible) ? (
                    <IssueForm
                        opened={formVisible}
                        option={[ISSUES_ADD, ISSUES_EDIT].includes(option) ? option : ""}
                        error={[ISSUES_ADD, ISSUES_EDIT].includes(option) ? error : ""}
                        loading={[ISSUES_ADD, ISSUES_EDIT].includes(option) ? loading : false}
                        onClosed={this.onToggled}
                        onSubmited={this.onSubmited}
                        onChanged={this.onChanged}
                        onChecked={this.onChecked}
                        onSelected={this.onSelected}
                        issue={issue}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
Issues.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    issues: PropTypes.array,
    issue: PropTypes.object,
    onListIssues: PropTypes.func,
    onSetIssues: PropTypes.func,
    onResetIssues: PropTypes.func,
    onAddIssue: PropTypes.func,
    onEditIssue: PropTypes.func
}
const mapStateToProps = state => ({
    issues: state.incidents.issues,
    issue: state.incidents.issue,
    error: state.incidents.error,
    option: state.incidents.option,
    success: state.incidents.success,
    loading: state.incidents.loading
})
const mapDispatchToProps = dispatch => ({
    onListIssues: (option) => dispatch(listIssues(option)),
    onSetIssues: (data) => dispatch(setIssues(data)),
    onResetIssues: (all) => dispatch(resetIssues(all)),
    onAddIssue: (data, option) => dispatch(addIssue(data, option)),
    onEditIssue: (data, id, option) => dispatch(editIssue(data, id, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Issues)))