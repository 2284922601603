import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import router
import { withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import components
import { Row, Col, Alert, Container, CardTitle, Label } from "reactstrap"

// import custom common components
import Loader from '../../components/common/Loader'

// import constants
import { PRICINGS_ADD, PRICINGS_EDIT, PRICINGS_LIST, PRICINGS_REMOVE } from "helpers/constants"

// import custom components
import AdditionalTable from './AdditionalTable'
import AdditionalForm from './AdditionalForm'


const AdditionalPricings = ({ formVisible, pricings, pricing, option, error, loading, onAdding, onEditing, onRemoving, onSubmited, onClosed, onChanged, onChecked, onClicked }) => {

    return (
        <React.Fragment>
            <Container fluid>
                <div className="p-5">
                    {((error != "") && (option === PRICINGS_LIST)) ? (
                        <Alert color="danger"> {error} </Alert>
                    ) : (loading && (option === PRICINGS_LIST)) ? (
                        <Loader color="primary" />
                    ) : (
                        <AdditionalTable
                            items={pricings}
                            onAdding={onAdding}
                            onEditing={onEditing}
                            onRemoving={onRemoving}
                        />
                    )}
                </div>
            </Container>
            {(formVisible) ? (
                <AdditionalForm
                    opened={formVisible}
                    modalOption={[PRICINGS_ADD, PRICINGS_EDIT, PRICINGS_REMOVE].includes(option) ? option : ""}
                    modalError={[PRICINGS_ADD, PRICINGS_EDIT, PRICINGS_REMOVE].includes(option) ? error : ""}
                    modalLoading={[PRICINGS_ADD, PRICINGS_EDIT, PRICINGS_REMOVE].includes(option) ? loading : false}
                    onClosed={onClosed}
                    onSubmited={onSubmited}
                    onChanged={onChanged}
                    onChecked={onChecked}
                    onClicked={onClicked}
                    additional={pricing}
                />
            ) : null}
        </React.Fragment>
    )
}
AdditionalPricings.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    pricings: PropTypes.array,
    pricing: PropTypes.any,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    formVisible: PropTypes.bool,
    onSubmited: PropTypes.func,
    onClosed: PropTypes.func,
    onClicked: PropTypes.func,
    onChanged: PropTypes.func,
    onChecked: PropTypes.func,
    onAdding: PropTypes.func,
    onEditing: PropTypes.func,
    onRemoving: PropTypes.func
}
AdditionalPricings.defaultProps = {
    formVisible: false
}
export default withRouter(withTranslation()(AdditionalPricings))