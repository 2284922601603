import {
	INVOICES_ERROR, INVOICES_GET, INVOICES_RESET, INVOICES_FIND, INVOICES_SELECT, INVOICES_SET,
	INVOICES_SUCCESS, INVOICES_UPDATE, INVOICES_FILTER, INVOICES_INSERT, INVOICES_GENERATE, INVOICES_SET_PAID
} from "./constants"

import { filterInvoicesByStatus } from "helpers/utilities"

const INITIAL_STATE = {
	filtereds: [],
	invoices: [],
	invoice: {},
	period: {},
	filter: {},
	error: "",
	option: "",
	loading: false,
	success: false
}

const factures = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case INVOICES_SELECT:
		case INVOICES_GET:
		case INVOICES_FIND:
		case INVOICES_UPDATE:
		case INVOICES_INSERT:
		case INVOICES_GENERATE:
		case INVOICES_SET_PAID:
			return {
				...state,
				error: "",
				option: payload.option,
				loading: true
			}

		case INVOICES_SUCCESS:
			return {
				...state,
				invoices: payload.data.invoices ? payload.data.invoices.reverse() : state.invoices,
				filtereds: payload.data.invoices ? payload.data.invoices.reverse() : state.filtereds,
				invoice: payload.data.invoice ? payload.data.invoice : state.invoice,
				period: payload.data.period ? payload.data.period : state.period,
				option: payload.option,
				loading: false,
				success: true
			}

		case INVOICES_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case INVOICES_FILTER:
			return {
				...state,
				filter: (payload.reset) ? INITIAL_STATE.filter : payload.filter,
				filtereds: (payload.reset) ? state.invoices : filterInvoicesByStatus(state.invoices, payload.filter.key)
			}

		case INVOICES_SET:
			return {
				...state,
				invoices: payload.invoices ? payload.invoices : state.invoices,
				filtereds: payload.filtereds ? payload.filtereds : state.filtereds,
				invoice: payload.invoice ? payload.invoice : state.invoice,
				period: payload.period ? payload.period : state.period,
				filter: payload.filter ? payload.filter : state.filter,
				error: payload.error ? payload.error : state.error,
				option: payload.option ? payload.option : state.option,
				loading: payload.loading ? payload.loading : state.loading,
				success: payload.success ? payload.success : state.success
			}

		case INVOICES_RESET:
			return {
				...state,
				filtereds: payload.all ? INITIAL_STATE.filtereds : state.filtereds,
				invoices: payload.all ? INITIAL_STATE.invoices : state.invoices,
				invoice: payload.all ? INITIAL_STATE.invoice : state.invoice,
				filter: payload.all ? INITIAL_STATE.filter : state.filter,
				period: payload.all ? INITIAL_STATE.period : state.period,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}
}

export default factures