import React from 'react'
import PropTypes from 'prop-types'


const Filter = ({ items, selected, classes, onFiltered }) => {

    return (
        <React.Fragment>
            <div className="toolbar button-items">
                {items.map((item, index) => (
                    <span key={`filter_button_${index}`}>
                        <button
                            type="button"
                            onClick={() => onFiltered(item)}
                            className={`btn btn-outline-light btn-sm my-1${(selected.key === item.key) ? " active" : ""} center-ibox`}
                        >
                            {item.name} {""}
                            {(item.icon && (item.icon !== "")) ? (
                                <i className={`bx ${item.icon} ms-1`} />
                            ) : null}
                            {((item.key !== selected.key) && item.totals) ? (
                                <span className={`badge bg-${item.color} ms-1 fw-bolder`}>
                                    {`${(item.totals >= 100) ? "99+" : `${item.totals}`}`}
                                </span>
                            ) : null}
                        </button>{" "}
                    </span>
                ))}
            </div>
        </React.Fragment>
    )

}
Filter.propTypes = {
    classes: PropTypes.string,
    items: PropTypes.array,
    selected: PropTypes.object,
    onFiltered: PropTypes.func.isRequired
}
Filter.defaultProps = {
    classes: "",
    items: [],
    selected: {}
}
export default Filter