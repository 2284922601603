import { all, fork } from "redux-saga/effects"

// Front
import LayoutSaga from "./layout/saga"

// Auth
import AuthSaga from "./auth/saga"

// Users
import UserSaga from "./users/saga"

// Customers
import CustomerSaga from "./customers/saga"

// Pricings
import PricingSaga from "./pricings/saga"

// Orders
import OrderSaga from "./orders/saga"

// Issues
import IssueSaga from "./issues/saga"

// Invoices
import InvoiceSaga from "./invoices/saga"

// Statistics
import StatisticSaga from "./statistics/saga"

// logging
import LoggingSaga from "./logging/saga"


export default function* rootSaga() {
	yield all([
		//public
		fork(LayoutSaga),
		fork(AuthSaga),
		fork(UserSaga),
		fork(CustomerSaga),
		fork(PricingSaga),
		fork(OrderSaga),
		fork(IssueSaga),
		fork(InvoiceSaga),
		fork(StatisticSaga),
		fork(LoggingSaga)
	])
}
