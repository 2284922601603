import { ISSUES_SELECT, ISSUES_GET, ISSUES_INSERT, ISSUES_UPDATE, ISSUES_RESET, ISSUES_SET, ISSUES_SUCCESS, ISSUES_ERROR } from "./constants"


export const listIssues = (option, param = false) => ({
    type: ISSUES_SELECT,
    payload: { option, param },
})

export const showIssue = (id, option) => ({
    type: ISSUES_GET,
    payload: { id, option },
})

export const addIssue = (data, option) => ({
    type: ISSUES_INSERT,
    payload: { data, option },
})

export const editIssue = (data, id, option) => ({
    type: ISSUES_UPDATE,
    payload: { data, id, option },
})

export const successIssues = (data, option) => ({
    type: ISSUES_SUCCESS,
    payload: { data, option },
})

export const errorIssues = (error = "", option = "") => ({
    type: ISSUES_ERROR,
    payload: { error, option },
})

export const setIssues = (data, replace = true) => ({
    type: ISSUES_SET,
    payload: { ...data, replace: replace },
})

export const resetIssues = (all) => ({
    type: ISSUES_RESET,
    payload: { all },
})