import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Card, CardBody } from "reactstrap"

// import custom table component, constants
import CustomDatatable, { CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM } from 'components/lists/CustomDatatable';

// import utilities
import { formatPhoneNumberForDisplay, upperFirst } from 'helpers/utilities';


const SupervisorTable = ({ items, onAdding, onEditing }) => {

    const columns = [
        {
            prop: "lastname",
            title: "Nom",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <span className="wrapped-text fw-bold">
                        {`${row.firstname} ${row.lastname}`}
                        <span className="d-block fs-12 fw-light">
                            @{row.username}
                        </span>
                    </span>
                );
            }
        },
        {
            prop: "phone",
            title: "Contact",
            filterable: true,
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <span> {formatPhoneNumberForDisplay(row.phone)} </span>
                );
            }
        },
        {
            prop: "email",
            title: "Email",
            filterable: true,
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <span> {row.email} </span>
                );
            }
        },
        // {
        //     prop: "username",
        //     title: "Identifiant",
        //     filterable: true,
        //     cellProps: {
        //         className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
        //     },
        //     cell: row => {
        //         return (
        //             <span> {row.username} </span>
        //         );
        //     }
        // },
        {
            prop: "menu",
            isDummyField: true,
            title: "",
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <i
                            className="bx bx-edit bx-xs bx-border text-secondary h-cursor"
                            id={`showtooltip_${row._id}`}
                            onClick={() => onEditing(row)}
                        />
                    </div>
                );
            }
        },
    ];

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CustomDatatable
                        tableHeaders={columns}
                        tableBody={items}
                        rowsPerPage={TOTAL_PER_PAGE_SM}
                        rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                        initialSort={{ prop: '', isAscending: true }}
                        labels={{ ...LABELS, entries: `sur ${items.length} superviseurs` }}
                        classes={CLASSES}
                        leftOptions={{ leftAction: true, label: "Nouveau Superviseur", onClicked: onAdding }}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
SupervisorTable.propTypes = {
    items: PropTypes.array,
    onAdding: PropTypes.func,
    onEditing: PropTypes.func
}
export default SupervisorTable