import { PRICINGS_DELETE, PRICINGS_ERROR, PRICINGS_GET, PRICINGS_GETCONFIGS, PRICINGS_INSERT, PRICINGS_RESET, PRICINGS_SELECT, PRICINGS_SET, PRICINGS_SETCONFIGS, PRICINGS_SUCCESS, PRICINGS_UPDATE } from "./constants"


const INITIAL_STATE = {
	pricings: [],
	pricing: {},
	configs: {},
	reset_configs: {},
	error: "",
	option: "",
	loading: false,
	success: false
}

const tarifications = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case PRICINGS_SELECT:
		case PRICINGS_GET:
		case PRICINGS_INSERT:
		case PRICINGS_UPDATE:
		case PRICINGS_DELETE:
		case PRICINGS_SETCONFIGS:
		case PRICINGS_GETCONFIGS:
			return {
				...state,
				error: "",
				option: payload.option,
				loading: true
			}

		case PRICINGS_SUCCESS:
			return {
				...state,
				pricings: Array.isArray(payload.data) ? payload.data.reverse() : state.pricings,
				pricing: payload.data._id ? payload.data : state.pricing,
				configs: payload.data.day_price ? payload.data : state.configs,
				reset_configs: payload.data.day_price ? payload.data : state.reset_configs,
				option: payload.option,
				loading: false,
				success: true
			}

		case PRICINGS_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case PRICINGS_SET:
			return {
				...state,
				pricings: payload.pricings ? payload.pricings : state.pricings,
				pricing: payload.pricing ? payload.pricing : state.pricing,
				configs: payload.configs ? payload.configs : state.configs,
				reset_configs: payload.reset_configs ? payload.reset_configs : state.reset_configs,
				error: payload.error ? payload.error : state.error,
				option: payload.option ? payload.option : state.option,
				loading: payload.loading ? payload.loading : state.loading,
				success: payload.success ? payload.success : state.success
			}

		case PRICINGS_RESET:
			return {
				...state,
				pricings: payload.all ? INITIAL_STATE.pricings : state.pricings,
				pricing: payload.all ? INITIAL_STATE.pricing : state.pricing,
				configs: payload.all ? INITIAL_STATE.configs : state.configs,
				reset_configs: payload.all ? INITIAL_STATE.reset_configs : state.reset_configs,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}
}

export default tarifications