import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Button as ReactstrapButton } from "reactstrap"

import { CSVLink } from "react-csv"

// import tables components
import {
    Pagination, PaginationOpts, TableHeader, TableBody, Filter,
    useDatatableLifecycle, shouldTableUpdate
} from 'react-bs-datatable';

// Pagination options
export const TOTAL_PER_PAGE = 10
export const TOTAL_PER_PAGE_OPTIONS = [10, 20, 30]

// Pagination options small
export const TOTAL_PER_PAGE_SM = 6
export const TOTAL_PER_PAGE_OPTIONS_SM = [6, 12, 24]

// Empty list
export const EMPTY_LIST = "La liste est vide."

// Custom styles
export const CLASSES = {
    table: 'table align-middle table-nowrap table-hover table-check responsive-md',
    thead: 'table-light text-center',
    paginationOptsFormControl: 'btn btn-light btn-pagination-options',
    filterInputGroup: 'mb-2',
    filterFormControl: 'search-control',
    filterClearButton: 'search-clear text-muted',
    paginationButton: 'btn-pagination'
};

// Custom labels
export const LABELS = {
    first: '<<',
    last: '>>',
    prev: '<',
    next: '>',
    show: 'Affichage de ',
    entries: 'éléments',
    noResults: 'Il n\'y a aucune donnée à afficher',
    filterPlaceholder: "Rechercher..."
};


const CustomDatatable = (props) => {

    const {
        data,
        rowsPerPageOption,
        tableHeaders,
        onChangeFilter,
        onPageNavigate,
        classes,
        onRowsPerPageChange,
        onSortChange,
        tableClass,
        labels,
        filterable,
        filterText,
        rowsPerPage,
        currentPage,
        sortedProp,
        maxPage,
        Components
    } = useDatatableLifecycle(props);

    return (
        <React.Fragment>
            <Components.Row className="controlRow__root mb-2">
                <Components.Col md="3" sm="12">
                    <Filter
                        classes={classes}
                        tableHeaders={tableHeaders}
                        placeholder={labels.filterPlaceholder}
                        onChangeFilter={onChangeFilter}
                        filterText={filterText}
                        filterable={filterable}
                        components={{
                            Adornment: Components.Adornment,
                            Button: Components.Button,
                            ClearIcon: Components.ClearIcon,
                            FormControl: Components.FormControl,
                            InputGroup: Components.InputGroup
                        }}
                    />
                </Components.Col>
                <Components.Col md="9" sm="12">
                    {(props.exportOptions && props.exportOptions.exportAction && props.exportOptions.data && (props.exportOptions.data.length > 0)) ? (
                        <div className="text-sm-end">
                            <CSVLink
                                data={props.exportOptions.data}
                                headers={props.exportOptions.headers}
                                filename={props.exportOptions.filename}
                                className="btn btn-success bg-gradient"
                            >
                                <i className="bx bx-download me-1" />
                                {props.exportOptions.label}
                            </CSVLink>
                        </div>
                    ) : null}
                    {(props.leftOptions && props.leftOptions.leftAction) ? (
                        <div className="text-sm-end">
                            <ReactstrapButton
                                type="button"
                                color="primary bg-gradient"
                                className="btn-block center-ibox mb-2"
                                onClick={() => props.leftOptions.onClicked()}
                            >
                                <i className="bx bx-plus-circle me-1" />
                                {props.leftOptions.label}
                            </ReactstrapButton>
                        </div>
                    ) : null}
                </Components.Col>
            </Components.Row>
            <Components.Row>
                <Components.Col xs="12">
                    <Components.Table className={tableClass}>
                        <TableHeader
                            classes={classes}
                            tableHeaders={tableHeaders}
                            sortedProp={sortedProp}
                            onSortChange={onSortChange}
                            components={{
                                TableHead: Components.TableHead,
                                TableCell: Components.TableCell,
                                TableRow: Components.TableRow
                            }}
                        />
                        <TableBody
                            classes={classes}
                            tableHeaders={tableHeaders}
                            labels={labels}
                            data={data}
                            components={{
                                TableBody: Components.TableBody,
                                TableCell: Components.TableCell,
                                TableRow: Components.TableRow
                            }}
                        />
                    </Components.Table>
                </Components.Col>
            </Components.Row>
            <Components.Row className="controlRow__root bottom">
                <Components.Col sm={12} md={6}>
                    <PaginationOpts
                        classes={classes}
                        labels={labels}
                        onRowsPerPageChange={onRowsPerPageChange}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOption={rowsPerPageOption}
                        components={{
                            Form: Components.Form,
                            FormGroup: Components.FormGroup,
                            FormControl: Components.FormControl
                        }}
                    />
                </Components.Col>
                <Components.Col sm={12} md={6} className="d-flex justify-content-end align-items-center">
                    <Pagination
                        classes={classes}
                        data={data}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        onPageNavigate={onPageNavigate}
                        labels={labels}
                        maxPage={maxPage}
                        components={{
                            Button: Components.Button,
                            ButtonGroup: Components.ButtonGroup
                        }}
                    />
                </Components.Col>
            </Components.Row>
        </React.Fragment>
    )

}
CustomDatatable.propTypes = {
    leftOptions: PropTypes.object,
    exportOptions: PropTypes.object,
}
CustomDatatable.defaultProps = {
    leftOptions: {
        leftAction: false,
        label: "",
        onClicked: {}
    },
    exportOptions: {
        exportAction: false,
        filename: "",
        label: "Exporter",
        headers: [],
        data: []
    }
}
export default React.memo(CustomDatatable, shouldTableUpdate);