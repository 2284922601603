import React from "react"
import PropTypes from 'prop-types'

// import router
import { Route, Redirect } from "react-router-dom"

// import constants
import { USER_KEY } from "helpers/constants"

const AppRoute = ({ component: Component, layout: Layout, isProtected, roles, ...rest }) => (
	<Route
		{...rest} render={props => {
			if (isProtected && !localStorage.getItem(USER_KEY)) {// protected route
				return (
					<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
				)
			}

			if (isProtected && localStorage.getItem(USER_KEY)) {// check restricted role
				let userRole = JSON.parse(localStorage.getItem(USER_KEY)).role
				if ((roles.length > 0) && !roles.includes(userRole)) {
					return (
						<Redirect to={{ pathname: '/' }} />
					)
				}
			}

			return (
				<Layout>
					<Component {...props} />
				</Layout>
			)
		}}
	/>
)

AppRoute.propTypes = {
	isProtected: PropTypes.bool,
	component: PropTypes.any,
	location: PropTypes.object,
	layout: PropTypes.any,
	roles: PropTypes.array
}

export default AppRoute
