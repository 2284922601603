import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Label, Input } from "reactstrap"

// import form components
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"

// Form Mask
import InputMask from "react-input-mask"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import store and actions
import { connect } from 'react-redux'
import { finishOrder, resetOrders, setOrders } from 'store/actions'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import Loader, Selecter
import Selecter from "components/common/Selecter"
import Loader from "components/common/Loader"

// import constants
import { MIN_TEXT_INPUT, ORDERS_FINISH } from 'helpers/constants'

// import utilities
import { consoleErrorMessage, formatPhoneNumberForRequest } from 'helpers/utilities'


class OrderFinish extends React.Component {

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onClosed } = this.props

                if (success) {
                    if (option === ORDERS_FINISH) {
                        // Display toasts
                        toast.success(`Course terminée avec succès.`)

                        //Close modal
                        onClosed("finishVisible", false)
                    }
                }
                if (option === "") onClosed("finishVisible", false)
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(false)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {
            //Get props values
            let { order, option, onFinishOrder } = this.props

            //Get delivery code
            let delivery_auth_code = (
                (order.delivery_auth.enabled && values.delivery_code) ? values.delivery_code.trim() : ""
            )

            //Launch request
            switch (option) {
                case ORDERS_FINISH:
                    onFinishOrder({
                        in_agency: true,
                        delivery_code: delivery_auth_code,
                        name: values.name.trim(),
                        phone: formatPhoneNumberForRequest(values.phone.trim()),
                        registry_id: values.register_reference.trim(),
                        signature: ""
                    }, order._id, option)
                    break;
                default:
                    console.log("UNKNOW MODAL OPTION " + option)
                    break;
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }


    render() {
        const { opened, option, classes, error, loading, order, onClosed } = this.props
        const { code, created_at, start_point, delivery_point, delivery_auth, current_status } = order

        return (
            <Modal isOpen={opened} centered={true} className={classes}>
                <AvForm onValidSubmit={this.onSubmited}>
                    <ModalHeader tag="h4">
                        Terminer une course
                        {(created_at && code) ? (
                            <span className="d-block fs-12 text-secondary">
                                Course {" "}
                                <strong> #{code} </strong>
                                du {" "}
                                <strong>
                                    {`${moment.unix(created_at).format('DD MMM YYYY HH:mm')}`}
                                </strong>
                            </span>
                        ) : null}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            {(error && (error !== "") && (option === ORDERS_FINISH)) ? (
                                <Col xl="12">
                                    <Alert color="danger">{error}</Alert>
                                </Col>
                            ) : null}

                            <Col xl="7" className="mb-3">
                                <AvField
                                    name="name"
                                    label="Nom et Prénom(s)"
                                    type="text"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: 'Le nom est obligatoire.',
                                        },
                                        minLength: {
                                            value: MIN_TEXT_INPUT,
                                            errorMessage: (`Le nom a au moins ${MIN_TEXT_INPUT} caractères.`)
                                        }
                                    }}
                                />
                            </Col>

                            <Col xl="5" className="mb-3">
                                <AvField
                                    type="text"
                                    name="phone"
                                    label="Numéro de téléphone"
                                    mask="(+22\9) 99 99 99 99"
                                    maskChar="-"
                                    tag={[Input, InputMask]}
                                    validate={{
                                        minLength: {
                                            value: 8,
                                            errorMessage: 'Le numéro de téléphone a au moins 8 caractères.'
                                        },
                                        required: {
                                            value: true,
                                            errorMessage: 'Le numéro de téléphone est obligatoire.',
                                        },
                                    }}
                                />
                            </Col>

                            {(delivery_auth.enabled) ? (
                                <Col sm="12" className="mb-3">
                                    <AvGroup>
                                        <AvField
                                            name="delivery_code"
                                            type="text"
                                            label="Code de livraison"
                                            placeholder="00000000-AZER"
                                            maxLength="20"
                                            aria-describedby="accept-help-block"
                                            validate={{
                                                required: {
                                                    value: delivery_auth.enabled,
                                                    errorMessage: 'Le code de livraison est obligatoire.',
                                                },
                                                maxLength: {
                                                    value: 20,
                                                    errorMessage: 'Le code de livraison a 20 caractères.',
                                                }
                                            }}
                                        />
                                        <span id="accept-help-block" className="form-text">
                                            Pour cette course, le client a demandé une double confirmation de la livraison,
                                            veuillez saisir le code de livraison respectant le format <strong>00000000-AZER</strong>.
                                        </span>
                                    </AvGroup>
                                </Col>
                            ) : null}

                            <Col xl="12" className="mb-1">
                                <AvGroup>
                                    <AvField
                                        name="register_reference"
                                        label="Référence du registre"
                                        type="text"
                                        minLength="3"
                                        aria-describedby="register-help-block"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: 'La référence du registre est obligatoire.',
                                            },
                                            minLength: {
                                                value: MIN_TEXT_INPUT,
                                                errorMessage: (`La référence du registre a au moins ${MIN_TEXT_INPUT} caractères.`)
                                            }
                                        }}
                                    />
                                    <span id="register-help-block" className="form-text">
                                        Veuillez fournir la référence du registre contenant les nom, prénom(s)
                                        et signature de personne récupérant de le colis de la course.
                                    </span>
                                </AvGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {(loading && (option === ORDERS_FINISH)) ? (
                            <Loader color="success" />
                        ) : (
                            <>
                                <button
                                    type="button" data-dismiss="modal" className="btn btn-light"
                                    onClick={() => onClosed("finishVisible", false)}
                                >
                                    Fermer
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-success save-finish"
                                >
                                    Terminer
                                </button>
                            </>
                        )}
                    </ModalFooter>
                </AvForm>
            </Modal>
        );
    }

}
OrderFinish.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func,
    order: PropTypes.object
}
OrderFinish.defaultProps = {
    opened: false,
    classes: "order-finish-modal"
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onFinishOrder: (data, id, option) => dispatch(finishOrder(data, id, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderFinish)