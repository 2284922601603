import React from 'react'
import PropTypes from "prop-types"

// import router link
import { Link } from "react-router-dom"

// import components
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap"


const Breadcrumbs = ({ title, subtitle, previousPageLink, previousPageLabel }) => {
	return (
		<React.Fragment>
			<Row>
				<Col xs="12">
					<div className="page-title-box d-block align-items-center justify-content-between">
						<h4 className="mb-0 fs-18">
							{`${title}${subtitle}`}
						</h4>

						{((previousPageLabel != "") && (previousPageLink != "")) ? (
							<div className="page-title-right">
								<Breadcrumb listClassName="m-0">
									<BreadcrumbItem className="text-muted">
										<Link to={previousPageLink}>
											<i className='bx bx-arrow-back fs-10' /> {" "}
											{previousPageLabel}
										</Link>
									</BreadcrumbItem>
									<BreadcrumbItem active className="text-muted">
										<a href="" className="disabled">
											{title}
										</a>
									</BreadcrumbItem>
								</Breadcrumb>
							</div>
						) : null}
					</div>
				</Col>
			</Row>
		</React.Fragment>
	)
}
Breadcrumbs.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	previousPageLink: PropTypes.string,
	previousPageLabel: PropTypes.string
}
Breadcrumbs.defaultProps = {
	previousPageLink: "",
	previousPageLabel: "",
	subtitle: ""
}
export default Breadcrumbs