// HTTPS
export const ERROR_409 = { code: 409, label: "Conflict", message: "Requête avec des conflits." }
export const ERROR_404 = { code: 404, label: "Not Found", message: "Ressource non retrouvée." }
export const ERROR_401 = { code: 401, label: "Unauthorized", message: "Requête non autorisée." }
export const ERROR_400 = { code: 400, label: "Bad Request", message: "Requête invalide." }
export const ERROR_403 = { code: 403, label: "Forbidden", message: "Accès impossible." }
export const ERROR_500 = { code: 500, label: "Internal Server Error", message: "Erreur interne du serveur." }

// UNKNOWN
export const UNKNOWN_ERROR = { message: "Une erreur est survenue. Veuillez réessayer." }

// NETWORK
export const NETWORK_ERROR = { label: "Network Error", message: "Une erreur est survenue. Veuillez vérifier votre connexion internet et réessayer." }

// DUPLICATES ERRORS
export const DUPLICATE_ERROR = { label: "duplicate key error collection" }
export const DUPLICATE_PHONE = { label: "phone_ctl", field: "phone" }
export const DUPLICATE_EMAIL = { label: "email_ctl", field: "email" }
export const DUPLICATE_USERNAME = { label: "username_ctl", field: "username" }
export const DUPLICATE_NAME = { label: "name_ctl", field: "name" }
export const DUPLICATE_GENERATED = { label: "Bills already generated for period", field: "generated" }
export const CUSTOM_GENERATED = { label: "You can not generate bills for current month", field: "currentMonth" }
export const CUSTOM_PAYMENT = [
    { label: "Incompatible prices", field: "price" },
    { label: "Transaction not found or uncompleted", field: "transaction" },
    { label: "Payment can not be set to order", field: "updated" },
    { label: "transaction_id already used", field: "duplicated" }
]

// LOGIN PASSWORD
export const PASSWORD_ERROR = "Password doesn't match"

/**
 * Get error.
 * 
 * @param {*} status 
 * @param {*} statusText 
 * @returns 
 */
const getErrorByStatus = (status) => {
    switch (status) {
        case ERROR_400.code:
            return ERROR_400;
        case ERROR_401.code:
            return ERROR_401;
        case ERROR_403.code:
            return ERROR_403;
        case ERROR_404.code:
            return ERROR_404;
        case ERROR_409.code:
            return ERROR_409;
        case ERROR_500.code:
            return ERROR_500;
        default:
            return UNKNOWN_ERROR;
    }
}

/**
 * Conflicted error.
 * 
 * @param {*} errCode 
 * @param {*} errMessage 
 * @returns 
 */
const isConflictedError = (errCode, errMessage) => {
    if (errCode && (errCode === ERROR_409.code)) {
        let conflictOption = `${ERROR_409.code}`
        if (errMessage) {
            let isConflicted = errMessage.includes(DUPLICATE_GENERATED.label)
            if (isConflicted) {
                return { conflicted: isConflicted, option: DUPLICATE_GENERATED.field }
            } else {
                isConflicted = errMessage.includes(DUPLICATE_ERROR.label)
                if (isConflicted) {
                    if (errMessage.includes(DUPLICATE_PHONE.label)) {
                        conflictOption = DUPLICATE_PHONE.field
                    } else if (errMessage.includes(DUPLICATE_EMAIL.label)) {
                        conflictOption = DUPLICATE_EMAIL.field
                    } else if (errMessage.includes(DUPLICATE_USERNAME.label)) {
                        conflictOption = DUPLICATE_USERNAME.field
                    } else if (errMessage.includes(DUPLICATE_NAME.label)) {
                        conflictOption = DUPLICATE_NAME.field
                    }
                }
                return { conflicted: isConflicted, option: conflictOption }
            }
        }
        return { conflicted: true, option: conflictOption }
    }
    return { conflicted: false, option: "" }
}

const isCustomError = (errCode, errMessage) => {
    if (errCode && (errCode === ERROR_404.code) && errMessage) {
        let isGenerated = errMessage.includes(CUSTOM_GENERATED.label)
        let customOption = ((isGenerated) ? CUSTOM_GENERATED.field : `${ERROR_404.code}`)
        return { isCustom: true, option: customOption }
    } else if (errCode && (errCode === ERROR_400.code) && errMessage) {
        let customError = CUSTOM_PAYMENT.find((item) => errMessage.includes(item.label))
        let customOption = ((customError) ? customError.field : `${ERROR_400.code}`)
        return { isCustom: true, option: customOption }
    }
    return { isCustom: false, option: "" }
}

/**
 * Unauthorized error.
 * 
 * @param {*} error 
 * @returns 
 */
const isUnauthorizedError = (error) => {
    return (error.toLowerCase() === ERROR_401.message.toLowerCase())
}

/**
 * Format error message.
 * 
 * @param {*} errorObject 
 * @param {*} messages 
 * @returns 
 */
export const errorMessage = (errorObject, messages = {}) => {
    let error = errorObject.response ? errorObject.response : errorObject
    let msgError = ""
    if (error && error.data && error.data.status) {
        let sError = getErrorByStatus(error.data.status)
        let cError = isConflictedError(sError.code, error.data.message)
        let csdError = isCustomError(sError.code, error.data.message)
        if (cError.conflicted) {
            msgError = (messages[sError.code] ? messages[sError.code][cError.option] ?
                messages[sError.code][cError.option] : messages[sError.code] : sError.message)
        } else if (csdError.isCustom) {
            msgError = (messages[sError.code] ? messages[sError.code][csdError.option] ?
                messages[sError.code][csdError.option] : messages[sError.code] : sError.message)
        } else {
            msgError = (messages[sError.code] ? messages[sError.code] : sError.message)
        }
    } else if (error && error.status && error.statusText) {
        let bsError = getErrorByStatus(error.status)
        let csError = isCustomError(bsError.code, error.statusText)
        if (csError.isCustom) {
            msgError = (messages[bsError.code] ? messages[bsError.code][csError.option] ?
                messages[bsError.code][csError.option] : messages[bsError.code] : bsError.message)
        } else {
            msgError = (messages[bsError.code] ? messages[bsError.code] : bsError.message)
        }
    } else if (error && error.message && (error.message === NETWORK_ERROR.label)) {
        msgError = NETWORK_ERROR.message
    } else {
        msgError = UNKNOWN_ERROR.message
    }
    return { unauth: isUnauthorizedError(msgError), message: msgError }
}