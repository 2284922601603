import { USERS_ERROR, USERS_GET, USERS_INSERT, USERS_RESET, USERS_SELECT, USERS_SET, USERS_SUCCESS, USERS_UPDATE } from "./constants"

import { formatFactorsWithAgency } from "helpers/utilities"

const INITIAL_STATE = {
	agencies: [],
	factors: [],
	supervisors: [],
	billers: [],
	masters: [],
	agents: [],
	user: {},
	error: "",
	option: "",
	loading: false,
	success: false
}

const utilisateurs = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case USERS_SELECT:
		case USERS_GET:
		case USERS_INSERT:
		case USERS_UPDATE:
			return {
				...state,
				error: "",
				option: payload.option,
				success: false,
				loading: true
			}

		case USERS_SUCCESS:
			return {
				...state,
				agencies: payload.data.agencies ? payload.data.agencies.reverse() : state.agencies,
				factors: payload.data.factors ? formatFactorsWithAgency(payload.data.factors,
					(payload.data.agencies ? payload.data.agencies : state.agencies)) : state.factors,
				supervisors: payload.data.supervisors ? payload.data.supervisors.reverse() : state.supervisors,
				billers: payload.data.billers ? payload.data.billers.reverse() : state.billers,
				masters: payload.data.masters ? payload.data.masters.reverse() : state.masters,
				agents: payload.data.agents ? payload.data.agents : state.agents,
				user: payload.data._id ? payload.data : state.user,
				option: payload.option,
				loading: false,
				success: true
			}

		case USERS_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case USERS_SET:
			return {
				...state,
				agencies: payload.agencies ? payload.agencies : state.agencies,
				factors: payload.factors ? payload.factors : state.factors,
				supervisors: payload.supervisors ? payload.supervisors : state.supervisors,
				billers: payload.billers ? payload.billers : state.billers,
				masters: payload.masters ? payload.masters : state.masters,
				agents: payload.agents ? payload.agents : state.agents,
				user: payload.user ? payload.user : state.user,
				error: payload.error ? payload.error : state.error,
				option: payload.option ? payload.option : state.option,
				loading: payload.loading ? payload.loading : state.loading,
				success: payload.success ? payload.success : state.success
			}

		case USERS_RESET:
			return {
				...state,
				agencies: payload.all ? INITIAL_STATE.agencies : state.agencies,
				factors: payload.all ? INITIAL_STATE.factors : state.factors,
				supervisors: payload.all ? INITIAL_STATE.supervisors : state.supervisors,
				billers: payload.all ? INITIAL_STATE.billers : state.billers,
				masters: payload.all ? INITIAL_STATE.masters : state.masters,
				agents: payload.all ? INITIAL_STATE.agents : state.agents,
				user: payload.all ? INITIAL_STATE.user : state.user,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}
}

export default utilisateurs