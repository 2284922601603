import React from 'react'

// import components
import { Row, Col } from "reactstrap"

// import constants
import { APP_NAME } from "helpers/constants"

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container-fluid">
                    <Row>
                        <Col sm={6}>
                            {new Date().getFullYear()} © {APP_NAME}.
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Tous droits réservés.
                            </div>
                        </Col>
                    </Row>
                </div>
            </footer>
        </React.Fragment>
    )
}
export default Footer
