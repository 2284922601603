import { combineReducers } from "redux"

// Front
import ground from "./layout/reducer"

// Auth
import auth from "./auth/reducer"

// Users
import utilisateurs from "./users/reducer"

// Customers
import clients from "./customers/reducer"

// Pricings
import tarifications from "./pricings/reducer"

// Orders
import commandes from "./orders/reducer"

// Issues
import incidents from "./issues/reducer"

// Invoices
import factures from "./invoices/reducer"

// Statistics
import statistiques from "./statistics/reducer"

// Logging
import journalisation from "./logging/reducer"


const rootReducer = combineReducers({
  // public
  ground, auth, utilisateurs, clients,
  tarifications, commandes, incidents,
  factures, statistiques, journalisation
})

export default rootReducer
