import React from 'react';
import PropTypes from 'prop-types';

// import select
import Select from "react-select";

const Selecter = ({ id, name, placeholder, multiple, loading, clearable, selected, options, onSelected, valueAttribut, labelAttribut, selectClass, selectClassPrefix }) => {

    const formatItemsWithValuesAndLabels = (data) => {
        if ((data == null) || (data == "") || (data == {})) return
        if (Array.isArray(data)) {
            return data.map((item) => {
                return ((item && item.value && item.label) ? item : {
                    ...item,
                    value: item[valueAttribut],
                    label: item[labelAttribut]
                })
            })
        } else {
            return ((data && data.value && data.label) ? data : {
                ...data,
                value: data[valueAttribut],
                label: data[labelAttribut]
            })
        }
    }

    return (
        <>
            {(selected !== null) ? (
                <Select
                    value={formatItemsWithValuesAndLabels(selected)}
                    onChange={(selected) => onSelected(selected, name)}
                    options={formatItemsWithValuesAndLabels(options)}
                    loadingMessage={() => 'Chargement...'}
                    noOptionsMessage={() => 'Aucune option'}
                    className={selectClass}
                    classNamePrefix={selectClassPrefix}
                    isDisabled={false}
                    isLoading={loading}
                    isClearable={clearable}
                    isSearchable={true}
                    isMulti={multiple}
                    placeholder={placeholder}
                    name={name}
                    id={id}
                />
            ) : (
                <Select
                    onChange={(selected) => onSelected(selected, name)}
                    options={formatItemsWithValuesAndLabels(options)}
                    loadingMessage={() => 'Chargement...'}
                    noOptionsMessage={() => 'Aucune option'}
                    className={selectClass}
                    classNamePrefix={selectClassPrefix}
                    isDisabled={false}
                    isLoading={loading}
                    isClearable={clearable}
                    isSearchable={true}
                    isMulti={multiple}
                    placeholder={placeholder}
                    name={name}
                    id={id}
                />
            )}
        </>
    );

}
Selecter.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    loading: PropTypes.bool,
    clearable: PropTypes.bool,
    selectClass: PropTypes.string,
    selectClassPrefix: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.any,
    onSelected: PropTypes.func,
    valueAttribut: PropTypes.string,
    labelAttribut: PropTypes.string,
}
Selecter.defaultProps = {
    id: "",
    name: "",
    placeholder: "Sélectionner...",
    selectClass: "default-selects",
    selectClassPrefix: "default-selects-prefix",
    multiple: false,
    loading: false,
    clearable: true,
    valueAttribut: "_id",
    labelAttribut: "name",
    options: [],
    selected: null
}
export default Selecter