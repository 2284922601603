import {
	CUSTOMERS_ERROR, CUSTOMERS_GET, CUSTOMERS_INSERT, CUSTOMERS_RESET,
	CUSTOMERS_SELECT, CUSTOMERS_SET, CUSTOMERS_SUCCESS, CUSTOMERS_UPDATE
} from "./constants"


const INITIAL_STATE = {
	customers: [],
	customer: {},
	error: "",
	option: "",
	loading: false,
	success: false
}

const clients = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case CUSTOMERS_SELECT:
		case CUSTOMERS_GET:
		case CUSTOMERS_INSERT:
		case CUSTOMERS_UPDATE:
			return {
				...state,
				error: "",
				option: payload.option,
				loading: true
			}

		case CUSTOMERS_SUCCESS:
			return {
				...state,
				customers: Array.isArray(payload.data) ? payload.data.reverse() : state.customers,
				customer: payload.data._id ? payload.data : state.customer,
				option: payload.option,
				loading: false,
				success: true
			}

		case CUSTOMERS_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case CUSTOMERS_SET:
			return {
				...state,
				customers: payload.customers ? payload.customers : state.customers,
				customer: payload.customer ? payload.customer : state.customer,
				error: payload.error ? payload.error : state.error,
				option: payload.option ? payload.option : state.option,
				loading: payload.loading ? payload.loading : state.loading,
				success: payload.success ? payload.success : state.success
			}

		case CUSTOMERS_RESET:
			return {
				...state,
				customers: payload.all ? INITIAL_STATE.customers : state.customers,
				customer: payload.all ? INITIAL_STATE.customer : state.customer,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}
}

export default clients