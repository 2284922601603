import { useState, useEffect } from "react";
import { startRecording, saveRecording } from "./recorder-handlers";

const INITIAL_STATE = {
    recordingMinutes: 0,
    recordingSeconds: 0,
    initRecording: false,
    mediaStream: null,
    mediaRecorder: null,
    mediaBlob: null,
    audio: null,
};

export default function useRecorder() {
    const [record, setRecord] = useState(INITIAL_STATE);

    useEffect(() => {
        const MAX_RECORDER_TIME = 2;
        let recordingInterval = null;

        if (record.initRecording && record.audio !== null) {
            setRecord((prevState) => {
                return {
                    ...prevState,
                    audio: INITIAL_STATE.audio,
                    mediaBlob: INITIAL_STATE.mediaBlob
                }
            })
        }

        if (record.initRecording)
            recordingInterval = setInterval(() => {
                setRecord((prevState) => {
                    if (
                        prevState.recordingMinutes === MAX_RECORDER_TIME &&
                        prevState.recordingSeconds === 0
                    ) {
                        clearInterval(recordingInterval);
                        return prevState;
                    }

                    if (prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59)
                        return {
                            ...prevState,
                            recordingSeconds: prevState.recordingSeconds + 1,
                        };

                    if (prevState.recordingSeconds === 59)
                        return {
                            ...prevState,
                            recordingMinutes: prevState.recordingMinutes + 1,
                            recordingSeconds: 0,
                        };
                });
            }, 1000);
        else clearInterval(recordingInterval);

        return () => clearInterval(recordingInterval);
    });

    useEffect(() => {
        if (record.mediaStream)
            setRecord((prevState) => {
                return {
                    ...prevState,
                    mediaRecorder: new MediaRecorder(prevState.mediaStream),
                };
            });
    }, [record.mediaStream]);

    useEffect(() => {
        const recorder = record.mediaRecorder;
        let chunks = [];

        if (recorder && recorder.state === "inactive") {
            recorder.start();

            recorder.ondataavailable = (e) => {
                chunks.push(e.data);
            };

            recorder.onstop = () => {
                const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
                chunks = [];

                setRecord((prevState) => {
                    if (prevState.mediaRecorder)
                        return {
                            ...INITIAL_STATE,
                            mediaBlob: blob,
                            audio: window.URL.createObjectURL(blob),
                        };
                    else return INITIAL_STATE;
                });
            };
        }

        return () => {
            if (recorder) recorder.stream.getAudioTracks().forEach((track) => track.stop());
        };
    }, [record.mediaRecorder]);

    return {
        record,
        startRecording: () => startRecording(setRecord),
        cancelRecording: () => setRecord(INITIAL_STATE),
        saveRecording: () => saveRecording(record.mediaRecorder),
    };
}