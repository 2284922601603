import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorStatistics, successStatistics } from "./actions"
import { isUnauthorized } from "store/actions"

// import redux constants
import {
    STATISTICS_GET, STATISTICS_GET_ACTIVES_AGENTS, STATISTICS_GET_AGENCY_ORDERS, STATISTICS_GET_COMPLETED_ORDERS, STATISTICS_GET_CUSTOMERS, STATISTICS_GET_ORDERS_BY_PRICES, STATISTICS_GET_REFUNDED_ORDERS
} from "./constants"

// import constants
import { USER_KEY } from "helpers/constants"

// import errors
import { errorMessage } from "helpers/erreurs"

// import utilities
import { consoleErrorMessage, isSuccessfulRequest, isUnauthorizedRequest } from "helpers/utilities"

// import services
import {
    getActivesAgentsStatistics, getAgencyStatistics, getAgentStatistics, getCompletedOrdersStatistics, getCustomersStatistics, getOrdersByPricesStatistics, getRefundedOrdersStatistics, getStatistics, selectStatistics
} from "services/statisticService"


function* onGetStatistics({ payload }) {
    try {
        let { start_date, end_date, user_id, option } = payload
        const response = yield call(getStatistics, start_date, end_date, user_id)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            let result_field = ((user_id !== "") ? "user_statistics" : "all_statistics")
            yield put(successStatistics({ all_statistics: (response.data ? response.data : {}) }, payload.option))
        } else {
            consoleErrorMessage(response, "GET STATISTICS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorStatistics((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET STATISTICS ERROR")
        yield put(errorStatistics(errorMessage(error).message, payload.option))
    }
}

function* onGetCustomersStatistics({ payload }) {
    try {
        let { start_date, end_date, option } = payload
        const response = yield call(getCustomersStatistics, start_date, end_date)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successStatistics({ customer_statistics: (response.data ? response.data : []) }, payload.option))
        } else {
            consoleErrorMessage(response, "GET CUSTOMERS STATISTICS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorStatistics((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET CUSTOMERS STATISTICS ERROR")
        yield put(errorStatistics(errorMessage(error).message, payload.option))
    }
}

function* onGetCompletedOrdersStatistics({ payload }) {
    try {
        let { start_date, end_date, option } = payload
        const response = yield call(getCompletedOrdersStatistics, start_date, end_date)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successStatistics({ completed_statistics: (response.data ? response.data : []) }, payload.option))
        } else {
            consoleErrorMessage(response, "GET COMPLETED ORDERS STATISTICS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorStatistics((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET COMPLETED ORDERS STATISTICS ERROR")
        yield put(errorStatistics(errorMessage(error).message, payload.option))
    }
}

function* onGetOrdersByPricesStatistics({ payload }) {
    try {
        let { start_date, end_date, option } = payload
        const response = yield call(getOrdersByPricesStatistics, start_date, end_date)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successStatistics({ prices_statistics: (response.data ? response.data : []) }, payload.option))
        } else {
            consoleErrorMessage(response, "GET COMPLETED ORDERS STATISTICS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorStatistics((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET COMPLETED ORDERS STATISTICS ERROR")
        yield put(errorStatistics(errorMessage(error).message, payload.option))
    }
}

function* onGetRefundedOrdersStatistics({ payload }) {
    try {
        let { start_date, end_date, option } = payload
        const response = yield call(getRefundedOrdersStatistics, start_date, end_date)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successStatistics({ refunded_statistics: (response.data ? response.data : []) }, payload.option))
        } else {
            consoleErrorMessage(response, "GET REFUNDED ORDERS STATISTICS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorStatistics((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET REFUNDED ORDERS STATISTICS ERROR")
        yield put(errorStatistics(errorMessage(error).message, payload.option))
    }
}

function* onGetAgencyStatistics({ payload }) {
    try {
        let { agency_id, option } = payload
        const response = yield call(getAgencyStatistics, agency_id)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successStatistics({ agency_statistics: (response.data ? response.data : []) }, payload.option))
        } else {
            consoleErrorMessage(response, "GET AGENCY STATISTICS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorStatistics((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET AGENCY STATISTICS ERROR")
        yield put(errorStatistics(errorMessage(error).message, payload.option))
    }
}

function* onGetActivesAgentsStatistics({ payload }) {
    try {
        let { start_date, end_date, agent_role, option } = payload
        const response = yield call(getActivesAgentsStatistics, start_date, end_date, agent_role)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            let formated_list = (response.data ? response.data : [])
            formated_list = formated_list.map((item) => ({
                ...item,
                name: item.name ? item.name : (item.firstname && item.lastname) ? `${item.firstname} ${item.lastname}` : ""
            }))
            yield put(successStatistics({ agent_statistics: formated_list }, payload.option))
        } else {
            consoleErrorMessage(response, "GET ACTIVES AGENTS STATISTICS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorStatistics((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET ACTIVES AGENTS STATISTICS ERROR")
        yield put(errorStatistics(errorMessage(error).message, payload.option))
    }
}


function* StatisticSaga() {
    yield takeEvery(STATISTICS_GET, onGetStatistics)
    yield takeEvery(STATISTICS_GET_CUSTOMERS, onGetCustomersStatistics)
    yield takeEvery(STATISTICS_GET_COMPLETED_ORDERS, onGetCompletedOrdersStatistics)
    yield takeEvery(STATISTICS_GET_REFUNDED_ORDERS, onGetRefundedOrdersStatistics)
    yield takeEvery(STATISTICS_GET_AGENCY_ORDERS, onGetAgencyStatistics)
    yield takeEvery(STATISTICS_GET_ACTIVES_AGENTS, onGetActivesAgentsStatistics)
    yield takeEvery(STATISTICS_GET_ORDERS_BY_PRICES, onGetOrdersByPricesStatistics)
}

export default StatisticSaga