import API, { ORDER_BASE_URL } from './baseService';

export function selectInvoices(month, year) {
    return API.get(`${ORDER_BASE_URL}bills?month=${month}&year=${year}`).then((response) => response).catch((error) => error);
}

export function findInvoice(reference, month, year) {
    return API.get(`${ORDER_BASE_URL}bills/find?reference=${reference}&year=${year}&month=${month}`).then((response) => response).catch((error) => error);
}

export function setPaidInvoice(data) {
    return API.put(`${ORDER_BASE_URL}bills/set/paid`, data).then((response) => response).catch((error) => error);
}

export function generateInvoice(data) {
    return API.post(`${ORDER_BASE_URL}bills/generate`, data).then((response) => response).catch((error) => error);
}