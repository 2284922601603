import React from 'react'
import PropTypes from 'prop-types'

// import Link
import { Link } from "react-router-dom"

// import components
import { Col, Row } from "reactstrap"


const InvoiceSearch = React.memo(({ onSearched, onChanged }) => {

    return (
        <React.Fragment>
            <Row>
                <Col xl="5" sm="12" className="mb-3">
                    <label htmlFor="code" className="form-label">
                        Code
                    </label>
                    <div className="input-group">
                        <input
                            id="code"
                            name="code"
                            type="text"
                            className="form-control"
                            onChange={(e) => onChanged(e)}
                            aria-label="code"
                            aria-describedby="addon-search-code"
                        />
                        <span
                            id="addon-search-code"
                            className="input-group-text h-cursor"
                            onClick={() => onSearched("code")}
                        >
                            <i className="text-muted bx bx-xs bx-search" />
                        </span>
                    </div>
                </Col>

                <Col xl="7" sm="12" className="mb-3">
                    <label htmlFor="date" className="form-label">
                        Date
                    </label>
                    <div className="input-group">
                        <input
                            id="date"
                            name="date"
                            type="date"
                            className="form-control"
                            onChange={(e) => onChanged(e)}
                            aria-label="date"
                            aria-describedby="addon-search-date"
                        />
                        <span
                            id="addon-search-date"
                            className="input-group-text h-cursor"
                            onClick={() => onSearched("date")}
                        >
                            <i className="text-muted bx bx-xs bx-search" />
                        </span>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );

});
InvoiceSearch.propTypes = {
    onSearched: PropTypes.func.isRequired,
    onChanged: PropTypes.func.isRequired
}
InvoiceSearch.defaultProps = {}
export default InvoiceSearch