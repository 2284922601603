import React from "react"
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Input, Label } from "reactstrap"

// import form components
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"

// Form Mask
import InputMask from "react-input-mask"

// import custom components
import Loader from '../../components/common/Loader'
import Selecter from "components/common/Selecter"

// import constants
import { ISSUES_ADD, ISSUES_EDIT, ISSUES_HIGH, ISSUES_LOW, ISSUES_MEDIUM, MAX_TEXT_INPUT, MIN_TEXT_INPUT } from "helpers/constants"


const IssueForm = React.memo(({ opened, option, classes, error, loading, onClosed, onSubmited, onChecked, onChanged, onSelected, issue }) => {

    return (
        <Modal isOpen={opened} centered={true} className={classes}>
            <ModalHeader tag="h4">
                {(option === ISSUES_EDIT) ? "Modifier un incident" : "Ajouter un incident"}
            </ModalHeader>
            <AvForm onValidSubmit={onSubmited}>
                <ModalBody>
                    <Row>
                        {(error && error !== "") ? (
                            <Col xl="12">
                                <Alert color="danger">{error}</Alert>
                            </Col>
                        ) : null}

                        <Col xl="12" className="mb-3">
                            <AvField
                                name="name"
                                label="Libellé"
                                type="textarea"
                                rows="3"
                                value={issue.name || ""}
                                onChange={(e) => onChanged(e)}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Le nom est obligatoire.",
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`Le nom a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    },
                                    maxLength: {
                                        value: MAX_TEXT_INPUT,
                                        errorMessage: (`Le nom a au plus ${MAX_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                            {(issue.name && (issue.name != "")) ? (
                                <span className="badgecount badge bg-secondary">
                                    {" "}
                                    {`${issue.name.length}`} / {MAX_TEXT_INPUT}{" "}
                                </span>
                            ) : null}
                        </Col>

                        <Col xl="12" className="mb-3">
                            <div className="form-group">
                                <Label htmlFor="selected_gravity">
                                    Gravité
                                </Label>
                                <Selecter
                                    selected={issue.selected_gravity || null}
                                    onSelected={onSelected}
                                    options={[ISSUES_LOW, ISSUES_MEDIUM, ISSUES_HIGH]}
                                    id="selected_gravity"
                                    name="selected_gravity"
                                    placeholder="Sélectionner la gravité..."
                                    valueAttribut="key"
                                    labelAttribut="name"
                                />
                            </div>
                        </Col>

                        <Col xl="12" className="mb-1">
                            <div className="form-group">
                                <div className={`custom-check${(issue.enabled) ? " active" : ""}`}>
                                    <div className="form-check form-switch form-switch-md check-control">
                                        <input
                                            name="enabled"
                                            type="checkbox"
                                            className="form-check-input"
                                            id="enabledSwitch"
                                            checked={issue.enabled || false}
                                            onChange={(e) => onChecked(e)}
                                        />
                                        <label className="form-check-label" htmlFor="enabledSwitch">
                                            <span className={`${(issue.enabled) ? "fw-bolder" : "fw-light"}`}>
                                                {(issue.enabled) ? "Active" : "Active"}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {(loading) ? (
                        <Loader color={`${(option === ISSUES_EDIT) ? "warning" : "info"}`} />
                    ) : (
                        <>
                            <button type="button" data-dismiss="modal" className="btn btn-light" onClick={() => onClosed()}>
                                Fermer
                            </button>
                            <button type="submit" className={`btn btn-${(option === ISSUES_EDIT) ? "warning" : "info"} save-issue`}>
                                {
                                    (option == ISSUES_EDIT) ? "Modifier" : "Ajouter"
                                }
                            </button>
                        </>
                    )}
                </ModalFooter>
            </AvForm>
        </Modal>
    );

});
IssueForm.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onChanged: PropTypes.func,
    onChecked: PropTypes.func,
    onClosed: PropTypes.func,
    onSubmited: PropTypes.func,
    issue: PropTypes.object
}
IssueForm.defaultProps = {
    opened: false,
    classes: "issues-form-modal"
}
export default IssueForm