import React from 'react'
import PropTypes from 'prop-types'

import { Pie } from "react-chartjs-2"

const PieChart = ({ completeds, uncompleteds }) => {
    const data = {
        // labels: ["Desktops", "Tablets"],
        datasets: [
            {
                data: [completeds, uncompleteds],
                backgroundColor: ["#34c38f", "#f46a6a"],
                hoverBackgroundColor: ["#34c38f", "#f46a6a"],
                hoverBorderColor: "#fff",
            },
        ],
    }

    return (
        <React.Fragment>
            <Pie width={574} height={400} data={data} />
        </React.Fragment>
    )
}

PieChart.propTypes = {}

export default PieChart