import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Card, CardBody } from "reactstrap"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom table component, constants
import CustomDatatable, { CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM } from 'components/lists/CustomDatatable';

// import utilities
import { formatExportedFilename, formatPhoneNumberForDisplay, toDaysMinutesSeconds } from 'helpers/utilities';


const RefundedOrdersTable = ({ items, start, end, onShowed }) => {

    const columns = [
        {
            prop: "code",
            title: "#",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span
                        className="fw-bold text-primary text-decoration-underline h-cursor"
                        onClick={() => onShowed(row)}
                    >
                        {row.code}
                    </span>
                );
            }
        },
        {
            prop: "created_at",
            title: "Crée le",
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className="d-block">
                        {moment.unix(row.created_at).format("DD MMM YYYY HH:mm")}
                    </span>
                );
            }
        },
        {
            prop: "refund",
            title: "Remboursée",
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <div>
                        <span className="d-block">
                            {moment.unix(row.refund.at).format("DD MMM YYYY HH:mm")}
                        </span>
                        <span className="d-block wrapped-text">
                            {(row.refund.reason.length > 30) ? (row.refund.reason.substr(0, 29) + "...") : row.refund.reason}
                        </span>
                    </div>
                );
            }
        },
        {
            prop: "start_point",
            title: "Point d’expédition",
            filterable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {(row.start_point.address.length > 65) ? (row.start_point.address.substr(0, 64) + "...") : row.start_point.address}
                    </span>
                );
            }
        },
        {
            prop: "delivery_point",
            title: "Point de destination",
            filterable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {(row.delivery_point.address.length > 65) ? (row.delivery_point.address.substr(0, 64) + "...") : row.delivery_point.address}
                    </span>
                );
            }
        },
    ];

    const headers = [
        { label: "Code", key: "code" },
        { label: "Nature", key: "nature" },
        { label: "Coût(XOF)", key: "payment.price" },
        { label: "Crée le", key: "createdAt" },
        { label: "Remboursée le", key: "refundedAt" },
        { label: "Remboursée au motif de", key: "refund.reason" },
        { label: "Point d’expédition", key: "start_point.address" },
        { label: "Point de destination", key: "delivery_point.address" },
        { label: "Client", key: "customerData" }
    ];

    const formatedData = items.map((item) => ({
        ...item,
        createdAt: moment.unix(item.created_at).format("DD MMM YYYY HH:mm"),
        refundedAt: moment.unix(item.refund.at).format("DD MMM YYYY HH:mm"),
        customerData: (`${item.customer.name} \n\ ${formatPhoneNumberForDisplay(item.customer.phone)}`)
    }))

    const formatedFilename = formatExportedFilename("courses_remboursees", start, end)

    const onFilter = {
        start_point: (value) => {
            return (value && value.address) ? value.address : ""
        },
        delivery_point: (value) => {
            return (value && value.address) ? value.address : ""
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CustomDatatable
                        tableHeaders={columns}
                        tableBody={items}
                        classes={CLASSES}
                        rowsPerPage={TOTAL_PER_PAGE_SM}
                        rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                        initialSort={{ prop: '', isAscending: true }}
                        labels={{ ...LABELS, entries: `sur ${items.length} courses remboursées` }}
                        leftOptions={{ leftAction: false, label: "", onClicked: {} }}
                        exportOptions={{
                            exportAction: true,
                            label: "Exporter",
                            filename: `${formatedFilename}.csv`,
                            headers: headers,
                            data: formatedData
                        }}
                        onFilter={onFilter}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
RefundedOrdersTable.propTypes = {
    items: PropTypes.array,
    onExporting: PropTypes.func,
}
export default RefundedOrdersTable