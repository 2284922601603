import React from 'react'
import PropTypes from 'prop-types'

// import bootstrap components
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

const NaturesCard = ({ natures }) => {
    return (
        <React.Fragment>
            {natures.map((item, index) => (
                <Col key={`nature_item_${index}`} xl="3" sm="12">
                    <Card>
                        <CardBody>
                            <div className="d-flex justify-content-between mb-4">
                                <div className="d-flex align-items-center">
                                    <div className="avatar-xs me-2">
                                        <span className={`avatar-title rounded-circle bg-soft bg-${item.color} text-${item.color} fs-18`}>
                                            <i className={`bx ${item.icon}`} />
                                        </span>
                                    </div>
                                    <h5 className="fs-14 mb-0 fw-bold">{item.name}</h5>
                                </div>
                                <span className="fs-22">
                                    {item.total || "0"}
                                </span>
                            </div>
                            <Row className="justify-content-center">
                                <Col sm={6}>
                                    <div className="text-center">
                                        <h5 className="mb-0 fs-17">
                                            {item.completed}
                                        </h5>
                                        <p className="text-muted fs-12">
                                            Terminées
                                        </p>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="text-center">
                                        <h5 className="mb-0 fs-17">
                                            {item.uncompleted}
                                        </h5>
                                        <p className="text-muted fs-12">
                                            Non Terminées
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            ))}
            <small className="text-muted fw-light fs-12">
                *Les données affichées ci-dessus, concernent uniquement les courses non remboursées
            </small>
        </React.Fragment>
    )
}

NaturesCard.propTypes = {
    natures: PropTypes.any,
}
NaturesCard.defaultProps = {
    natures: [],
}
export default NaturesCard