import API, { ORDER_BASE_URL } from './baseService';


export function getStatistics(started_at, ended_at, user_id = "") {
    let params = ((user_id !== "") ? `&user_id=${user_id}` : "")
    return API.get(`${ORDER_BASE_URL}statistics?start_date=${started_at}&end_date=${ended_at}${params}`).then((response) => response).catch((error) => error);
}

export function getCustomersStatistics(started_at, ended_at) {
    return API.get(`${ORDER_BASE_URL}lists/customers?start_date=${started_at}&end_date=${ended_at}`).then((response) => response).catch((error) => error);
}

export function getCompletedOrdersStatistics(started_at, ended_at) {
    return API.get(`${ORDER_BASE_URL}lists/orders/completed?start_date=${started_at}&end_date=${ended_at}`).then((response) => response).catch((error) => error);
}

export function getRefundedOrdersStatistics(started_at, ended_at) {
    return API.get(`${ORDER_BASE_URL}lists/orders/refunded?start_date=${started_at}&end_date=${ended_at}`).then((response) => response).catch((error) => error);
}

export function getAgencyStatistics(agency = "") {
    let params = ((agency !== "") ? `?agency_id=${agency}` : "")
    return API.get(`${ORDER_BASE_URL}lists/orders/uncompleted/agency${params}`).then((response) => response).catch((error) => error);
}

export function getActivesAgentsStatistics(started_at, ended_at, agent_role) {
    return API.get(`${ORDER_BASE_URL}lists/users/active?start_date=${started_at}&end_date=${ended_at}&role=${agent_role}`).then((response) => response).catch((error) => error);
}

export function getOrdersByPricesStatistics(started_at, ended_at) {
    return API.get(`${ORDER_BASE_URL}lists/orders/completed/price?start_date=${started_at}&end_date=${ended_at}`).then((response) => response).catch((error) => error);
}