// LIST, GET, INSERT, UPDATE
export const CUSTOMERS_SELECT = "CUSTOMERS_SELECT"
export const CUSTOMERS_GET = "CUSTOMERS_GET"
export const CUSTOMERS_INSERT = "CUSTOMERS_INSERT"
export const CUSTOMERS_UPDATE = "CUSTOMERS_UPDATE"

// ERROR, SUCCESS, SET, RESET
export const CUSTOMERS_ERROR = "CUSTOMERS_ERROR"
export const CUSTOMERS_SUCCESS = "CUSTOMERS_SUCCESS"
export const CUSTOMERS_SET = "CUSTOMERS_SET"
export const CUSTOMERS_RESET = "CUSTOMERS_RESET"