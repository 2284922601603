import { CHANGE_SIDEBAR_THEME, CHANGE_PRELOADER, CHANGE_LAYOUT_THEME, } from "./constants"

export const changePreloader = preloader => ({
  type: CHANGE_PRELOADER,
  payload: preloader,
})

export const changeSidebarTheme = theme => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
})

export const changeLayoutTheme = theme => ({
  type: CHANGE_LAYOUT_THEME,
  payload: theme,
})