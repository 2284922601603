import { STATISTICS_ERROR, STATISTICS_GET, STATISTICS_GET_COMPLETED_ORDERS, STATISTICS_GET_CUSTOMERS, STATISTICS_GET_AGENCY_ORDERS, STATISTICS_RESET, STATISTICS_SET, STATISTICS_SUCCESS, STATISTICS_GET_ACTIVES_AGENTS, STATISTICS_GET_REFUNDED_ORDERS, STATISTICS_GET_ORDERS_BY_PRICES } from "./constants"


export const showStatistics = (start_date, end_date, user_id, option) => ({
    type: STATISTICS_GET,
    payload: { start_date, end_date, user_id, option },
})

export const customersStatistics = (start_date, end_date, option) => ({
    type: STATISTICS_GET_CUSTOMERS,
    payload: { start_date, end_date, option },
})

export const completedStatistics = (start_date, end_date, option) => ({
    type: STATISTICS_GET_COMPLETED_ORDERS,
    payload: { start_date, end_date, option },
})

export const pricesStatistics = (start_date, end_date, option) => ({
    type: STATISTICS_GET_ORDERS_BY_PRICES,
    payload: { start_date, end_date, option },
})

export const refundedStatistics = (start_date, end_date, option) => ({
    type: STATISTICS_GET_REFUNDED_ORDERS,
    payload: { start_date, end_date, option },
})

export const agentsStatistics = (start_date, end_date, agent_role, option) => ({
    type: STATISTICS_GET_ACTIVES_AGENTS,
    payload: { start_date, end_date, agent_role, option },
})

export const agencyStatistics = (agency_id, option) => ({
    type: STATISTICS_GET_AGENCY_ORDERS,
    payload: { agency_id, option },
})

export const successStatistics = (data, option) => ({
    type: STATISTICS_SUCCESS,
    payload: { data, option },
})

export const errorStatistics = (error = "", option = "") => ({
    type: STATISTICS_ERROR,
    payload: { error, option },
})

export const setStatistics = (data) => ({
    type: STATISTICS_SET,
    payload: { ...data },
})

export const resetStatistics = (all) => ({
    type: STATISTICS_RESET,
    payload: { all },
})