import API, { ACCOUNT_BASE_URL } from './baseService';

export function selectUsers(type) {
    return API.get(`${ACCOUNT_BASE_URL}users/list?role=${type}`).then((response) => response).catch((error) => error);
}

export function getUser(id) {
    return API.get(`${ACCOUNT_BASE_URL}users/${id}`).then((response) => response).catch((error) => error);
}

export function insertUser(data, url) {
    return API.post(`${ACCOUNT_BASE_URL}${url}`, data).then((response) => response).catch((error) => error);
}

export function updateUser(id, data, url) {
    return API.put(`${ACCOUNT_BASE_URL}${url}/${id}`, data).then((response) => response).catch((error) => error);
}