import { call, takeEvery, put } from "redux-saga/effects"

import { CHANGE_SIDEBAR_THEME, } from "./constants"
import { consoleErrorMessage } from "helpers/utilities"

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value)
  return true
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass)
      break
    case "remove":
      if (document.body) document.body.classList.remove(cssClass)
      break
    default:
      if (document.body) document.body.classList.toggle(cssClass)
      break
  }

  return true
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeSidebarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, "data-sidebar", theme)
  } catch (error) {
    consoleErrorMessage(error)
  }
}

function* LayoutSaga() {
  yield takeEvery(CHANGE_SIDEBAR_THEME, changeSidebarTheme)
}

export default LayoutSaga
