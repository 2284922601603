import React, { Component } from 'react'

// import router, link
import { withRouter, Link } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import sidebar menu
import SidebarMenu from './SidebarMenu'

// import constants
import { APP_NAME } from "helpers/constants"


class Sidebar extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="vertical-menu">
                    <div className="navbar-brand-box">
                        <Link to="/" className="logo logo-dark">
                            <span className="logo-sm">
                                <h3 className="mt-4 mb-0 text-white fw-bold">
                                    RP
                                </h3>
                            </span>
                            <span className="logo-lg">
                                <h2 className="mt-4 mb-0 text-white">
                                    {APP_NAME}
                                </h2>
                            </span>
                        </Link>

                        <Link to="/" className="logo logo-light">
                            <span className="logo-sm">
                                <h3 className="mt-4 mb-0 text-white fw-bold">
                                    RP
                                </h3>
                            </span>
                            <span className="logo-lg">
                                <h2 className="mt-4 mb-0 text-white">
                                    {APP_NAME}
                                </h2>
                            </span>
                        </Link>
                    </div>
                    <div data-simplebar className="h-100">
                        <SidebarMenu />
                    </div>
                    <div className="sidebar-background"></div>
                </div>
            </React.Fragment>
        )
    }
    
}
export default withRouter(withTranslation()(Sidebar))
