import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Card, CardBody } from "reactstrap"

// import custom table component, constants
import CustomDatatable, {
    CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM
} from 'components/lists/CustomDatatable';

// import utilities
import { formatPhoneNumberForDisplay } from 'helpers/utilities';


const PartnerTable = ({ items, onAdding, onShowing, onEditing }) => {

    const columns = [
        {
            prop: "name",
            title: "Nom",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <span className="h-cursor ellipsis-text fw-bold" onClick={() => onShowing(row)}>
                        {row.name}
                        <span className="d-block fs-12 fw-light">
                            @{row.username}
                        </span>
                    </span>
                );
            }
        },
        {
            prop: "email",
            title: "Contact",
            filterable: true,
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <div>
                        <span className="d-block"> {row.email} </span>
                        <span className="d-block">
                            {formatPhoneNumberForDisplay(row.phone)}
                        </span>
                    </div>
                );
            }
        },
        {
            prop: "address",
            title: "Addresse",
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <span className="ellipsis-text">
                        {row.address}
                    </span>
                );
            }
        },
        {
            prop: "menu",
            isDummyField: true,
            title: "",
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <i
                            className="bx bx-edit bx-xs bx-border text-secondary h-cursor"
                            id={`showtooltip_${row._id}`}
                            onClick={() => onEditing(row)}
                        />
                    </div>
                );
            }
        },
    ];

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CustomDatatable
                        classes={CLASSES}
                        tableHeaders={columns}
                        tableBody={items}
                        rowsPerPage={TOTAL_PER_PAGE_SM}
                        rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                        initialSort={{ prop: '', isAscending: true }}
                        labels={{ ...LABELS, entries: `sur ${items.length} clients contractuels` }}
                        leftOptions={{ leftAction: true, label: "Nouveau Client", onClicked: onAdding }}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
PartnerTable.propTypes = {
    items: PropTypes.array,
    onAdding: PropTypes.func,
    onEditing: PropTypes.func,
    onShowing: PropTypes.func
}
export default PartnerTable