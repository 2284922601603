import API, { ACCOUNT_BASE_URL } from './baseService';

export function selectCustomers(partnersOnly = true, userIsNotRoot = false) {
    let extraLink = (userIsNotRoot ? "/list" : "")
    return API.get(`${ACCOUNT_BASE_URL}customers${extraLink}?partners_only=${partnersOnly}`).then((response) => response).catch((error) => error);
}

export function getCustomer(id, withDetails = false) {
    let extraLink = (withDetails ? "detail/" : "")
    return API.get(`${ACCOUNT_BASE_URL}customers/${extraLink}${id}`).then((response) => response).catch((error) => error);
}

export function insertCustomer(data) {
    return API.post(`${ACCOUNT_BASE_URL}customers`, data).then((response) => response).catch((error) => error);
}

export function updateCustomer(id, data) {
    return API.put(`${ACCOUNT_BASE_URL}customers/${id}`, data).then((response) => response).catch((error) => error);
}