import React from 'react'
import PropTypes from 'prop-types'

// import bootstrap components
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// import chart
import PieChart from './PieChart'

// import utilities
import { toDaysMinutesSeconds } from 'helpers/utilities'

const TotalsCard = ({ totals, completeds, uncompleteds, avg_duration, onClicked }) => {

    //Format average duration
    let formated_avg_duration = toDaysMinutesSeconds((avg_duration))
    let duration_splited = formated_avg_duration.trim().split(" ")

    return (
        <React.Fragment>
            <Col lg={6} sm="12">
                <Row>
                    <Col xl="8">
                        <Card style={{ height: '250px' }}>
                            <CardBody>
                                <Row className="g-0">
                                    <Col xl="6" sm="12">
                                        <CardTitle className="mb-2 fs-13">
                                            <span className="d-block fs-13">
                                                Commandes{" "}
                                            </span>
                                            <span className="d-block fs-30">
                                                {totals || "0"}
                                            </span>
                                        </CardTitle>
                                        <Row className="justify-content-center">
                                            <Col sm={12}>
                                                <div className="text-center">
                                                    <h5 className="mb-0 fs-20">
                                                        {completeds}
                                                    </h5>
                                                    <p className="text-muted">
                                                        <i className={`bx bxs-circle text-success fs-10`}></i>{" "}
                                                        <span className="fs-12">Terminées</span>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="text-center">
                                                    <h5 className="mb-0 fs-20">
                                                        {uncompleteds}
                                                    </h5>
                                                    <p className="text-muted">
                                                        <i className={`bx bxs-circle text-danger fs-10`}></i>{" "}
                                                        <span className="fs-12">Non Terminées</span>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl="6" sm="12">
                                        <div className="h-100 d-flex align-items-center justify-content-center">
                                            <PieChart completeds={completeds} uncompleteds={uncompleteds} />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="4">
                        <Card style={{ height: '250px' }}>
                            <CardBody>
                                <CardTitle className="mb-0 fs-13">
                                    Durée moyenne
                                    {(avg_duration > 0) ? (
                                        <span
                                            className="d-block fs-12 text-muted text-decoration-underline h-cursor"
                                            onClick={onClicked}
                                        >
                                            Voir plus
                                        </span>
                                    ) : null}
                                </CardTitle>
                                <div className="h-100 d-flex align-items-center justify-content-center">
                                    <h5 className="mb-0 display-3 text-center">
                                        {duration_splited[0] || "0"}
                                        {(duration_splited.length > 1) ? (
                                            <span className="d-block fs-12">
                                                {duration_splited.slice(1).reduce(
                                                    (accumulateur, valeurCourante) => (`${accumulateur} ${valeurCourante}`)
                                                )}
                                            </span>
                                        ) : null}
                                    </h5>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </React.Fragment>
    )
}

TotalsCard.propTypes = {
    totals: PropTypes.number,
    completeds: PropTypes.number,
    uncompleteds: PropTypes.number,
    onClicked: PropTypes.func.isRequired
}
TotalsCard.defaultProps = {
    totals: 0,
    completeds: 0,
    uncompleteds: 0
}
export default TotalsCard