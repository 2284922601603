import React from 'react'
import PropTypes from 'prop-types'

// import bootstrap components
import { Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap"

// import constants
import { NATURES_DOCUMENT, NATURES_ENCOMBRANT, NATURES_FRAGILE, NATURES_FRAIS } from 'helpers/constants'

// import custom polar
import PolarChart from './PolarChart'


const RevenuesCard = ({ revenues }) => {

    const formated = () => {
        let { per_nature } = revenues
        let formated_list = [
            {
                ...NATURES_DOCUMENT,
                value: per_nature[NATURES_DOCUMENT.key] ? per_nature[NATURES_DOCUMENT.key] : 0
            },
            {
                ...NATURES_ENCOMBRANT,
                value: per_nature[NATURES_ENCOMBRANT.key] ? per_nature[NATURES_ENCOMBRANT.key] : 0
            },
            {
                ...NATURES_FRAGILE,
                value: per_nature[NATURES_FRAGILE.key] ? per_nature[NATURES_FRAGILE.key] : 0
            },
            {
                ...NATURES_FRAIS,
                value: per_nature[NATURES_FRAIS.key] ? per_nature[NATURES_FRAIS.key] : 0
            }
        ]
        return formated_list
    }

    return (
        <React.Fragment>
            <Col xl="6" sm="12">
                <Card style={{ height: '250px' }}>
                    <CardBody>
                        <Row className="g-0">
                            <Col xl="6" sm="12">
                                <CardTitle className="mb-2">
                                    <span className="d-block fs-13">
                                        Revenues{" "}
                                        <small className="text-muted">(XOF)</small>
                                    </span>
                                    <span className="d-block fs-30">
                                        {revenues.total || "0"}
                                    </span>
                                </CardTitle>
                                <Row className="justify-content-center">
                                    <Col sm={6}>
                                        <div className="text-center">
                                            <h5 className="mb-0 fs-20">
                                                {revenues.per_nature.ENCOMBRANT || "0"}
                                            </h5>
                                            <p className="text-muted mb-2">
                                                <i className={`bx bxs-circle text-${NATURES_ENCOMBRANT.color} fs-10`}></i>{" "}
                                                <span className="fs-12">{NATURES_ENCOMBRANT.name}</span>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="text-center">
                                            <h5 className="mb-0 fs-20">
                                                {revenues.per_nature.FRAGILE || "0"}
                                            </h5>
                                            <p className="text-muted mb-2">
                                                <i className={`bx bxs-circle text-${NATURES_FRAGILE.color} fs-10`}></i>{" "}
                                                <span className="fs-12">{NATURES_FRAGILE.name}</span>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="text-center">
                                            <h5 className="mb-0 fs-20">
                                                {revenues.per_nature.FRAIS || "0"}
                                            </h5>
                                            <p className="text-muted mb-2">
                                                <i className={`bx bxs-circle text-${NATURES_FRAIS.color} fs-10`}></i>{" "}
                                                <span className="fs-12">{NATURES_FRAIS.name}</span>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="text-center">
                                            <h5 className="mb-0 fs-20">
                                                {revenues.per_nature.DOCUMENT || "0"}
                                            </h5>
                                            <p className="text-muted mb-2">
                                                <i className={`bx bxs-circle text-${NATURES_DOCUMENT.color} fs-10`}></i>{" "}
                                                <span className="fs-12">{NATURES_DOCUMENT.name}</span>
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <small className="text-muted fw-light">
                                    *Les revenues sont calculés sur les courses terminées et non remboursées
                                </small>
                            </Col>
                            <Col xl="6" sm="12">
                                <PolarChart items={formated()} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}
RevenuesCard.propTypes = {
    revenues: PropTypes.object
}
RevenuesCard.defaultProps = {
    revenues: {}
}
export default RevenuesCard