import React from 'react'
import PropTypes from 'prop-types'

// import date range picker
import DateRangePicker from 'react-bootstrap-daterangepicker'

// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css'

// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css'

// import moment
import moment from 'moment'
import 'moment/locale/fr'


const DateRanger = React.memo(({ singleDate, maxSingleDate, dateFormat, start, end, onRanged }) => {

    const initSettings = singleDate ? {
        singleDatePicker: true,
        autoApply: true,
        maxDate: maxSingleDate.toDate()
    } : {
        ranges: {
            'Aujourd\'hui': [moment().toDate(), moment().toDate()],
            'Hier': [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
            ],
            'Les 7 derniers jours': [
                moment().subtract(6, 'days').toDate(),
                moment().toDate(),
            ],
            'Les 30 derniers jours': [
                moment().subtract(29, 'days').toDate(),
                moment().toDate(),
            ],
            'Mois courant': [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
            ],
            'Mois dernier': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
            ],
        },
        locale: {
            format: dateFormat,
            separator: " - ",
            applyLabel: "Appliquer",
            cancelLabel: "Annuler",
            fromLabel: "De",
            toLabel: "À",
            customRangeLabel: "Définir",
        }
    }

    return (
        <DateRangePicker
            initialSettings={{
                startDate: ((start !== "") ? start.toDate() : start),
                endDate: ((end !== "") ? end.toDate() : end),
                ...initSettings,
            }}
            onCallback={onRanged}
        >
            <div id="dashRange" className="col-3 custom-range-picker app-font">
                <i className="bx bxs-calendar"></i>&nbsp;
                <span className="flex-grow-1 text-center">
                    {((start !== "") && (end !== "")) ? (
                        <>
                            {(start.format(dateFormat) === end.format(dateFormat)) ? start.format(dateFormat) :
                                (`${start.format(dateFormat)} - ${end.format(dateFormat)}`)
                            }
                        </>
                    ) : null}
                </span>
                &nbsp;<i className="bx bx-caret-down"></i>
            </div>
        </DateRangePicker>
    );

});
DateRanger.propTypes = {
    start: PropTypes.object,
    end: PropTypes.object,
    onRanged: PropTypes.func,
    dateFormat: PropTypes.string,
    maxSingleDate: PropTypes.object,
    singleDate: PropTypes.bool
}
DateRanger.defaultProps = {
    dateFormat: "DD MMM YYYY",
    singleDate: false
}
export default DateRanger