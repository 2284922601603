// @flow
import { CHANGE_LAYOUT_THEME, CHANGE_SIDEBAR_THEME, CHANGE_PRELOADER, } from "./constants"

const INIT_STATE = {
  layoutTheme: "light",
  layoutType: "vertical",
  layoutWidth: "fluid",
  sidebarTheme: "dark",
  sidebarThemeImage: "none",
  sidebarType: "default",
  topbarTheme: "light",
  isPreloader: true,
  isMobile: false,
}

const ground = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT_THEME:
      return {
        ...state,
        layoutTheme: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        sidebarTheme: action.payload,
      }
    default:
      return state
  }
}

export default ground