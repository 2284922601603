import React, { useState } from 'react'
import PropTypes from 'prop-types'

// import axios
import axios from 'axios'

// import utilities
import { consoleErrorMessage, isSuccessfulRequest } from 'helpers/utilities'

// BASE GEOSEARCH LINK
const BASE_GEOSEARCH_LINK = "https://nominatim.openstreetmap.org/search?format=json&accept-language=fr&countrycodes=bj&limit=10"

// DEFAULT STATE
const DEFAULT_STATE = {
    current: 0,
    suggestions: [],
    display: false,
    query: ""
}


const LocationCompleter = ({ onSelected }) => {

    const [data, setData] = useState(DEFAULT_STATE)

    const onChanged = (e) => {
        try {
            const query = ((e && e.currentTarget) ? e.currentTarget.value : "")

            onSuggestionsLoaded(query)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    const onSuggestionsLoaded = (query) => {
        try {
            //Init config
            let config = {
                method: 'get',
                url: (`${BASE_GEOSEARCH_LINK}&q=${query}`),
                headers: {}
            }

            //Execute geo-reverse request
            axios(config).then((response) => {
                let { status, statusText } = response
                if (isSuccessfulRequest(status, statusText)) {
                    let list = response.data.map((item) => ({
                        place_id: item.place_id,
                        lat: item.lat, lon: item.lon,
                        display_name: item.display_name
                    }))
                    setData({
                        ...data, suggestions: list, display: true, query: query,
                    })
                }
            }).catch((error) => {
                consoleErrorMessage(error)
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    const onClicked = (e, item) => {
        try {
            let input = e.currentTarget.innerText
            setData({ current: 0, suggestions: [], display: false, query: input })
            document.getElementById("search-input").value = input
            onSelected(item)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    const onKeyDowned = (e) => {
        try {
            const { keyCode } = e
            const { current, suggestions } = data

            if (keyCode === 13) {
                let input = suggestions[current].display_name
                setData({ ...data, current: 0, display: false, query: input })
                document.getElementById("search-input").value = input
                onSelected(suggestions[current])
            } else if ((keyCode === 38) && (current > 0)) {
                setData({ ...data, current: (current - 1) })
            } else if ((keyCode === 40) && (current < suggestions.length)) {
                setData({ ...data, current: (current + 1) })
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    const onReseted = () => {
        try {
            setData({ current: 0, suggestions: [], display: false, query: "" })
            document.getElementById("search-input").value = ""
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    return (
        <React.Fragment>
            <div className="completer-wrapper">
                <div className="query-box">
                    <i className="bx bx-search-alt query-indicator" />
                    <input
                        id="search-input"
                        type="text"
                        className="query-input"
                        placeholder="Rechercher..."
                        onKeyDown={(e) => onKeyDowned(e)}
                        onChange={(e) => onChanged(e)}
                    />
                    <i
                        className="bx bx-x query-erase"
                        onClick={() => onReseted()}
                    />
                </div>
                <div className="list-box">
                    {(data.display && data.query) ? (
                        <SuggestionList {...data} onClicked={onClicked} />
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    )

}

LocationCompleter.propTypes = {
    onSelected: PropTypes.func
}
LocationCompleter.defaultProps = {}
export default LocationCompleter


/**
 * Suggestions list.
 */
const SuggestionList = ({ suggestions, current, onClicked }) => (
    <ul>
        {(suggestions && (suggestions.length > 0)) ? (
            <>
                {suggestions.map((suggestion, index) => (
                    <li
                        key={`item_${index}_${suggestion}`}
                        className={`${(index === current) ? "item-active" : ""}`}
                        onClick={(e) => onClicked(e, suggestion)}
                    >
                        <i className="bx bx-search-alt" />
                        <span>
                            {suggestion.display_name ? suggestion.display_name : ""}
                        </span>
                    </li>
                ))}
            </>
        ) : (
            <li className="list-emtpy">
                <em>Aucune suggestion !</em>
            </li>
        )}
    </ul>
);