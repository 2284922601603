import { ORDERS_PENDING, ORDERS_COMPLETED, ORDERS_STARTED, ISSUES_LOW, ISSUES_MEDIUM, ISSUES_HIGH, VEHICLE_BIKE, VEHICLE_CAR, VEHICLE_TRICYCLE, ORDERS_ASSIGNED, EVENTS_TREATMENT, EVENTS_INCIDENT, EVENTS_RTS } from "./constants";

export const KKIAPAY_PUB_API_KEY = "3544b6e02e4c11ebb982638a58a20c31"

// ORDERS
export const generateOrders = (size = 5) => {
    let list = [];
    let all_status = [ORDERS_PENDING.key, ORDERS_ASSIGNED.key, ORDERS_STARTED.key];
    for (let i = 0; i < size; i++) {
        let randomIndex = Math.floor(Math.random() * all_status.length)
        let randomFactor = Math.floor(Math.random() * 5)
        let randomBool = Math.round(Math.random())
        list.push({
            _id: `6227d8e8e780c309c636b6bd${i}`,
            start_point: {
                address: "Aïbatin, 12ᵉ Arrondissement, Cotonou, Littoral, 00000, Bénin",
                contact: "22966085508",
                lat: 6.361432436481551,
                lng: 2.3827725648880005
            },
            delivery_point: {
                address: "CanalOlympia Wologuede, Avenue du Renouveau, Sainte Rita, 8ème Arrondissement, Cotonou, Littoral, 05BP1063 COTONOU- BENIN, Bénin",
                contact: "22967141025",
                lat: 6.376038898599079,
                lng: 2.4142808094620705
            },
            nature: "ENCOMBRANT",
            delivery_auth: { enabled: randomBool },
            description_file: "http://api.command.rp.diezze.com/v1/files/1656162538.mp3",
            description: "Imperdiet dui accumsan sit amet nulla facilisi morbi tempus iaculis",
            customer_id: ((randomBool === 1) ? `6227d8e8e780d309r633b3clt${i}` : ""),
            current_user_id: (randomIndex === 1) ? (`6227d8e8e780d309r633b3fac${randomFactor}`) : "",
            code: `O-A01${i}`,
            payment: {
                price: `50${i}`,
                transaction_id: `393782736648864${i}`,
            },
            created_at: new Date(),
            current_status: all_status[randomIndex],
            reported: ((randomBool === 1) && (randomIndex === 2))
        })
    }
    return list;
};


// ORDERS EVENTS
export const generateEvents = (size = 5) => {
    let list = [];
    let all_types = [EVENTS_TREATMENT.key, EVENTS_INCIDENT.key, EVENTS_RTS.key];
    let all_status = [ORDERS_PENDING.key, ORDERS_STARTED.key, ORDERS_ASSIGNED.key];
    for (let i = 0; i < size; i++) {
        let randomStatut = Math.floor(Math.random() * all_status.length)
        let randomType = Math.floor(Math.random() * all_types.length)
        list.push({
            at: 1656162538,
            event: all_types[randomType],
            status: all_status[randomStatut]
        })
    }
    return list;
}


// ISSUES
export const generateIssues = (size = 5) => {
    let list = [];
    let all_gravities = [ISSUES_LOW.key, ISSUES_MEDIUM.key, ISSUES_HIGH.key];
    for (let i = 0; i < size; i++) {
        let randomIndex = Math.floor(Math.random() * all_gravities.length)
        let randomBool = Math.round(Math.random())
        list.push({
            _id: `6227d8e8e780c309c636b6bdiss${i}`,
            name: `Incident ${(i + 1)}`,
            enabled: randomBool,
            gravity: all_gravities[randomIndex],
            created_at: new Date()
        })
    }
    return list;
}


// CUSTOMER
export const generateCustomers = (size = 5) => {
    let list = [];
    for (let i = 0; i < size; i++) {
        let randomBool = Math.round(Math.random())
        list.push({
            _id: `6227d8e8e780c309c636b6bctl${i}`,
            name: "Amet risus",
            phone: (`9067891${i}`),
            email: (`amet${i}@gmail.com`),
            username: (`ametr${i}`),
            partner: randomBool,
            enabled: true,
            address: "Purus sit amet volutpat consequat mauris nunc",
            created_at: new Date()
        })
    }
    return list;
}


// INVOICES
export const generateInvoices = (size = 5) => {
    let list = [];
    let customers = generateCustomers(size)
    for (let i = 0; i < size; i++) {
        let randomBool = Math.round(Math.random())
        list.push({
            _id: `6d8e8e780c309c636b6bdbills${i}`,
            code: `FA-${(2 * i)}14251`,
            amount: `${((1455 * i) + 5000)}`,
            due_at: new Date(),
            emitted_at: new Date(),
            paid: randomBool,
            paid_at: (randomBool ? new Date() : ""),
            customer: customers[i],
            billing_address: "John Smith, 1234 Main, Apt. 4B, Springfield ST 54321",
            shipping_address: "Kenny Rigdon, 1234 Main, Apt. 4B, Springfield ST 54321"
        })
    }
    return list;
}


// FACTORS
export const generateFactors = (size = 5) => {
    let list = [];
    let all_vehicles = [VEHICLE_BIKE.key, VEHICLE_CAR.key, VEHICLE_TRICYCLE.key];
    for (let i = 0; i < size; i++) {
        let randomIndex = Math.floor(Math.random() * all_vehicles.length)
        let randomValue = Math.floor(Math.random() * 5)
        list.push({
            _id: `6227d8e8e780d309r633b3fac${i}`,
            firstname: "Aboubacar",
            lastname: "Kpokponi",
            phone: `9043902${i}`,
            position: "",
            vehicle_type: all_vehicles[randomIndex],
            pendings: (randomValue + i),
            ongoings: (randomValue * i),
            enabled: true
        })
    }
    return list;
}


// LOCATION
export const LOCATION_FAKE = "Sainte Cécile d'Ahouansori, Rue 6.108, Toweta 1, Dantokpa, 6ème Arrondissement, Cotonou, Littoral, 03 BP 2149 COTONOU, Bénin"

// DESCRIPTION
export const DESCRIPTION_FAKE = "http://api.command.rp.diezze.com/v1/files/1656162538.mp3"

// COORDINATES START
export const COORDINATES_START = {
    lat: 6.36107284881529,
    lng: 2.3827259682416018
}

// COORDINATES DELIVERY
export const COORDINATES_DELIVERY = {
    lat: 6.370826962016937,
    lng: 2.49494799722966
}