import { PRICINGS_SELECT, PRICINGS_GET, PRICINGS_INSERT, PRICINGS_UPDATE, PRICINGS_RESET, PRICINGS_SET, PRICINGS_SUCCESS, PRICINGS_ERROR, PRICINGS_SETCONFIGS, PRICINGS_GETCONFIGS, PRICINGS_DELETE } from "./constants"


export const listPricings = (option) => ({
    type: PRICINGS_SELECT,
    payload: { option },
})

export const showPricing = (id, option) => ({
    type: PRICINGS_GET,
    payload: { id, option },
})

export const addPricing = (data, option) => ({
    type: PRICINGS_INSERT,
    payload: { data, option },
})

export const editPricing = (data, id, option) => ({
    type: PRICINGS_UPDATE,
    payload: { data, id, option },
})

export const removePricing = (id, option) => ({
    type: PRICINGS_DELETE,
    payload: { id, option },
})

export const addConfigPricing = (data, option) => ({
    type: PRICINGS_SETCONFIGS,
    payload: { data, option },
})

export const showConfigPricing = (option) => ({
    type: PRICINGS_GETCONFIGS,
    payload: { option },
})

export const successPricings = (data, option) => ({
    type: PRICINGS_SUCCESS,
    payload: { data, option },
})

export const errorPricings = (error = "", option = "") => ({
    type: PRICINGS_ERROR,
    payload: { error, option },
})

export const setPricings = (data, replace = true) => ({
    type: PRICINGS_SET,
    payload: { ...data, replace: replace },
})

export const resetPricings = (all) => ({
    type: PRICINGS_RESET,
    payload: { all },
})