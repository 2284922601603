import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorPricings, successPricings } from "./actions"
import { isUnauthorized } from "store/actions"

// import constants
import { PRICINGS_DELETE, PRICINGS_GET, PRICINGS_GETCONFIGS, PRICINGS_INSERT, PRICINGS_SELECT, PRICINGS_SETCONFIGS, PRICINGS_UPDATE } from "./constants"

// import errors
import { errorMessage, isUnauthorizedError } from "helpers/erreurs"

// import utilities
import { consoleErrorMessage, isSuccessfulRequest, isUnauthorizedRequest } from "helpers/utilities"

// import services
import { deletePricing, getConfigs, getPricing, insertPricing, selectPricings, setConfigs, updatePricing } from "services/pricingService"

// Users errors messages
const FORM_ERRORS = {
    403: "Opération non autorisée.",
    404: "La tarification n'existe pas.",
    409: "Une tarification ayant ces informations existe déjà.",
}

function* onGetConfigs({ payload }) {
    try {
        const response = yield call(getConfigs)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successPricings((response.data ? response.data : {}), payload.option))
        } else {
            consoleErrorMessage(response, "GET CONFIGS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET CONFIGS ERROR")
        yield put(errorPricings(errorMessage(error).message, payload.option))
    }
}

function* onSetConfigs({ payload }) {
    try {
        const response = yield call(setConfigs, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successPricings((response.data ? response.data : {}), payload.option))
        } else {
            consoleErrorMessage(response, "SET CONFIGS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "SET CONFIGS ERROR")
        yield put(errorPricings(errorMessage(error).message, payload.option))
    }
}

function* onSelectPricings({ payload }) {
    try {
        const response = yield call(selectPricings)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successPricings((response.data ? response.data : []), payload.option))
        } else {
            consoleErrorMessage(response, "SELECT PRICINGS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "SELECT PRICINGS ERROR")
        yield put(errorPricings(errorMessage(error).message, payload.option))
    }
}

function* onGetPricing({ payload }) {
    try {
        const response = yield call(getPricing, payload.id)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successPricings((response.data ? response.data : {}), payload.option))
        } else {
            consoleErrorMessage(response, "GET PRICING ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET PRICING ERROR")
        yield put(errorPricings(errorMessage(error).message, payload.option))
    }
}

function* onInsertPricing({ payload }) {
    try {
        const response = yield call(insertPricing, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectPricings)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successPricings((list.data ? list.data : []), payload.option))
            } else {
                consoleErrorMessage(list, "INSERT PRICING ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "INSERT PRICING ERROR")
            let { unauth, message } = errorMessage(response, FORM_ERRORS)
            yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "INSERT PRICING ERROR")
        yield put(errorPricings(errorMessage(error).message, payload.option))
    }
}

function* onUpdatePricing({ payload }) {
    try {
        const response = yield call(updatePricing, payload.id, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectPricings)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successPricings((list.data ? list.data : []), payload.option))
            } else {
                consoleErrorMessage(list, "UPDATE PRICING ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "UPDATE PRICING ERROR")
            let { unauth, message } = errorMessage(response, FORM_ERRORS)
            yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "UPDATE PRICING ERROR")
        yield put(errorPricings(errorMessage(error).message, payload.option))
    }
}

function* onDeletePricing({ payload }) {
    try {
        const response = yield call(deletePricing, payload.id)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectPricings)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successPricings((list.data ? list.data : []), payload.option))
            } else {
                consoleErrorMessage(list, "DELETE PRICING ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "DELETE PRICING ERROR")
            let { unauth, message } = errorMessage(response, FORM_ERRORS)
            yield put(errorPricings((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "DELETE PRICING ERROR")
        yield put(errorPricings(errorMessage(error).message, payload.option))
    }
}

function* PricingSaga() {
    yield takeEvery(PRICINGS_SELECT, onSelectPricings)
    yield takeEvery(PRICINGS_GET, onGetPricing)
    yield takeEvery(PRICINGS_INSERT, onInsertPricing)
    yield takeEvery(PRICINGS_UPDATE, onUpdatePricing)
    yield takeEvery(PRICINGS_DELETE, onDeletePricing)
    yield takeEvery(PRICINGS_GETCONFIGS, onGetConfigs)
    yield takeEvery(PRICINGS_SETCONFIGS, onSetConfigs)
}

export default PricingSaga