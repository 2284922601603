import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorIssues, successIssues } from "./actions"
import { isUnauthorized } from "store/actions"

// import constants
import { ISSUES_GET, ISSUES_INSERT, ISSUES_SELECT, ISSUES_UPDATE } from "./constants"

// import errors
import { errorMessage, isUnauthorizedError } from "helpers/erreurs"

// import utilities
import { consoleErrorMessage, isSuccessfulRequest, isUnauthorizedRequest } from "helpers/utilities"

// import services
import { getIssue, insertIssue, selectIssues, updateIssue } from "services/issueService"

// Issues errors messages
const FORM_ERRORS = {
    403: "Opération non autorisée.",
    404: "La tarification n'existe pas.",
    409: {
        name: "Une tarification ayant le même nom existe déjà.",
        "409": "Une tarification ayant ces informations existe déjà."
    },
}


function* onSelectIssues({ payload }) {
    try {
        const response = yield call(selectIssues, payload.param)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successIssues((response.data ? response.data : []), payload.option))
        } else {
            consoleErrorMessage(response, "SELECT ISSUE ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorIssues((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "SELECT ISSUES ERROR")
        yield put(errorIssues(errorMessage(error).message, payload.option))
    }
}

function* onGetIssue({ payload }) {
    try {
        const response = yield call(getIssue, payload.id)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successIssues((response.data ? response.data : {}), payload.option))
        } else {
            consoleErrorMessage(response, "GET ISSUE ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorIssues((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET ISSUE ERROR")
        yield put(errorIssues(errorMessage(error).message, payload.option))
    }
}

function* onInsertIssue({ payload }) {
    try {
        const response = yield call(insertIssue, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectIssues, false)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successIssues((list.data ? list.data : []), payload.option))
            } else {
                consoleErrorMessage(list, "INSERT ISSUE ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorIssues((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (lsError.unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "INSERT ISSUE ERROR")
            let { unauth, message } = errorMessage(response, FORM_ERRORS)
            yield put(errorIssues((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "INSERT ISSUE ERROR")
        yield put(errorIssues(errorMessage(error).message, payload.option))
    }
}

function* onUpdateIssue({ payload }) {
    try {
        const response = yield call(updateIssue, payload.id, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectIssues, false)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successIssues((list.data ? list.data : []), payload.option))
            } else {
                consoleErrorMessage(list, "UPDATE ISSUE ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorIssues((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (lsError.unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "UPDATE ISSUE ERROR")
            let { unauth, message } = errorMessage(response, FORM_ERRORS)
            yield put(errorIssues((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "UPDATE ISSUE ERROR")
        yield put(errorIssues(errorMessage(error).message, payload.option))
    }
}

function* IssueSaga() {
    yield takeEvery(ISSUES_SELECT, onSelectIssues)
    yield takeEvery(ISSUES_GET, onGetIssue)
    yield takeEvery(ISSUES_INSERT, onInsertIssue)
    yield takeEvery(ISSUES_UPDATE, onUpdateIssue)
}

export default IssueSaga