// LIST, GET, INSERT, UPDATE
export const INVOICES_SELECT = "INVOICES_SELECT"
export const INVOICES_GET = "INVOICES_GET"
export const INVOICES_FILTER = "INVOICES_FILTER"
export const INVOICES_FIND = "INVOICES_FIND"
export const INVOICES_GENERATE = "INVOICES_GENERATE"
export const INVOICES_SET_PAID = "INVOICES_SET_PAID"
export const INVOICES_UPDATE = "INVOICES_UPDATE"
export const INVOICES_INSERT = "INVOICES_INSERT"

// ERROR, SUCCESS, SET, RESET
export const INVOICES_ERROR = "INVOICES_ERROR"
export const INVOICES_SUCCESS = "INVOICES_SUCCESS"
export const INVOICES_SET = "INVOICES_SET"
export const INVOICES_RESET = "INVOICES_RESET"