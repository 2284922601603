import { AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AUTH_RESET, AUTH_SESSION, AUTH_SUCCESS, AUTH_UNAUTHORIZED, AUTH_UPDATE } from "./constants"

export const authLogin = (data, option) => ({
	type: AUTH_LOGIN,
	payload: { data, option },
})

export const authLogout = (option) => ({
	type: AUTH_LOGOUT,
	payload: { option },
})

export const authUpdate = (data, option) => ({
	type: AUTH_UPDATE,
	payload: { data, option },
})

export const authSuccess = () => ({
	type: AUTH_SUCCESS
})

export const authError = (error) => ({
	type: AUTH_ERROR,
	payload: { error },
})

export const authReset = (all = false) => ({
	type: AUTH_RESET,
	payload: { all },
})

export const isUnauthorized = () => ({
	type: AUTH_UNAUTHORIZED,
})

export const authValidateSession = (option) => ({
	type: AUTH_SESSION,
	payload: { option },
})