import { takeEvery, put, call } from "redux-saga/effects"

// import services
import { login, logout, updatePassword, validateSession } from "services/authService"

// import actions
import { authError, authSuccess, isUnauthorized } from "./actions"

// import redux constants
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_SESSION, AUTH_UPDATE } from "./constants"

// import constants
import { USER_AGENCY, USER_BILLER, USER_KEY, USER_MASTER, USER_ROOT, USER_SUPERVISOR } from "helpers/constants"

// import errors
import { errorMessage } from "helpers/erreurs"

// import utilities
import { consoleErrorMessage, formatUserData, isSuccessfulRequest } from "helpers/utilities"

// Auth errors messages
const AUTH_ERRORS = {
	"LOGIN": {
		400: "Identifiants de connexion invalides.",
		401: "Le mot passe est incorrect.",
		404: "L'identifant n'existe pas.",
		403: "L'utilisateur connecté est désactivé."
	},
	"LOGOUT": {},
	"UPDATE": {
		403: "L'ancien mot de passe ne correspond pas"
	}
}

const USER_ROLES = [USER_ROOT.key, USER_MASTER.key, USER_SUPERVISOR.key, USER_BILLER.key, USER_AGENCY.key]

function* onLoginUser({ payload }) {
	try {
		const response = yield call(login, payload.data)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			if (USER_ROLES.includes(response.data.role)) {
				const user = formatUserData(response.data)
				localStorage.setItem(USER_KEY, JSON.stringify(user))
				yield put(authSuccess())
			} else {
				yield put(authError("Vous n'êtes pas autorisé à accéder à cette application,"))
			}
		} else {
			consoleErrorMessage(response, "LOGIN ERROR")
			yield put(authError(errorMessage(response, AUTH_ERRORS.LOGIN).message))
		}
	} catch (error) {
		consoleErrorMessage(error, "LOGIN ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onLogoutUser({ payload }) {
	try {
		const response = yield call(logout)
		let { unauth, message } = errorMessage(response)
		if (isSuccessfulRequest(response.status, response.statusText) || unauth) {
			yield put(authSuccess())
		} else {
			consoleErrorMessage(response, "LOGOUT ERROR")
			yield put(authError(message))
		}
	} catch (error) {
		consoleErrorMessage(error, "LOGOUT ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onUpdatePassword({ payload }) {
	try {
		const response = yield call(updatePassword, payload.data)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			const loggedOut = yield call(logout)
			yield put(authSuccess())
			if (!isSuccessfulRequest(loggedOut.status, loggedOut.statusText)) {
				consoleErrorMessage(loggedOut, "LOGOUT ERROR")
			}
		} else {
			consoleErrorMessage(response, "UPDATE PASSWORD ERROR")
			let { unauth, message } = errorMessage(response, AUTH_ERRORS.UPDATE)
			yield put(authError((unauth ? "" : message)))
			if (unauth) yield put(isUnauthorized())
		}
	} catch (error) {
		consoleErrorMessage(error, "UPDATE PASSWORD ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onValidateSession() {
	try {
		const response = yield call(validateSession)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			if (USER_ROLES.includes(response.data.role)) {
				let user = formatUserData(response.data)
				localStorage.setItem(USER_KEY, JSON.stringify(user))
				yield put(authSuccess())
			} else {
				yield put(authError("Vous n'êtes pas autorisé à accéder à cette application,"))
			}
		} else {
			consoleErrorMessage(response, "VALIDATE SESSION ERROR")
			yield put(isUnauthorized())
		}
	} catch (error) {
		consoleErrorMessage(error, "VALIDATE SESSION ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* AuthSaga() {
	yield takeEvery(AUTH_LOGIN, onLoginUser)
	yield takeEvery(AUTH_LOGOUT, onLogoutUser)
	yield takeEvery(AUTH_UPDATE, onUpdatePassword)
	yield takeEvery(AUTH_SESSION, onValidateSession)

}

export default AuthSaga