import React from 'react'
import PropTypes from 'prop-types'

// import router link
import { Link } from "react-router-dom"

// import components
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"

// import simplebar
import SimpleBar from "simplebar-react"

// import i18n
import { withTranslation } from "react-i18next"


const Notifications = ({ t, items }) => {

    const [drop, setDrop] = React.useState(false)

    const onDropToggled = () => {
        setDrop((prevDrop) => (!prevDrop))
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={drop}
                toggle={onDropToggled}
                className="dropdown header-item px-1 center-ibox me-1"
                tag="li"
            >
                <DropdownToggle
                    className="btn btn-sm noti-icon border-0"
                    id="page-header-notifications-dropdown"
                    tag="button"
                >
                    <i className="far fa-bell text-muted"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0"> {t("Notifications")} </h6>
                            </Col>
                        </Row>
                    </div>

                    {(items.length > 0) ? (
                        <>
                            <SimpleBar style={{ height: "230px" }}>
                                {items.map((notif, index) => (
                                    <Link key={`notification_${index}`} to="" className="text-reset notification-item">
                                        <div className="media">
                                            <div className="avatar-xs me-3">
                                                <span className={`avatar-title bg-${notif.color} rounded-circle fs-16`}>
                                                    <i className={`bx bx-${notif.icon}`} />
                                                </span>
                                            </div>
                                            <div className="media-body">
                                                <h6 className="mt-0 mb-1">
                                                    {notif.title}
                                                </h6>
                                                <div className="fs-12 text-muted">
                                                    <p className="mb-1">
                                                        {notif.description}
                                                    </p>
                                                    <p className="mb-0">
                                                        <i className="far fa-clock" />{" "}
                                                        {notif.time}{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </SimpleBar>
                            <div className="p-2 border-top d-grid">
                                <Link className="btn btn-sm btn-link fs-14 text-center" to="#">
                                    <i className="fas fa-arrow-circle-right me-1"></i>
                                    <span key="t-view-more">{`${t("Voir plus")}...`}</span>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <div className="p-3">
                            <p className="m-0 p-2 pt-0 fs-12 text-secondary text-center">
                                Vous n'avez pas de nouvelle notification.
                            </p>
                        </div>
                    )}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}
Notifications.propTypes = {
    t: PropTypes.any,
    items: PropTypes.array
}
Notifications.defaultProps = {
    items: []
}
export default withTranslation()(Notifications)