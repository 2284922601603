import { USERS_ERROR, USERS_GET, USERS_INSERT, USERS_RESET, USERS_SELECT, USERS_SET, USERS_SUCCESS, USERS_UPDATE } from "./constants"


export const listUsers = (type, extraType, enabled_only, extra_enabled_only, option) => ({
    type: USERS_SELECT,
    payload: { type, extraType, enabled_only, extra_enabled_only, option },
})

export const showUser = (id, option) => ({
    type: USERS_GET,
    payload: { id, option },
})

export const addUser = (data, type, option) => ({
    type: USERS_INSERT,
    payload: { data, type, option },
})

export const editUser = (data, id, type, refresh_list, option) => ({
    type: USERS_UPDATE,
    payload: { data, id, type, refresh_list, option },
})

export const successUsers = (data, option) => ({
    type: USERS_SUCCESS,
    payload: { data, option },
})

export const errorUsers = (error = "", option = "") => ({
    type: USERS_ERROR,
    payload: { error, option },
})

export const setUsers = (data) => ({
    type: USERS_SET,
    payload: { ...data },
})

export const resetUsers = (all) => ({
    type: USERS_RESET,
    payload: { all },
})