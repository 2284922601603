import { ISSUES_ERROR, ISSUES_GET, ISSUES_INSERT, ISSUES_RESET, ISSUES_SELECT, ISSUES_SET, ISSUES_SUCCESS, ISSUES_UPDATE } from "./constants"

// import utilities
import { formatIssuesWithGravity } from "helpers/utilities"

const INITIAL_STATE = {
	issues: [],
	issue: {},
	error: "",
	option: "",
	loading: false,
	success: false
}

const incidents = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case ISSUES_SELECT:
		case ISSUES_GET:
		case ISSUES_INSERT:
		case ISSUES_UPDATE:
			return {
				...state,
				error: "",
				option: payload.option,
				loading: true
			}

		case ISSUES_SUCCESS:
			return {
				...state,
				issues: Array.isArray(payload.data) ? formatIssuesWithGravity(payload.data.reverse()) : state.issues,
				issue: payload.data._id ? payload.data : state.issue,
				option: payload.option,
				loading: false,
				success: true
			}

		case ISSUES_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case ISSUES_SET:
			return {
				...state,
				issues: payload.issues ? payload.issues : state.issues,
				issue: payload.issue ? payload.issue : state.issue,
				error: payload.error ? payload.error : state.error,
				option: payload.option ? payload.option : state.option,
				loading: payload.loading ? payload.loading : state.loading,
				success: payload.success ? payload.success : state.success
			}

		case ISSUES_RESET:
			return {
				...state,
				issues: payload.all ? INITIAL_STATE.issues : state.issues,
				issue: payload.all ? INITIAL_STATE.issue : state.issue,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}
}

export default incidents