import React from "react"
import PropTypes from 'prop-types'

// import router
import { withRouter, Link } from "react-router-dom"

// import components
import { Card, CardBody, Col, Container, Row } from "reactstrap"

// import constants
import { APP_NAME } from "helpers/constants"

// import logo
import logo from "../../assets/images/logos/logo.png"


const Auther = ({ children, location }) => {

	return (
		<React.Fragment>
			<div className="auth-pages">
				<Container>
					<Row className="justify-content-center g-0">
						<Col sm={12} md={10} xl={8}>
							<Card className="m-0">
								<CardBody className="p-0">
									<Row className="g-0">
										<Col sm={12} lg={6} className="auth-logo p-2 d-flex align-items-stretch">
											<div className="flex-grow-1 center-box">
												<img
													src={logo}
													alt="app logo"
													className="img-fluid"
													width={200} height={200}
												/>
											</div>
										</Col>
										<Col sm={12} lg={6} className="p-2 d-flex align-items-stretch">
											<div className="p-4 flex-grow-1 center-box">
												{children}
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)

}
Auther.propTypes = {
	children: PropTypes.object,
	location: PropTypes.object,
}
export default withRouter(Auther)