import React from "react"
import PropTypes from 'prop-types'

// import components
import { Row, Col, Alert } from "reactstrap"

// import link
import { Link } from "react-router-dom"

// import custom common components
import Loader from "components/common/Loader"

// import utilities
import { getConstantObject } from "helpers/utilities"

// import constants
import { DESCRIPTION_AUDIO, DESCRIPTION_TEXT, ORDERS_EVALUATE } from "helpers/constants"


const SummaryTab = React.memo(({ order, loading, error, option, onLoaded }) => {

    const {
        pickup_point, pickup_contact, delivery_point, delivery_contact, delivery_auth, round_trip, nature,
        description_type, description_textual, description_count, description_file, cost
    } = order

    let selected_nature = (nature && (nature !== "")) ? getConstantObject(nature) : {}

    return (
        <React.Fragment>
            <div style={{ minHeight: '350px' }}>
                {(loading && (option === ORDERS_EVALUATE)) ? (
                    <Row className="mb-2">
                        <Col xl="12">
                            <Loader color="primary" />
                            <center>
                                <p className="m-0 p-2">
                                    Calcul du coût de la course. Veuillez patienter...
                                </p>
                            </center>
                        </Col>
                    </Row>
                ) : ((error !== "") && (option === ORDERS_EVALUATE)) ? (
                    <Row className="mb-2">
                        <Col xl="12">
                            <Alert color="danger" className="m-0 d-flex align-items-center justify-content-between">
                                {error}{" "}
                                <Link to="#" className="alert-link" onClick={() => onLoaded()}>
                                    Réessayer
                                </Link>
                            </Alert>
                        </Col>
                    </Row>
                ) : ((order !== undefined) && (order !== null)) ? (
                    <Row className="mb-2">
                        <Col xl="8">
                            <Row>
                                <Col xl="12" className="mb-4">
                                    <div className="orders-points">
                                        <div className="d-flex align-items-center">
                                            <span className="points-box start-point me-1">
                                                <i className="bx bxs-map text-orange" />
                                            </span>
                                            <small className="flex-grow-1 text-muted">
                                                Enlèvement
                                            </small>
                                        </div>
                                        <div className="start-content mt-2">
                                            <span className="d-block fs-14 fw-bolder">
                                                {pickup_contact || ""}
                                            </span>
                                            <span className="d-block fs-13">
                                                {(pickup_point && pickup_point.address) ? pickup_point.address : ""}
                                            </span>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl="12" className="mb-4">
                                    <div className="orders-points">
                                        <div className="d-flex align-items-center">
                                            <span className="points-box end-point me-1">
                                                <i className="bx bxs-map text-blue" />
                                            </span>
                                            <small className="flex-grow-1 text-muted">
                                                Livraison
                                            </small>
                                        </div>
                                        <div className="end-content mt-2">
                                            <span className="d-block fs-14 fw-bolder">
                                                {delivery_contact || ""}
                                            </span>
                                            <span className="d-block fs-13">
                                                {(delivery_point && delivery_point.address) ? delivery_point.address : ""}
                                            </span>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl="12">
                                    <div className="orders-points">
                                        <div className="d-flex align-items-center">
                                            <span className="points-box description-point me-1">
                                                <i className={`bx ${(description_type === DESCRIPTION_AUDIO) ? "bx-play" : "bxs-map"} text-secondary`} />
                                            </span>
                                            <small className="flex-grow-1 text-muted">
                                                Description de la course
                                            </small>
                                        </div>
                                        {((description_type === DESCRIPTION_AUDIO) && description_file && (description_file !== "")) ? (
                                            <audio controls="controls" preload="metadata" className="m-0 w-100 rounded mt-2">
                                                <source src={description_file} type="audio/mpeg" />
                                                Votre navigateur ne prend pas en charge l'élément <code>audio</code>.
                                            </audio>
                                        ) : ((description_type === DESCRIPTION_TEXT) && description_textual && (description_textual !== "")) ? (
                                            <p className="m-0 px-2 py-3 rounded bg-light mt-2">
                                                {description_textual}
                                            </p>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="4">
                            <Row>
                                <Col sm="12">
                                    <div className="bg-light rounded p-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <small> Montant de la course </small>
                                            <span className="d-block ms-1 fw-bolder text-dark fs-20">
                                                {(cost && cost.total) ? (
                                                    <>
                                                        {cost.total}
                                                        <small>(XOF)</small>
                                                    </>
                                                ) : ""}
                                            </span>
                                        </div>
                                    </div>
                                </Col>

                                {selected_nature ? (
                                    <Col sm="12" className="mt-2">
                                        <div className={`bg-soft bg-${selected_nature.color} rounded p-3`}>
                                            <span className={`d-flex align-items-center justify-content-between fs-14 fw-bold text-${selected_nature.color}`}>
                                                {selected_nature.name}
                                                <i className={`bx ${selected_nature.icon} bx-xs text-${selected_nature.color}`} />
                                            </span>
                                        </div>
                                    </Col>
                                ) : null}

                                <Col sm="12" className="mt-2">
                                    {(delivery_auth) ? (
                                        <div className="bg-soft bg-blue rounded p-3">
                                            <span className="text-blue d-flex align-items-center justify-content-between">
                                                Code de livraison demandé
                                                <i className='bx bx-check-double bx-xs text-blue' />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="bg-soft bg-secondary rounded p-3">
                                            <span className="text-muted d-flex align-items-center justify-content-between">
                                                Pas de code de livraison
                                                <i className='bx bx-check-double bx-xs text-muted' />
                                            </span>
                                        </div>
                                    )}
                                </Col>

                                <Col sm="12" className="mt-2">
                                    {(round_trip) ? (
                                        <div className="bg-soft bg-blue rounded p-3">
                                            <span className="text-blue d-flex align-items-center justify-content-between">
                                                Aller-retour
                                                <i className='bx bx-transfer bx-xs text-blue' />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="bg-soft bg-secondary rounded p-3">
                                            <span className="text-muted d-flex align-items-center justify-content-between">
                                                Pas d'aller-retour
                                                <i className='bx bx-transfer bx-xs text-muted' />
                                            </span>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mb-2">
                        <Col xl="12">
                            <p className="m-0 p-2">
                                Aucune donnée à afficher
                            </p>
                        </Col>
                    </Row>
                )}
            </div>
        </React.Fragment>
    );

});
SummaryTab.propTypes = {
    order: PropTypes.object
}
SummaryTab.defaultProps = {
    order: {}
}
export default SummaryTab