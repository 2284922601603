import React from 'react'
import PropTypes from 'prop-types'

// import router
import { withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import components
import { Row, Col, Alert, Container, CardTitle, Label, Input } from "reactstrap"

// import form components
import { AvForm, AvField, AvInput, AvGroup } from "availity-reactstrap-validation"

// import time picker
import TimePicker from 'react-time-picker'

// import custom components
import Loader from '../../components/common/Loader'

// import constants
import { PRICINGS_ADDCONFIGS, PRICINGS_SHOWCONFIGS } from "helpers/constants"


const BasePricings = ({ pricings, option, error, success, loading, onSubmited, onChanged, onReseted }) => {

    return (
        <React.Fragment>
            <Container fluid>
                <div className="p-5">
                    {(error && (error !== "") && (option === PRICINGS_SHOWCONFIGS)) ? (
                        <Alert color="danger"> {error} </Alert>
                    ) : (loading && (option === PRICINGS_SHOWCONFIGS)) ? (
                        <Loader color='primary' />
                    ) : (pricings && pricings.day_price && pricings.night_price) ? (
                        <AvForm onValidSubmit={onSubmited}>
                            <Row>
                                {(error && (error !== "") && (option === PRICINGS_ADDCONFIGS)) ? (
                                    <Col xl="12">
                                        <Alert color="danger">{error}</Alert>
                                    </Col>
                                ) : null}

                                <Col xl="12">
                                    <CardTitle className="h5 font-size-14">
                                        Tarifs de jour
                                    </CardTitle>
                                    <Row>
                                        <Col xl="4" className="mb-3">
                                            <AvField
                                                name="d_min_price"
                                                label="Prix minimum(XOF)"
                                                type="number"
                                                value={pricings.day_price.min_price || ""}
                                                min="0"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Vouz devez spécifier le prix minimum.",
                                                    },
                                                    min: {
                                                        value: 0,
                                                        errorMessage: "Vouz devez spécifier le prix minimum.",
                                                    },
                                                }}
                                            />
                                        </Col>

                                        <Col xl="4" className="mb-3">
                                            <AvField
                                                name="d_min_km_price"
                                                label="Distance minimum(km)"
                                                type="number"
                                                value={pricings.day_price.min_km_price || ""}
                                                min="0"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Vouz devez spécifier la distance minimum.",
                                                    },
                                                    min: {
                                                        value: 0,
                                                        errorMessage: "Vouz devez spécifier la distance minimum.",
                                                    },
                                                }}
                                            />
                                        </Col>

                                        <Col xl="4" className="mb-3">
                                            <AvField
                                                name="d_additional_price_per_km"
                                                label="Prix distance supplémentaire(XOF)"
                                                type="number"
                                                value={pricings.day_price.additional_price_per_km || ""}
                                                min="0"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Vouz devez spécifier le prix de la distance supplémentaire.",
                                                    },
                                                    min: {
                                                        value: 0,
                                                        errorMessage: "Vouz devez spécifier le prix de la distance supplémentaire.",
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <div className="mt-5" />

                                <Col xl="12">
                                    <CardTitle className="h5 font-size-14">
                                        Tarifs de nuit
                                    </CardTitle>
                                    <Row>
                                        <Col xl="12">
                                            <Label>
                                                Heures de la nuit
                                            </Label>
                                        </Col>

                                        <Col xl="6" className="mb-3">
                                            <AvGroup>
                                                <div className="input-group">
                                                    <span id="addon-night-start-hour" className="input-group-text">
                                                        de
                                                    </span>

                                                    <TimePicker
                                                        onChange={(value) => onChanged(value, "night_started_at")}
                                                        locale="fr-FR"
                                                        format="HH:mm"
                                                        className="form-control"
                                                        clearIcon={null}
                                                        clockIcon={null}
                                                        disableClock={true}
                                                        openClockOnFocus={true}
                                                        value={pricings.night_started_at ? moment.unix(pricings.night_started_at).format('HH:mm') : "00:00"}
                                                    />
                                                </div>
                                            </AvGroup>
                                        </Col>

                                        <Col xl="6" className="mb-3">
                                            <AvGroup>
                                                <div className="input-group">
                                                    <span id="addon-night-end-hour" className="input-group-text">
                                                        à
                                                    </span>

                                                    <TimePicker
                                                        onChange={(value) => onChanged(value, "night_ended_at")}
                                                        locale="fr-FR"
                                                        format="HH:mm"
                                                        className="form-control"
                                                        clearIcon={null}
                                                        clockIcon={null}
                                                        disableClock={true}
                                                        openClockOnFocus={true}
                                                        value={pricings.night_ended_at ? moment.unix(pricings.night_ended_at).format('HH:mm') : "00:00"}
                                                    />
                                                </div>
                                            </AvGroup>
                                        </Col>

                                        <Col xl="4" className="mb-3">
                                            <AvField
                                                name="n_min_price"
                                                label="Prix minimum(XOF)"
                                                type="number"
                                                value={pricings.night_price.min_price || ""}
                                                min="0"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Vouz devez spécifier le prix minimum.",
                                                    },
                                                    min: {
                                                        value: 0,
                                                        errorMessage: "Vouz devez spécifier le prix minimum.",
                                                    },
                                                }}
                                            />
                                        </Col>

                                        <Col xl="4" className="mb-3">
                                            <AvField
                                                name="n_min_km_price"
                                                label="Distance minimum(km)"
                                                type="number"
                                                value={pricings.night_price.min_km_price || ""}
                                                min="0"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Vouz devez spécifier la distance minimum.",
                                                    },
                                                    min: {
                                                        value: 0,
                                                        errorMessage: "Vouz devez spécifier la distance minimum.",
                                                    },
                                                }}
                                            />
                                        </Col>

                                        <Col xl="4" className="mb-3">
                                            <AvField
                                                name="n_additional_price_per_km"
                                                label="Prix distance supplémentaire(XOF)"
                                                type="number"
                                                value={pricings.night_price.additional_price_per_km || ""}
                                                min="0"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Vouz devez spécifier le prix de la distance supplémentaire.",
                                                    },
                                                    min: {
                                                        value: 0,
                                                        errorMessage: "Vouz devez spécifier le prix de la distance supplémentaire.",
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <div className="mt-5" />

                                <Col xl="12" className="text-end">
                                    {(loading && (option === PRICINGS_ADDCONFIGS)) ? (
                                        <Loader color="primary" />
                                    ) : (
                                        <button type="submit" className="btn btn-primary save-configs">
                                            Enregistrer
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        </AvForm>
                    ) : null}
                </div>
            </Container>
        </React.Fragment>
    )

}
BasePricings.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    pricings: PropTypes.any,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onSubmited: PropTypes.func
}
BasePricings.defaultProps = { }
export default withRouter(withTranslation()(BasePricings))
