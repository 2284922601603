import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import { resetOrders, setOrders } from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import {
    APP_NAME
} from 'helpers/constants'

// import custom common components
import AgentSelector from './AgentSelector'
import AgentActivity from './AgentActivity'


class AgentOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const { t } = this.props

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Activité des utilisateurs")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={t("Activité des utilisateurs")} />

                        <Row className="mb-2">
                            <Col md="3" sm="12" className="px-2 px-xl-4">
                                <AgentSelector />
                            </Col>

                            <Col md="9" sm="12" className="px-2 px-xl-4">
                               <AgentActivity />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

}
AgentOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any
}
export default (withRouter(withTranslation()(AgentOrders)))
