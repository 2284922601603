// LIST, GET, INSERT, UPDATE
export const PRICINGS_SELECT = "PRICINGS_SELECT"
export const PRICINGS_GET = "PRICINGS_GET"
export const PRICINGS_INSERT = "PRICINGS_INSERT"
export const PRICINGS_UPDATE = "PRICINGS_UPDATE"
export const PRICINGS_DELETE = "PRICINGS_DELETE"
export const PRICINGS_GETCONFIGS = "PRICINGS_GETCONFIGS"
export const PRICINGS_SETCONFIGS = "PRICINGS_SETCONFIGS"

// ERROR, SUCCESS, SET, RESET
export const PRICINGS_ERROR = "PRICINGS_ERROR"
export const PRICINGS_SUCCESS = "PRICINGS_SUCCESS"
export const PRICINGS_SET = "PRICINGS_SET"
export const PRICINGS_RESET = "PRICINGS_RESET"