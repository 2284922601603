import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorCustomers, successCustomers } from "./actions"
import { isUnauthorized, setInvoices, successInvoices } from "store/actions"

// import constants
import { CUSTOMERS_GET, CUSTOMERS_INSERT, CUSTOMERS_SELECT, CUSTOMERS_UPDATE } from "./constants"

// import errors
import { errorMessage, isUnauthorizedError } from "helpers/erreurs"

// import utilities
import { consoleErrorMessage, isSuccessfulRequest, isUnauthorizedRequest } from "helpers/utilities"

// import services
import {
    getCustomer, getDetailCustomer, insertAgency, insertCustomer,
    selectCustomers, updateAgency, updateCustomer
} from "services/customerService"

// Customers Errors Messages
const CUSTOMERS_ERRORS = {
    "FORM": {
        403: "Opération non autorisée.",
        404: "Le client contractuel n'existe pas.",
        409: {
            email: "Un client contractuel ayant cet email existe déjà.",
            phone: "Un client contractuel ayant ce numéro de téléphone existe déjà.",
            username: "Un client contractuel ayant cet identifiant existe déjà.",
            "409": "Un client contractuel ayant ces informations existe déjà."
        }
    }
}


function* onSelectCustomers({ payload }) {
    try {
        let { partners_only, is_not_root, option } = payload
        const response = yield call(selectCustomers, partners_only, is_not_root)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successCustomers((response.data ? response.data : []), option))
        } else {
            consoleErrorMessage(response, "SELECT CUSTOMERS ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorCustomers((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "SELECT CUSTOMERS ERROR")
        yield put(errorCustomers(errorMessage(error).message, payload.option))
    }
}

function* onGetCustomer({ payload }) {
    try {
        let { id, with_details, option } = payload
        const response = yield call(getCustomer, id, with_details)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successCustomers((response.data ? response.data : {}), option))
        } else {
            consoleErrorMessage(response, "GET CUSTOMER ERROR")
            let { unauth, message } = errorMessage(response)
            yield put(errorCustomers((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "GET CUSTOMER ERROR")
        yield put(errorCustomers(errorMessage(error).message, payload.option))
    }
}

function* onInsertCustomer({ payload }) {
    try {
        const response = yield call(insertCustomer, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectCustomers)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successCustomers((list.data ? list.data : []), payload.option))
            } else {
                consoleErrorMessage(list, "INSERT CUSTOMER ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorCustomers((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "INSERT CUSTOMER ERROR")
            let { unauth, message } = errorMessage(response, CUSTOMERS_ERRORS.FORM)
            yield put(errorCustomers((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "INSERT CUSTOMER ERROR")
        yield put(errorCustomers(errorMessage(error).message, payload.option))
    }
}

function* onUpdateCustomer({ payload }) {
    try {
        const response = yield call(updateCustomer, payload.id, payload.data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectCustomers)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successCustomers((list.data ? list.data : []), payload.option))
            } else {
                consoleErrorMessage(list, "UPDATE CUSTOMER ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorCustomers((unauth ? "" : message), (unauth ? "" : payload.option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "UPDATE CUSTOMER ERROR")
            let { unauth, message } = errorMessage(response, CUSTOMERS_ERRORS.FORM)
            yield put(errorCustomers((unauth ? "" : message), (unauth ? "" : payload.option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "UPDATE CUSTOMER ERROR")
        yield put(errorCustomers(errorMessage(error).message, payload.option))
    }
}

function* CustomerSaga() {
    yield takeEvery(CUSTOMERS_SELECT, onSelectCustomers)
    yield takeEvery(CUSTOMERS_GET, onGetCustomer)
    yield takeEvery(CUSTOMERS_INSERT, onInsertCustomer)
    yield takeEvery(CUSTOMERS_UPDATE, onUpdateCustomer)
}

export default CustomerSaga