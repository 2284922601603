import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from "reactstrap"

// import toaster
import { toast } from 'react-toastify'

// import deep-equal
import equal from "deep-equal"

// import store and actions
import { connect } from 'react-redux'
import { regenerateInvoice, resetInvoices, setInvoices } from 'store/actions'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import Loader, Selecter
import Selecter from "components/common/Selecter"
import Loader from "components/common/Loader"

// import constants
import { INVOICES_ADD, INVOICES_EDIT, INVOICES_REGENERATE } from 'helpers/constants'

// import month picker
import MonthPicker, { CUSTOM_LABELS } from 'components/common/MonthPicker'

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'


class GenerateForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            period: {
                year: moment().subtract(1, 'month').startOf('month').year(),
                month: (moment().subtract(1, 'month').startOf('month').month() + 1)
            },
            regenerate: false
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, error, onClosed } = this.props

                if (success && (option === INVOICES_REGENERATE)) {
                    // Display toasts
                    toast.success("Facture(s) générée(s) avec succès.")

                    // Close modal
                    onClosed("generateVisible", false)                    
                }

                if ((error === "Déjà Généré") && (option === INVOICES_REGENERATE)) {
                    this.setState({ regenerate: true })
                }
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetInvoices(false)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Format Text.
     * 
     * @param {*} value 
     * @returns 
     */
    formatText = (value) => {
        return (`${CUSTOM_LABELS.months[(value.month - 1)]} ${value.year}`)
    }

    /**
     * On Regenerated.
     * 
     */
    onRegenerated = () => {
        try {
            //Get props
            let { onResetInvoices, onRegenerateInvoices, option } = this.props

            if (option === INVOICES_REGENERATE) {
                // Get state
                let { period, regenerate } = this.state

                //Reset state
                onResetInvoices(false)

                //Launch request
                onRegenerateInvoices({
                    month: period.month,
                    year: period.year,
                    force: regenerate
                }, option)
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On Month Picked.
     * 
     * @param {*} year 
     * @param {*} month 
     * @param {*} option 
     */
    onMonthPicked = (year, month, option = "period") => {
        try {
            this.props.onSetInvoices({
                error: "", loading: false, success: false
            })

            this.setState({
                [option]: { year: year, month: month },
                regenerate: false
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    render() {

        const { opened, classes, option, loading, error, onClosed } = this.props
        const { period, regenerate } = this.state

        return (
            <Modal isOpen={opened} centered={true} className={classes}>
                <ModalHeader tag="h4">
                    {`${regenerate ? "Régé" : "Gé"}nérer les factures`}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {((error !== "") && (option === INVOICES_REGENERATE)) ? (
                            <Col xl="12">
                                <Alert color={`${(error === "Déjà Généré") ? "info" : "danger"}`}>
                                    {(error === "Déjà Généré") ? "Les factures ont déjà été générées pour cette période." : error}
                                </Alert>
                            </Col>
                        ) : null}

                        <div className="mb-3">
                            <label htmlFor="period" className="form-label">
                                Sélectionnez un mois
                            </label>

                            <MonthPicker
                                selected={period}
                                onPicked={this.onMonthPicked}
                                name="period"
                            />
                        </div>

                        <small className="text-muted fw-bold">
                            {`${(regenerate) ? "Forcer la génération" : "Générer"} des factures pour ${this.formatText(period)}`}
                        </small>

                        <small className="d-block text-danger mt-1">
                            *La génération des factures peut durer plusieurs minutes.
                            {(loading && (option === INVOICES_REGENERATE)) ? " Veuillez patienter et ne pas fermer la page." : ""}
                        </small>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {(loading && (option === INVOICES_REGENERATE)) ? (
                        <Loader />
                    ) : (
                        <>
                            <button
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-light"
                                onClick={() => onClosed("generateVisible", false)}
                            >
                                Fermer
                            </button>
                            <button
                                type="buttton"
                                className="btn btn-info save-generate"
                                onClick={() => this.onRegenerated()}
                            >
                                {(regenerate) ? "Forcer la génération" : "Générer"}
                            </button>
                        </>
                    )}
                </ModalFooter>
            </Modal>
        )
    }


}
GenerateForm.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    onClosed: PropTypes.func.isRequired
}
GenerateForm.defaultProps = {
    opened: false,
    classes: "invoice-generate-form-modal"
}
const mapStateToProps = state => ({
    alls: state.factures.invoices,
    error: state.factures.error,
    option: state.factures.option,
    success: state.factures.success,
    loading: state.factures.loading
})
const mapDispatchToProps = dispatch => ({
    onSetInvoices: (data) => dispatch(setInvoices(data)),
    onResetInvoices: (all) => dispatch(resetInvoices(all)),
    onRegenerateInvoices: (data, option) => dispatch(regenerateInvoice(data, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(GenerateForm)