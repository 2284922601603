// SELECT, INSERT, UPDATE, ATTACH, FILTER, FIND, GET   
export const ORDERS_SELECT = "ORDERS_SELECT"
export const ORDERS_INSERT = "ORDERS_INSERT"
export const ORDERS_UPDATE = "ORDERS_UPDATE"
export const ORDERS_ATTACH = "ORDERS_ATTACH"
export const ORDERS_FILTER = "ORDERS_FILTER"
export const ORDERS_AGENTS = "ORDERS_AGENTS"
export const ORDERS_GET = "ORDERS_GET"
export const ORDERS_FIND = "ORDERS_FIND"
export const ORDERS_RETRIEVE = "ORDERS_RETRIEVE"
export const ORDERS_REFUND = "ORDERS_REFUND"
export const ORDERS_COMPLETE = "ORDERS_COMPLETE"
export const ORDERS_FORWARD = "ORDERS_FORWARD"
export const ORDERS_CALCULATE = "ORDERS_CALCULATE"
export const ORDERS_PAID = "ORDERS_PAID"

// ERROR, SUCCESS, SET, RESET
export const ORDERS_ERROR = "ORDERS_ERROR"
export const ORDERS_SUCCESS = "ORDERS_SUCCESS"
export const ORDERS_SET = "ORDERS_SET"
export const ORDERS_RESET = "ORDERS_RESET"