import API, { ACCOUNT_BASE_URL } from './baseService';

export function login(data) {
    return API.post(`${ACCOUNT_BASE_URL}users/login`, data).then((response) => response).catch((error) => error);
}

export function logout() {
    return API.put(`${ACCOUNT_BASE_URL}users/logout`).then((response) => response).catch((error) => error);
}

export function updatePassword(data) {
    return API.put(`${ACCOUNT_BASE_URL}users/password`, data).then((response) => response).catch((error) => error);
}

export function validateSession() {
    return API.put(`${ACCOUNT_BASE_URL}users/session/validate`).then((response) => response).catch((error) => error);
}