import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Card, CardBody } from "reactstrap"

// import custom table component, constants
import CustomDatatable, {
    CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM
} from 'components/lists/CustomDatatable'

// import utilities
import { upperFirst } from 'helpers/utilities'


const IssueTable = ({ items, onAdding, onShowing, onEditing }) => {

    const columns = [
        {
            prop: "name",
            title: "Nom",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => `issue-row text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {row.name}
                    </span>
                );
            }
        },
        {
            prop: "gravity",
            title: "Gravité",
            filterable: true,
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <>
                        {row.gravity ? (
                            <span className={`badge bg-soft bg-${row.gravity.color} text-${row.gravity.color} fw-bolder fs-12`}>
                                {row.gravity.name}
                            </span>
                        ) : (
                            <span> - </span>
                        )}
                    </>
                );
            }
        },
        {
            prop: "menu",
            isDummyField: true,
            title: "",
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <i
                            className="bx bx-edit bx-xs bx-border text-secondary h-cursor"
                            id={`showtooltip_${row._id}`}
                            onClick={() => onEditing(row)}
                        />
                    </div>
                );
            }
        }
    ];

    const onFilter = {
        gravity: (value) => {
            return (value && value.name) ? value.name : "-"
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CustomDatatable
                        tableHeaders={columns}
                        tableBody={items}
                        rowsPerPage={TOTAL_PER_PAGE_SM}
                        rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                        initialSort={{ prop: '', isAscending: true }}
                        labels={{ ...LABELS, entries: `sur ${items.length} incidents` }}
                        classes={CLASSES}
                        leftOptions={{ leftAction: true, label: "Nouvel Incident", onClicked: onAdding }}
                        onFilter={onFilter}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
IssueTable.propTypes = {
    items: PropTypes.array,
    onAdding: PropTypes.func,
    onEditing: PropTypes.func
}
export default IssueTable