import React from 'react'
import PropTypes from 'prop-types'

// import custom table component, constants
import CustomDatatable, {
    CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM
} from 'components/lists/CustomDatatable';

// import utilities
import { upperFirst } from 'helpers/utilities';

// impoet constants
import { PRICINGS_AMOUNT, PRICINGS_PERCENTAGE } from 'helpers/constants';

const AdditionalTable = ({ items, onAdding, onEditing, onRemoving }) => {

    const columns = [
        {
            prop: "name",
            title: "Nom",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <span className="wrapped-text">
                        {`${row.name}`}
                    </span>
                );
            }
        },
        {
            prop: "value",
            title: "Valeur",
            filterable: true,
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <span className="wrapped-text fw-bolder">
                        {`${row.value}(${row.value_is_percentage ? PRICINGS_PERCENTAGE : PRICINGS_AMOUNT})`}
                    </span>
                );
            }
        },
        {
            prop: "menu",
            isDummyField: true,
            title: "",
            cellProps: {
                className: row => `text-center${(!row.enabled) ? " disabled-item" : ""}`
            },
            cell: row => {
                return (
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <i
                            className="bx bx-edit bx-xs bx-border text-secondary h-cursor"
                            id={`edittooltip_${row._id}`}
                            onClick={() => onEditing(row)}
                        />
                    </div>
                );
            }
        },
    ];

    return (
        <React.Fragment>
            <CustomDatatable
                tableHeaders={columns}
                tableBody={items}
                rowsPerPage={TOTAL_PER_PAGE_SM}
                rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                initialSort={{ prop: '', isAscending: true }}
                labels={{ ...LABELS, entries: `sur ${items.length} tarifs` }}
                classes={CLASSES}
                leftOptions={{ leftAction: true, label: "Nouveau Tarif", onClicked: onAdding }}
            />
        </React.Fragment>
    )

}
AdditionalTable.propTypes = {
    items: PropTypes.array,
    onAdding: PropTypes.func,
    onEditing: PropTypes.func,
    onRemoving: PropTypes.func
}
export default AdditionalTable