import React from 'react'
import PropTypes from 'prop-types'

// import omth picker
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'

export const CUSTOM_LABELS = {
    months: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juill', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
    from: 'de', to: 'à',
}

const CUSTOM_YEARS = { min: 2020 }

const MonthPicker = React.memo(({ name, selected, years, labels, onPicked }) => {

    const monthPickerRef = React.useRef(null);

    const onShow = () => {
        if (monthPickerRef && monthPickerRef.current) {
            monthPickerRef.current.show();
        }
    };

    const onHide = () => {
        if (monthPickerRef && monthPickerRef.current) {
            monthPickerRef.current.dismiss();
        }
    };

    const onChanged = (year, month, name) => {
        try {
            onHide()
            onPicked(year, month, name)
        } catch (error) {
            consoleErrorMessage(error)
        }
    };

    const formatSelected = (value) => {
        if (value && value.year && value.month) return (CUSTOM_LABELS.months[(value.month - 1)] + '. ' + value.year)
        return '?'
    }

    return (
        <Picker
            ref={monthPickerRef}
            years={years}
            value={selected}
            lang={labels.months}
            onChange={(year, month) => onChanged(year, month, name)}
        >
            <span onClick={onShow} className="month-picker-box">
                <i className="bx bxs-calendar" />&nbsp;
                {formatSelected(selected)}
                &nbsp;<i className="bx bx-caret-down" />
            </span>
        </Picker>
    );

});
MonthPicker.propTypes = {
    name: PropTypes.string,
    selected: PropTypes.object,
    years: PropTypes.any,
    labels: PropTypes.any,
    onPicked: PropTypes.func,
}
MonthPicker.defaultProps = {
    name: "",
    years: CUSTOM_YEARS,
    labels: CUSTOM_LABELS
}
export default MonthPicker