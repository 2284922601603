import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Card, CardBody } from "reactstrap"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom table component, constants
import CustomDatatable, {
    CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM
} from 'components/lists/CustomDatatable';

// import utilities
import {
    consoleErrorMessage, formatExportedFilename, formatPhoneNumberForDisplay, toDaysMinutesSeconds
} from 'helpers/utilities';

// import constants
import {
    NATURES_DOCUMENT, NATURES_ENCOMBRANT, NATURES_FRAGILE, NATURES_FRAIS
} from 'helpers/constants'


const PricesTable = ({ items, start, end, onShowed }) => {

    const getNatureCountFromItem = (item, option) => {
        try {
            let natureItem = item.natures.find((nx) => (nx.name === option))
            return ((natureItem && natureItem.count) ? natureItem.count : 0)
        } catch (error) {
            consoleErrorMessage(error)
            return 0
        }
    }

    const columns = [
        {
            prop: "price",
            filterable: true,
            sortable: true,
            title: "Prix(XOF)",
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span>
                        {row.price}
                    </span>
                );
            }
        },
        {
            prop: "count",
            title: "Total",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className={`badge bg-soft bg-secondary text-secondary fw-bold fs-12`}>
                        {row.count}
                    </span>
                );
            }
        },
        {
            prop: "natures",
            title: NATURES_DOCUMENT.name,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className={`badge bg-soft bg-${NATURES_DOCUMENT.color} text-${NATURES_DOCUMENT.color} fw-bold fs-12`}>
                        {getNatureCountFromItem(row, NATURES_DOCUMENT.key)}
                    </span>
                );
            }
        },
        {
            prop: "natures",
            title: NATURES_ENCOMBRANT.name,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className={`badge bg-soft bg-${NATURES_ENCOMBRANT.color} text-${NATURES_ENCOMBRANT.color} fw-bold fs-12`}>
                        {getNatureCountFromItem(row, NATURES_ENCOMBRANT.key)}
                    </span>
                );
            }
        },
        {
            prop: "natures",
            title: NATURES_FRAGILE.name,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className={`badge bg-soft bg-${NATURES_FRAGILE.color} text-${NATURES_FRAGILE.color} fw-bold fs-12`}>
                        {getNatureCountFromItem(row, NATURES_FRAGILE.key)}
                    </span>
                );
            }
        },
        {
            prop: "natures",
            title: NATURES_FRAIS.name,
            cellProps: {
                className: row => `text-center`
            },
            cell: row => {
                return (
                    <span className={`badge bg-soft bg-${NATURES_FRAIS.color} text-${NATURES_FRAIS.color} fw-bold fs-12`}>
                        {getNatureCountFromItem(row, NATURES_FRAIS.key)}
                    </span>
                );
            }
        },
    ];

    const headers = [
        { label: "Prix(XOF)", key: "price" },
        { label: "Total", key: "count" },
        { label: NATURES_DOCUMENT.name, key: "totalDocuments" },
        { label: NATURES_ENCOMBRANT.name, key: "totalBulkies" },
        { label: NATURES_FRAGILE.name, key: "totalFragiles" },
        { label: NATURES_FRAIS.name, key: "totalFreshes" },
    ];

    const formatedData = items.map((item) => ({
        ...item,
        totalDocuments: getNatureCountFromItem(item, NATURES_DOCUMENT.key),
        totalBulkies: getNatureCountFromItem(item, NATURES_ENCOMBRANT.key),
        totalFragiles: getNatureCountFromItem(item, NATURES_FRAGILE.key),
        totalFreshes: getNatureCountFromItem(item, NATURES_FRAIS.key)
    }))

    const formatedFilename = formatExportedFilename("totaux_courses_par_prix", start, end)

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CustomDatatable
                        tableHeaders={columns}
                        tableBody={items}
                        classes={CLASSES}
                        rowsPerPage={TOTAL_PER_PAGE_SM}
                        rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                        initialSort={{ prop: '', isAscending: true }}
                        labels={{ ...LABELS, entries: `sur ${items.length} prix` }}
                        leftOptions={{ leftAction: false, label: "", onClicked: {} }}
                        exportOptions={{
                            exportAction: true,
                            label: "Exporter",
                            filename: `${formatedFilename}.csv`,
                            headers: headers,
                            data: formatedData
                        }}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )

}
PricesTable.propTypes = {
    items: PropTypes.array,
    onExporting: PropTypes.func,
}
export default PricesTable