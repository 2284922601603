import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import store
import { connect } from 'react-redux'

// import actions
import { listOrders, resetOrders, setOrders } from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import custom common components
import Loader from 'components/common/Loader'
import OrderList from 'components/orders/OrderList'
import AgentHeader from './AgentHeader'
import OrderDetails from 'pages/orders/OrderDetails'
import OrderAssign from 'pages/orders/OrderAssign'
import OrderForward from 'pages/orders/OrderForward'

// import constants
import {
    APP_NAME, ORDERS_ASSIGN, ORDERS_LIST, ORDERS_SHOW, ORDERS_TRANSFERT
} from 'helpers/constants'

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'


class AgentActivity extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            detailsVisible: false,
            assignVisible: false,
            forwardVisible: false,
        }
    }

    componentDidUpdate(prevProps) {
        try {
            if ((!equal(prevProps, this.props))) {
                if ((!equal(prevProps.agent, this.props.agent))) {
                    this.onLoaded()
                }
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {
            // Reset state
            this.props.onResetOrders(true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "detailsVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On Loaded.
     * 
     */
    onLoaded = () => {
        try {
            let { agent, onListOrders } = this.props
            onListOrders(agent._id, ORDERS_LIST)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On Loaded.
     * 
     */
    onShowed = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ order: { _id: selected }, option: ORDERS_SHOW })

            //Show modal
            this.onToggled("detailsVisible", true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On assigned.
     * 
     */
    onAssigned = () => {
        try {
            //Get props
            let { onResetOrders, onSetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init option
            onSetOrders({ option: ORDERS_ASSIGN })

            //Show modal
            this.onToggled("assignVisible", true);
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
    * On transferred.
    * 
    */
    onTransferred = () => {
        try {
            //Get props
            let { onResetOrders, onSetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init option
            onSetOrders({ option: ORDERS_TRANSFERT })

            //Show modal
            this.onToggled("forwardVisible", true);
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On Loaded.
     * 
     */
    onRefreshed = () => {
        try {

        } catch (error) {
            consoleErrorMessage(error)
        }
    }


    render() {
        const { t, alls, orders, order, filter, loading, success, error, option, agent } = this.props
        const { detailsVisible, assignVisible, forwardVisible } = this.state

        return (
            <React.Fragment>
                <Row className="mb-2">
                    <Col xl="12">
                        {((error != "") && (option === ORDERS_LIST)) ? (
                            <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                {error}{" "}
                                <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                    Réessayer
                                </Link>
                            </Alert>
                        ) : (loading && (option === ORDERS_LIST)) ? (
                            <Loader color="primary" />
                        ) : (agent && agent._id) ? (
                            <Row>
                                <Col xl="6">

                                </Col>
                                <Col xl="6">
                                    {(agent && agent._id) ? (
                                        <AgentHeader {...agent} />
                                    ) : null}
                                </Col>

                                <OrderList
                                    alls={alls}
                                    orders={orders}
                                    order={order}
                                    filter={filter}
                                    onShowed={this.onShowed}
                                    onReloaded={this.onLoaded}
                                    showReload
                                    withFilter={false}
                                    withSearch={false}
                                    listClasses="orders-searches"
                                />
                            </Row>
                        ) : (
                            <p className="bg-light p-3 m-0 fs-12">
                                Sélectionner un utilisateur dans la liste pour consulter son activité.
                            </p>
                        )}
                    </Col>
                </Row>
                {(assignVisible) ? (
                    <OrderAssign
                        opened={assignVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
                {(forwardVisible) ? (
                    <OrderForward
                        opened={forwardVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
                {(detailsVisible) ? (
                    <OrderDetails
                        opened={detailsVisible}
                        onClosed={this.onToggled}
                        onTransferred={this.onTransferred}
                        onAssigned={this.onAssigned}
                    />
                ) : null}
            </React.Fragment>
        )
    }
}
AgentActivity.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    alls: PropTypes.array,
    orders: PropTypes.array,
    order: PropTypes.object,
    filter: PropTypes.object,
}
const mapStateToProps = state => ({
    alls: state.commandes.orders,
    orders: state.commandes.filtereds,
    order: state.commandes.order,
    filter: state.commandes.filter,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
    agent: state.utilisateurs.user,
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onListOrders: (filter, option) => dispatch(listOrders(filter, true, option)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(AgentActivity)))
