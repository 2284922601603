import React from 'react'
import PropTypes from 'prop-types'

import { Pie } from "react-chartjs-2"

const PolarChart = ({ encombrant, frais, fragile, document, items }) => {
    const data = {
        datasets: [
            {
                data: items.map((item) => (item.value)),
                // data: [encombrant.value, frais.value, fragile.value, document.value],
                // backgroundColor: [encombrant.color, frais.color, fragile.color, document.color],
                backgroundColor: items.map((item) => (item.hex)),
                label: "Natures Revenues Dataset", // for legend
                hoverBorderColor: "#fff",
                hoverBackgroundColor: items.map((item) => (item.hex)),
            },
        ],
        // labels: items.map((item) => (item.name)),
        // labels: [encombrant.name, frais.name, fragile.name, document.name],
    }

    return (
        <React.Fragment>
            <Pie width={574} height={400} data={data} />
        </React.Fragment>
    )
}

PolarChart.propTypes = {}
PolarChart.defaultProps = {}
export default PolarChart