// SELECT, GET, INSERT, UPDATE
export const USERS_SELECT = "USERS_SELECT"
export const USERS_GET = "USERS_GET"
export const USERS_INSERT = "USERS_INSERT"
export const USERS_UPDATE = "USERS_UPDATE"

// ERROR, SUCCESS, SET, RESET
export const USERS_ERROR = "USERS_ERROR"
export const USERS_SUCCESS = "USERS_SUCCESS"
export const USERS_SET = "USERS_SET"
export const USERS_RESET = "USERS_RESET"