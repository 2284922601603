// @flow
import { AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AUTH_RESET, AUTH_SESSION, AUTH_SUCCESS, AUTH_UNAUTHORIZED, AUTH_UPDATE } from "./constants"

const INITIAL_STATE = {
	option: "",
	error: "",
	loading: false,
	success: false,
	unauthorized: false
}

const auth = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case AUTH_LOGIN:
		case AUTH_LOGOUT:
		case AUTH_UPDATE:
		case AUTH_SESSION:
			return {
				...state,
				error: "",
				option: payload.option,
				success: false,
				loading: true
			}

		case AUTH_SUCCESS:
			return {
				...state,
				loading: false,
				success: true
			}

		case AUTH_ERROR:
			return {
				...state,
				error: payload.error,
				loading: false
			}

		case AUTH_UNAUTHORIZED:
			return {
				...state,
				unauthorized: true
			}

		case AUTH_RESET:
			return {
				...state,
				error: INITIAL_STATE.error,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success,
				option: INITIAL_STATE.option,
				unauthorized: (payload.all) ? INITIAL_STATE.unauthorized : state.unauthorized
			}

		default:
			return { ...state }
	}

}

export default auth