import React from "react"
import PropTypes from 'prop-types'

// import components
import {
    Row, Col, Input, Label, Form, FormGroup, InputGroup, InputGroupText, FormText
} from "reactstrap"

// Form Mask
import InputMask from "react-input-mask"

// import custom recorder component & hook
import useRecorder from "components/recorder/useRecorder"
import Recorder from "components/recorder/Recorder"

// import constants
import {
    DESCRIPTION_AUDIO, DESCRIPTION_TEXT, MAX_DESCRIPTION_INPUT, MIN_DESCRIPTION_INPUT, NATURES_DOCUMENT,
    NATURES_ENCOMBRANT, NATURES_FRAGILE, NATURES_FRAIS, ORDERS_DELIVERY, ORDERS_PICKUP
} from "helpers/constants"

const NATURES_ITEMS = [NATURES_DOCUMENT, NATURES_ENCOMBRANT, NATURES_FRAGILE, NATURES_FRAIS];

const ComposeTab = React.memo(({ order, onChanged, onLocated, onRecorded, onSwitched }) => {

    const { record, ...handlers } = useRecorder();
    const { audio, mediaBlob, initRecording } = record;

    const {
        pickup_contact, pickup_point, delivery_contact, delivery_point, delivery_auth,
        description_type, description_file, description_textual, round_trip, nature
    } = order

    React.useEffect(() => {
        onRecorded({ link: audio, data: mediaBlob, initRecord: initRecording })
    }, [audio, initRecording]);


    const onDesciptionSwitched = (type) => {
        if (!initRecording) onSwitched(type, "description_type")
    }

    return (
        <React.Fragment>
            <div style={{ minHeight: '350px' }}>
                <Form>
                    <Row className="mb-3">
                        <Col xl="8">
                            <Row>
                                <Col xl="12" className="mb-3">
                                    <FormGroup>
                                        <Label htmlFor="pickup_point">
                                            Point d'enlèvement {" "}
                                            <small className="text-danger fs-13">*</small>
                                        </Label>
                                        <InputGroup>
                                            <InputGroupText
                                                className="h-cursor"
                                                onClick={() => onLocated(ORDERS_PICKUP)}
                                            >
                                                <i className="bx bxs-map text-orange bx-xs" />
                                            </InputGroupText>
                                            <Input
                                                required
                                                readOnly
                                                type="textarea"
                                                rows="2"
                                                className="form-control"
                                                id="pickup_point"
                                                name="pickup_point"
                                                value={(pickup_point && pickup_point.address) ? pickup_point.address : ""}
                                            />
                                        </InputGroup>
                                        <FormText>
                                            Cliquez sur <i className="bx bxs-map text-muted" /> pour selectionner le point d'enlèvement
                                        </FormText>
                                    </FormGroup>
                                </Col>

                                <Col xl="12" className="mb-3">
                                    <FormGroup>
                                        <Label htmlFor="delivery_point">
                                            Point de livraison {" "}
                                            <small className="text-danger fs-13">*</small>
                                        </Label>
                                        <InputGroup>
                                            <InputGroupText
                                                className="h-cursor"
                                                onClick={() => onLocated(ORDERS_DELIVERY)}
                                            >
                                                <i className="bx bxs-map text-blue bx-xs" />
                                            </InputGroupText>
                                            <Input
                                                required
                                                readOnly
                                                type="textarea"
                                                rows="2"
                                                className="form-control"
                                                id="delivery_point"
                                                name="delivery_point"
                                                value={(delivery_point && delivery_point.address) ? delivery_point.address : ""}
                                            />
                                        </InputGroup>
                                        <FormText>
                                            Cliquez sur <i className="bx bxs-map text-muted" /> pour selectionner le point de livraison
                                        </FormText>
                                    </FormGroup>
                                </Col>

                                <Col xl="12" className="mb-1">
                                    <FormGroup>
                                        <Label
                                            htmlFor="description_point"
                                            className="d-flex align-items-center justify-content-between"
                                        >
                                            <span>
                                                Description de la course {" "}
                                                <small className="text-danger fs-13">*</small>
                                            </span>
                                            <span className="description-switches">
                                                <span
                                                    className={`description-switch switch-vocal${(description_type === DESCRIPTION_AUDIO) ? " active" : ""}`}
                                                    onClick={() => onDesciptionSwitched(DESCRIPTION_AUDIO)}
                                                >
                                                    Description vocale
                                                    <i className="bx bxs-microphone ms-1" />
                                                </span>
                                                <span
                                                    className={`description-switch switch-textual${(description_type === DESCRIPTION_TEXT) ? " active" : ""}`}
                                                    onClick={() => onDesciptionSwitched(DESCRIPTION_TEXT)}
                                                >
                                                    Description textuelle
                                                    <i className="bx bx-align-justify ms-1" />
                                                </span>
                                            </span>
                                        </Label>
                                        {(description_type === DESCRIPTION_AUDIO) ? (
                                            <div className="description-content border-control rounded p-2">
                                                <p className="m-0 p-2 fs-12">
                                                    Décrivez vocalement votre course entre 30 secondes et 2 minutes !
                                                </p>

                                                <Recorder record={record} handlers={handlers} />

                                                {(description_file && (description_file !== "")) ? (
                                                    <div className="description-audio">
                                                        <audio controls="controls" preload="metadata" className="m-0 w-100 rounded mt-2">
                                                            <source src={description_file} type="audio/mpeg" />
                                                            Votre navigateur ne prend pas en charge l'élément <code>audio</code>.
                                                        </audio>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <>
                                                <Input
                                                    required={(description_type === DESCRIPTION_TEXT)}
                                                    type="textarea"
                                                    rows="5"
                                                    className="form-control"
                                                    id="description_textual"
                                                    name="description_textual"
                                                    placeholder="Décrivez votre course ici entre 50 et 500 caractères..."
                                                    minLength={MIN_DESCRIPTION_INPUT}
                                                    maxLength={MAX_DESCRIPTION_INPUT}
                                                    value={description_textual || ""}
                                                    onChange={onChanged}
                                                />
                                                {(description_textual && (description_textual !== "")) ? (
                                                    <span className={`badgecount badge bg-${(description_textual.length >= 50) ? "secondary" : "danger"}`}>
                                                        {" "}
                                                        {`${description_textual.length}`} / {MAX_DESCRIPTION_INPUT}{" "}
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>

                        <Col xl="4">
                            <Row>
                                <Col xl="12" className="mb-3">
                                    <FormGroup>
                                        <Label htmlFor="pickup_contact">
                                            Contact à l'enlèvement {" "}
                                            <small className="text-danger fs-13">*</small>
                                        </Label>
                                        <InputMask
                                            required
                                            type="text"
                                            className="form-control"
                                            mask="(+22\9) 99 99 99 99"
                                            maskChar="-"
                                            id="pickup_contact"
                                            name="pickup_contact"
                                            value={pickup_contact || ""}
                                            onChange={onChanged}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xl="12" className="mb-3">
                                    <FormGroup>
                                        <Label htmlFor="delivery_contact">
                                            Contact à la livraison {" "}
                                            <small className="text-danger fs-13">*</small>
                                        </Label>
                                        <InputMask
                                            required
                                            type="text"
                                            className="form-control"
                                            mask="(+22\9) 99 99 99 99"
                                            maskChar="-"
                                            id="delivery_contact"
                                            name="delivery_contact"
                                            value={delivery_contact || ""}
                                            onChange={onChanged}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xl="12" className="mb-3">
                                    <FormGroup>
                                        <Label htmlFor="nature">
                                            Nature du colis {" "}
                                            <small className="text-danger fs-13">*</small>
                                        </Label>
                                        <Input
                                            className="form-select"
                                            id="nature"
                                            name="nature"
                                            type="select"
                                            onChange={(e) => onChanged(e)}
                                            value={nature || ""}
                                        >
                                            <option value="">---</option>
                                            {NATURES_ITEMS.map((item, index) => (
                                                <option key={`nature_option_${index}`} value={item.key}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col xl="12" className="mb-3">
                                    <div className="form-group">
                                        <div className={`custom-check${(delivery_auth) ? " active" : ""}`}>
                                            <div className="form-check form-switch form-switch-md check-control">
                                                <input
                                                    name="delivery_auth"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="deliverySwitch"
                                                    checked={delivery_auth || false}
                                                    onChange={(e) => onChanged(e)}
                                                />
                                                <label className="form-check-label" htmlFor="deliverySwitch">
                                                    <span className={`${(delivery_auth) ? "fw-bolder" : "fw-light"}`}>
                                                        Activer code de livraison
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <FormText>
                                            La livraison de la course sera effective en renseignant le code de livraison.
                                        </FormText>
                                    </div>
                                </Col>

                                <Col xl="12" className="mb-1">
                                    <div className="form-group">
                                        <div className={`custom-check${(round_trip) ? " active" : ""}`}>
                                            <div className="form-check form-switch form-switch-md check-control">
                                                <input
                                                    name="round_trip"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="roundSwitch"
                                                    checked={round_trip || false}
                                                    onChange={(e) => onChanged(e)}
                                                />
                                                <label className="form-check-label" htmlFor="roundSwitch">
                                                    <span className={`${(round_trip) ? "fw-bolder" : "fw-light"}`}>
                                                        Aller-Retour
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <FormText>
                                            L'aller-retour compte comme deux courses et le client sera doublement facturé.
                                        </FormText>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col xl="12">
                            <small className="text-muted fs-13">
                                Les champs avec <strong className="text-danger">*</strong> sont obligatoires
                            </small>
                        </Col>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    );

});
ComposeTab.propTypes = {
    order: PropTypes.object
}
ComposeTab.defaultProps = {
    order: {}
}
export default ComposeTab