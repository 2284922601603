import React from "react"
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Input, Label } from "reactstrap"

// import form components
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"

// import custom components
import Loader from '../../components/common/Loader'

// import constants
import {
    MD_TEXT_INPUT, MIN_TEXT_INPUT, PRICINGS_ADD, PRICINGS_AMOUNT, PRICINGS_EDIT, PRICINGS_PERCENTAGE, PRICINGS_REMOVE, PRICING_MESSAGE
} from "helpers/constants"


const AdditionalForm = React.memo(({ opened, modalOption, modalClass, modalError, modalLoading, onClosed, onSubmited, onChecked, onChanged, onClicked, additional }) => {

    return (
        <Modal isOpen={opened} centered={true} className={modalClass}>
            <ModalHeader tag="h4">
                {`${(modalOption === PRICINGS_EDIT) ? "Modifier" : (modalOption === PRICINGS_REMOVE) ? "Supprimer" : "Ajouter"} un tarif supplémentaire`}
            </ModalHeader>
            <AvForm onValidSubmit={onSubmited}>
                <ModalBody>
                    <Row>
                        {(modalError && modalError !== "") ? (
                            <Col xl="12">
                                <Alert color="danger">{modalError}</Alert>
                            </Col>
                        ) : null}

                        <Col xl="12" className="mb-3">
                            <AvField
                                readOnly={(modalOption === PRICINGS_REMOVE)}
                                name="name"
                                label="Nom"
                                type="text"
                                value={additional.name || ""}
                                onChange={(e) => onChanged(e)}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Le nom est obligatoire.',
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`Le nom a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                            {((modalOption !== PRICINGS_REMOVE) && additional.name && (additional.name != "")) ? (
                                <span className="badgecount badge bg-secondary">
                                    {" "}
                                    {`${additional.name.length}`} / {MD_TEXT_INPUT}{" "}
                                </span>
                            ) : null}
                        </Col>

                        <Col xl="12" className="mb-3">
                            {(modalOption === PRICINGS_REMOVE) ? (
                                <AvField
                                    readOnly
                                    name="value"
                                    label="Valeur"
                                    type="text"
                                    value={`${additional.value}(${(additional.value_is_percentage) ? PRICINGS_PERCENTAGE : PRICINGS_AMOUNT})`}
                                />
                            ) : (
                                <AvGroup>
                                    <Label> Valeur </Label>
                                    <div className="input-group">
                                        <AvInput
                                            name="value"
                                            type="number"
                                            aria-describedby="value-help-block"
                                            aria-describedby="addon-pricing-value"
                                            value={additional.value || ""}
                                        />
                                        <span
                                            id="addon-pricing-value"
                                            className="input-group-text h-cursor"
                                            onClick={() => onClicked("value_is_percentage")}
                                        >
                                            {(additional.value_is_percentage) ? PRICINGS_PERCENTAGE : PRICINGS_AMOUNT}
                                        </span>
                                        <span id="value-help-block" className="form-text">
                                            {PRICING_MESSAGE}
                                        </span>
                                    </div>
                                </AvGroup>
                            )}
                        </Col>

                        <Col xl="12" className="mb-1">
                            {(modalOption === PRICINGS_REMOVE) ? (
                                <h5 className="text-center px-3">
                                    Voulez-vous supprimer ce tarif supplémentaire ?
                                </h5>
                            ) : (
                                <div className="form-group">
                                    <div className={`custom-check${(additional.enabled) ? " active" : ""}`}>
                                        <div className="form-check form-switch form-switch-md check-control">
                                            <input
                                                name="enabled"
                                                type="checkbox"
                                                className="form-check-input"
                                                id="enabledSwitch"
                                                checked={additional.enabled || false}
                                                onChange={(e) => onChecked(e)}
                                            />
                                            <label className="form-check-label" htmlFor="enabledSwitch">
                                                <span className={`${(additional.enabled) ? "fw-bolder" : "fw-light"}`}>
                                                    {(additional.enabled) ? "Activé" : "Activé"}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {(modalLoading) ? (
                        <Loader color={`${(modalOption === PRICINGS_EDIT) ? "warning" : (modalOption === PRICINGS_REMOVE) ? "danger" : "info"}`} />
                    ) : (
                        <>
                            <button type="button" data-dismiss="modal" className="btn btn-light" onClick={() => onClosed()}>
                                Fermer
                            </button>
                            <button type="submit" className={`btn btn-${(modalOption === PRICINGS_EDIT) ? "warning" : (modalOption === PRICINGS_REMOVE) ? "danger" : "info"} save-additional`}>
                                {
                                    (modalOption == PRICINGS_EDIT) ? "Modifier" : (modalOption === PRICINGS_REMOVE) ? "Supprimer" : "Ajouter"
                                }
                            </button>
                        </>
                    )}
                </ModalFooter>
            </AvForm>
        </Modal>
    );

});
AdditionalForm.propTypes = {
    opened: PropTypes.bool.isRequired,
    modalClass: PropTypes.string,
    modalOption: PropTypes.string,
    modalError: PropTypes.string,
    modalLoading: PropTypes.bool,
    onChanged: PropTypes.func.isRequired,
    onChecked: PropTypes.func.isRequired,
    onClosed: PropTypes.func.isRequired,
    onSubmited: PropTypes.func.isRequired,
    onClicked: PropTypes.func.isRequired,
    additional: PropTypes.object
}
AdditionalForm.defaultProps = {
    modalClass: "additionals-form-modal",
    modalOption: "",
    modalError: "",
    modalLoading: false,
    additional: {}
}
export default AdditionalForm