import React from "react"

// import router
import { Redirect } from "react-router-dom"

// Login
import Login from "../pages/authentication/Login"

// Dashboard
import Dashboard from "pages/dashboard"

// Agencies
import Agencies from "pages/agencies"

// Supervisors
import Supervisors from "pages/supervisors"

// Masters
import Masters from "pages/masters"

// Partners
import Partners from "pages/partners"

// Factors
import Factors from "pages/factors"

// Incidents
import Issues from "pages/issues"

// Billers
import Billers from "pages/billers"

// Invoices
import Invoices from "pages/invoices"

// Pricings
import Pricings from "pages/pricings"

// DailyOrders
import DailyOrders from "pages/daily-orders"

// SearchOrders
import SearchOrders from "pages/search-orders"

// CreateOrders
import CreateOrders from "pages/create-orders"

// Statistics : AgencyOrders, CompletedOrders, CustomerOrders
import AgencyOrders from "pages/statistics/agency-orders"
import CompletedOrders from "pages/statistics/completed-orders"
import RefundedOrders from "pages/statistics/refunded-orders"
import CustomerOrders from "pages/statistics/customer-orders"
import ActiveAgents from "pages/statistics/active-agents"

// SearchFactures
import SearchFactures from "pages/search-factures"

// TreatOrders
import TreatOrders from "pages/treat-orders"

// Logging
import Logging from "pages/logging"

//AgentOrders
import AgentOrders from "pages/agents-orders"

// import constants
import {
	USER_AGENCY, USER_BILLER, USER_KEY, USER_MASTER, USER_ROOT, USER_SUPERVISOR
} from "helpers/constants"

// OrdersByPrices
import OrdersByPrices from "pages/statistics/orders-by-prices"


// Get redirection link for /
const userRole = localStorage.getItem(USER_KEY) ? JSON.parse(localStorage.getItem(USER_KEY)).role : ""
const redirectionLink = (userRole === USER_AGENCY.key) ? "/agency-orders" : "/dashboard"
const redirectionRoles = (userRole === USER_AGENCY.key) ? [USER_AGENCY.key] : [USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key, USER_BILLER.key]

const protectedRoutes = [
	{ path: "/dashboard", component: Dashboard, roles: [USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key, USER_BILLER.key] },
	{ path: "/agences", component: Agencies, roles: [USER_MASTER.key, USER_ROOT.key] },
	{ path: "/superviseurs", component: Supervisors, roles: [USER_MASTER.key, USER_ROOT.key] },
	{ path: "/super-administrateurs", component: Masters, roles: [USER_ROOT.key] },
	{ path: "/journalisation", component: Logging, roles: [USER_MASTER.key, USER_ROOT.key] },
	{ path: "/facturiers", component: Billers, roles: [USER_MASTER.key, USER_ROOT.key] },
	{ path: "/factures", component: Invoices, roles: [USER_MASTER.key, USER_ROOT.key, USER_BILLER.key] },
	{ path: "/clients-contractuels", component: Partners, roles: [USER_MASTER.key, USER_ROOT.key] },
	{ path: "/facteurs", component: Factors, roles: [USER_MASTER.key, USER_ROOT.key] },
	{ path: "/incidents", component: Issues, roles: [USER_MASTER.key, USER_ROOT.key] },
	{ path: "/pricings", component: Pricings, roles: [USER_MASTER.key, USER_ROOT.key] },
	{ path: "/daily-orders", component: DailyOrders, roles: [USER_SUPERVISOR.key] },
	{ path: "/activite-utilisateurs", component: AgentOrders, roles: [USER_SUPERVISOR.key] },
	{ path: "/search-factures", component: SearchFactures, roles: [USER_MASTER.key, USER_ROOT.key, USER_BILLER.key] },
	{ path: "/search-orders", component: SearchOrders, roles: [USER_SUPERVISOR.key, USER_BILLER.key, USER_AGENCY.key] },
	{ path: "/create-orders", component: CreateOrders, roles: [USER_AGENCY.key] },
	{ path: "/agency-orders", component: TreatOrders, roles: [USER_AGENCY.key] },
	{ path: "/statistics/agency-orders", component: AgencyOrders, roles: [USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key] },
	{ path: "/statistics/courses-par-prix", component: OrdersByPrices, roles: [USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key] },
	{ path: "/statistics/completed-orders", component: CompletedOrders, roles: [USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key] },
	{ path: "/statistics/refunded-orders", component: RefundedOrders, roles: [USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key] },
	{ path: "/statistics/customers-orders", component: CustomerOrders, roles: [USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key] },
	{ path: "/statistics/actives-agents", component: ActiveAgents, roles: [USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key] },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to={redirectionLink} />, roles: redirectionRoles },
]

const publicRoutes = [
	{ path: "/login", component: Login },
]

export { protectedRoutes, publicRoutes }
