import React from 'react'
import PropTypes from 'prop-types'

// import i18n
import { withTranslation } from "react-i18next"

// import router
import { withRouter } from "react-router-dom"

// import toaster
import { toast } from 'react-toastify'

// import dropdowns components
import Profile from 'components/dropdowns/Profile'
import Notifications from 'components/dropdowns/Notifications'


class Header extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            screenFulled: false,
            withNotificationsMenu: false
        }
    }

    /**
     * Fullscreen toggle events.
     * Alternative standard method.
     * 
     */
    onFullscreenToggled = () => {
        // Update state
        this.setState(prevState => ({ screenFulled: !prevState.screenFulled }))

        // Fullscreen process
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            if (document.documentElement.requestFullscreen) {// current working methods
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    /**
     * Sidebar toggle events.
     * 
     */
    onSidebarToggled = () => {
        var body = document.body;
        body.classList.toggle("vertical-collpsed");
        body.classList.toggle("sidebar-enable");
    }

    render() {

        const { screenFulled, withNotificationsMenu } = this.state

        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            {/* Sidebar Toggle Button */}
                            <div className="dropdown header-item px-1 center-box">
                                <button
                                    type="button"
                                    onClick={this.onSidebarToggled}
                                    className="btn btn-sm noti-icon border-0"
                                    id="vertical-menu-btn"
                                >
                                    <i className="fa fa-bars text-muted fw-bold" />
                                </button>
                            </div>
                        </div>

                        <div className="d-flex">
                            {/* Fullscreen Toggle Button */}
                            <div className="dropdown header-item px-1 center-box d-none d-lg-inline-flex me-1">
                                <button
                                    type="button"
                                    onClick={this.onFullscreenToggled}
                                    className="btn btn-sm noti-icon border-0"
                                    data-toggle="fullscreen"
                                >
                                    <i className={`fa fa-${screenFulled ? "compress" : "expand"} text-muted fw-bold`} />
                                </button>
                            </div>

                            {/* Notification Dropdown */}
                            {withNotificationsMenu ? (
                                <Notifications />
                            ) : null}

                            {/* Profile Dropdown */}
                            <Profile />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }

}
Header.propTypes = {
    history: PropTypes.any,
}
export default withRouter(Header)
