import React from 'react'
import PropTypes from "prop-types"

// import scroll bar
import SimpleBar from "simplebar-react"

// immport menus
import MetisMenu from "metismenujs"

// import router
import { withRouter, Link } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import constants
import {
    USER_AGENCY, USER_BILLER, USER_KEY, USER_MASTER, USER_ROOT, USER_SUPERVISOR
} from 'helpers/constants'

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'


class SidebarMenu extends React.Component {

    constructor(props) {
        super(props)

        this.refDiv = React.createRef()

        // init state
        this.state = {
            user_role: "",
            is_contact_agency: false
        }
    }

    componentDidMount() {
        //init sidebar menu
        this.initMenu()

        try {
            if (localStorage.getItem(USER_KEY)) {// extract user data
                let { role, contact_point } = JSON.parse(localStorage.getItem(USER_KEY))
                this.setState({
                    user_role: role,
                    is_contact_agency: (contact_point ? contact_point : false),
                })
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.type !== prevProps.type) this.initMenu()
    }

    /**
     * Init Menu.
     */
    initMenu() {
        new MetisMenu("#side-menu")

        let matchingMenuItem = null
        const ul = document.getElementById("side-menu")
        const items = ul.getElementsByTagName("a")
        const { pathname } = this.props.location
        for (let i = 0; i < items.length; ++i) {
            if (pathname === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }
        if (matchingMenuItem) this.activateParentDropdown(matchingMenuItem)
    }

    /**
     * Scroll Element.
     * 
     * @param {*} item 
     */
    scrollElement = item => {
        setTimeout(() => {
            if (this.refDiv.current !== null) {
                if (item) {
                    const currentPosition = item.offsetTop
                    if (currentPosition > window.innerHeight) {
                        if (this.refDiv.current)
                            this.refDiv.current.getScrollElement().scrollTop = (currentPosition - 300)
                    }
                }
            }
        }, 300)
    }

    /**
     * Activate Parent Dropdown.
     * 
     * @param {*} item 
     * @returns 
     */
    activateParentDropdown = item => {
        item.classList.add("active")
        const parent = item.parentElement

        const parent2El = parent.childNodes[1]
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show")
        }

        if (parent) {
            parent.classList.add("mm-active")
            const parent2 = parent.parentElement
            if (parent2) {
                parent2.classList.add("mm-show") // ul tag
                const parent3 = parent2.parentElement // li tag
                if (parent3) {
                    parent3.classList.add("mm-active") // li
                    parent3.childNodes[0].classList.add("mm-active") // a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add("mm-show") // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add("mm-show") // li
                            parent5.childNodes[0].classList.add("mm-active") // a tag
                        }
                    }
                }
            }
            this.scrollElement(item)
            return false
        }
        this.scrollElement(item)
        return false
    }


    render() {
        const { user_role, is_contact_agency } = this.state
        const { t, location } = this.props

        return (
            <React.Fragment>
                <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">{t("Vue d'ensemble")}</li>
                            <li className={`d-${[USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key, USER_BILLER.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/dashboard">
                                    <i className='bx bxs-dashboard' />
                                    <span>{t("Tableau de bord")}</span>
                                </Link>
                            </li>

                            <li className={`d-${[USER_SUPERVISOR.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/daily-orders">
                                    <i className="bx bxs-cube-alt" />
                                    <span>{t("Courses à traiter")}</span>
                                </Link>
                            </li>
                            <li className={`d-${[USER_SUPERVISOR.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/activite-utilisateurs">
                                    <i className="bx bxs-cube" />
                                    <span>{t("Activité des utilisateurs")}</span>
                                </Link>
                            </li>

                            <li className={`d-${[USER_AGENCY.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/agency-orders">
                                    <i className="bx bxs-cube-alt" />
                                    <span>{t("Mes courses")}</span>
                                </Link>
                            </li>
                            <li className={`d-${([USER_AGENCY.key].includes(user_role) && is_contact_agency) ? "block" : "none"}`}>
                                <Link to="/create-orders">
                                    <i className="bx bxs-add-to-queue" />
                                    <span>{t("Créer une course")}</span>
                                </Link>
                            </li>

                            <li className={`d-${[USER_MASTER.key, USER_ROOT.key, USER_BILLER.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/factures">
                                    <i className="bx bx-spreadsheet" />
                                    <span>{t("Factures")}</span>
                                </Link>
                            </li>
                            <li className={`d-${[USER_MASTER.key, USER_ROOT.key, USER_BILLER.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/search-factures">
                                    <i className="bx bx-search-alt" />
                                    <span>{t("Rechercher une facture")}</span>
                                </Link>
                            </li>

                            <li className={`d-${[USER_SUPERVISOR.key, USER_BILLER.key, USER_AGENCY.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/search-orders">
                                    <i className="bx bx-search-alt" />
                                    <span>{t("Rechercher une course")}</span>
                                </Link>
                            </li>

                            <li className={`d-${[USER_MASTER.key, USER_ROOT.key, USER_SUPERVISOR.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bx-bar-chart-alt" />
                                    <span>{this.props.t("Statistiques - Listes")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/statistics/customers-orders">
                                            <span>{t("Les clients")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/statistics/completed-orders">
                                            <span>{t("Les courses abouties")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/statistics/refunded-orders">
                                            <span>{t("Les courses remboursées")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/statistics/agency-orders">
                                            <span>{t("Les courses en agence")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/statistics/courses-par-prix">
                                            <span>{t("Les courses par prix")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/statistics/actives-agents">
                                            <span>{t("Les utilisateurs actifs")}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={`menu-title d-${[USER_MASTER.key, USER_ROOT.key].includes(user_role) ? "block" : "none"}`}>
                                {t("Paramètres")}
                            </li>

                            <li className={`d-${[USER_MASTER.key, USER_ROOT.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/#" className="has-arrow">
                                    <i className="bx bxs-group" />
                                    <span>{this.props.t("Utilisateurs")}</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                        <Link to="/agences">
                                            <span>{t("Agences")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/facteurs">
                                            <span>{t("Facteurs")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/superviseurs">
                                            <span>{t("Superviseurs")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/facturiers">
                                            <span>{t("Facturiers")}</span>
                                        </Link>
                                    </li>
                                    <li className={`d-${[USER_ROOT.key].includes(user_role) ? "block" : "none"}`}>
                                        <Link to="/super-administrateurs">
                                            <span>{t("Super Administrateurs")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/clients-contractuels">
                                            <span>{t("Clients Contractuels")}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={`d-${[USER_MASTER.key, USER_ROOT.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/incidents">
                                    <i className="bx bxs-error-circle" />
                                    <span>{t("Incidents")}</span>
                                </Link>
                            </li>
                            <li className={`d-${[USER_MASTER.key, USER_ROOT.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/pricings">
                                    <i className="bx bx-money" />
                                    <span>{t("Tarification")}</span>
                                </Link>
                            </li>
                            <li className={`d-${[USER_MASTER.key, USER_ROOT.key].includes(user_role) ? "block" : "none"}`}>
                                <Link to="/journalisation">
                                    <i className="bx bxs-report" />
                                    <span>{t("Journalisation")}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </SimpleBar>
            </React.Fragment>
        )
    }

}
SidebarMenu.propTypes = {
    t: PropTypes.any,
    location: PropTypes.object,
    type: PropTypes.string,
}
export default withRouter(withTranslation()(SidebarMenu))
