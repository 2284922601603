import API, { ORDER_BASE_URL } from './baseService';

export function selectPricings() {
    return API.get(`${ORDER_BASE_URL}prices/additional`).then((response) => response).catch((error) => error);
}

export function getPricing(id) {
    return API.get(`${ORDER_BASE_URL}prices/additional/${id}`).then((response) => response).catch((error) => error);
}

export function insertPricing(data) {
    return API.post(`${ORDER_BASE_URL}prices/additional`, data).then((response) => response).catch((error) => error);
}

export function updatePricing(id, data) {
    return API.put(`${ORDER_BASE_URL}prices/additional/${id}`, data).then((response) => response).catch((error) => error);
}

export function deletePricing(id) {
    return API.delete(`${ORDER_BASE_URL}prices/additional/${id}`).then((response) => response).catch((error) => error);
}

export function getConfigs() {
    return API.get(`${ORDER_BASE_URL}prices/config`).then((response) => response).catch((error) => error);
}

export function setConfigs(data) {
    return API.put(`${ORDER_BASE_URL}prices/config`, data).then((response) => response).catch((error) => error);
}

export function calculatePricing({ s_lat, s_lng, d_lat, d_lng, rt }) {
    return API.get(`${ORDER_BASE_URL}prices/of?s_lat=${s_lat}&s_lng=${s_lng}&d_lat=${d_lat}&d_lng=${d_lng}&rt=${rt}`).then((response) => response).catch((error) => error);
}