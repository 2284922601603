import { STATISTICS_ERROR, STATISTICS_GET, STATISTICS_GET_ACTIVES_AGENTS, STATISTICS_GET_AGENCY_ORDERS, STATISTICS_GET_COMPLETED_ORDERS, STATISTICS_GET_CUSTOMERS, STATISTICS_GET_ORDERS_BY_PRICES, STATISTICS_GET_REFUNDED_ORDERS, STATISTICS_RESET, STATISTICS_SET, STATISTICS_SUCCESS } from "./constants"


const INITIAL_STATE = {
	all_statistics: {},
	agent_statistics: [],
	agency_statistics: [],
	customer_statistics: [],
	completed_statistics: [],
	refunded_statistics: [],
	prices_statistics: [],
	error: "",
	option: "",
	loading: false,
	success: false
}

const statistiques = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case STATISTICS_GET:
		case STATISTICS_GET_CUSTOMERS:
		case STATISTICS_GET_AGENCY_ORDERS:
		case STATISTICS_GET_COMPLETED_ORDERS:
		case STATISTICS_GET_REFUNDED_ORDERS:
		case STATISTICS_GET_ACTIVES_AGENTS:
		case STATISTICS_GET_ORDERS_BY_PRICES:
			return {
				...state,
				error: "",
				option: payload.option,
				loading: true,
				success: false
			}

		case STATISTICS_SUCCESS:
			return {
				...state,
				all_statistics: payload.data.all_statistics ? payload.data.all_statistics : state.all_statistics,
				agent_statistics: payload.data.agent_statistics ? payload.data.agent_statistics : state.agent_statistics,
				agency_statistics: payload.data.agency_statistics ? payload.data.agency_statistics : state.agency_statistics,
				customer_statistics: payload.data.customer_statistics ? payload.data.customer_statistics : state.customer_statistics,
				completed_statistics: payload.data.completed_statistics ? payload.data.completed_statistics : state.completed_statistics,
				refunded_statistics: payload.data.refunded_statistics ? payload.data.refunded_statistics : state.refunded_statistics,
				prices_statistics: payload.data.prices_statistics ? payload.data.prices_statistics : state.prices_statistics,
				option: payload.option,
				loading: false,
				success: true
			}

		case STATISTICS_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case STATISTICS_SET:
			return {
				...state,
				all_statistics: (payload.all_statistics) ? payload.all_statistics : state.all_statistics,
				agent_statistics: (payload.agent_statistics) ? payload.agent_statistics : state.agent_statistics,
				agency_statistics: (payload.agency_statistics) ? payload.agency_statistics : state.agency_statistics,
				customer_statistics: (payload.customer_statistics) ? payload.customer_statistics : state.customer_statistics,
				completed_statistics: (payload.completed_statistics) ? payload.completed_statistics : state.completed_statistics,
				refunded_statistics: (payload.refunded_statistics) ? payload.refunded_statistics : state.refunded_statistics,
				prices_statistics: (payload.prices_statistics) ? payload.prices_statistics : state.prices_statistics,
				error: (payload.error) ? payload.error : state.error,
				option: (payload.option) ? payload.option : state.option,
				loading: (payload.loading) ? payload.loading : state.loading,
				success: (payload.success) ? payload.success : state.success
			}

		case STATISTICS_RESET:
			return {
				...state,
				all_statistics: (payload.all) ? INITIAL_STATE.all_statistics : state.all_statistics,
				agent_statistics: (payload.all) ? INITIAL_STATE.agent_statistics : state.agent_statistics,
				agency_statistics: (payload.all) ? INITIAL_STATE.agency_statistics : state.agency_statistics,
				customer_statistics: (payload.all) ? INITIAL_STATE.customer_statistics : state.customer_statistics,
				completed_statistics: (payload.all) ? INITIAL_STATE.completed_statistics : state.completed_statistics,
				refunded_statistics: (payload.all) ? INITIAL_STATE.refunded_statistics : state.refunded_statistics,
				prices_statistics: (payload.all) ? INITIAL_STATE.prices_statistics : state.prices_statistics,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}
}

export default statistiques