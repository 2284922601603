// GET
export const STATISTICS_GET = "STATISTICS_GET"
export const STATISTICS_GET_CUSTOMERS = "STATISTICS_GET_CUSTOMERS"
export const STATISTICS_GET_COMPLETED_ORDERS = "STATISTICS_GET_COMPLETED_ORDERS"
export const STATISTICS_GET_REFUNDED_ORDERS = "STATISTICS_GET_REFUNDED_ORDERS"
export const STATISTICS_GET_AGENCY_ORDERS = "STATISTICS_GET_AGENCY_ORDERS"
export const STATISTICS_GET_ACTIVES_AGENTS = "STATISTICS_GET_ACTIVES_AGENTS"
export const STATISTICS_GET_ORDERS_BY_PRICES = "STATISTICS_GET_ORDERS_BY_PRICES"

// ERROR, SUCCESS, SET, RESET
export const STATISTICS_ERROR = "STATISTICS_ERROR"
export const STATISTICS_SUCCESS = "STATISTICS_SUCCESS"
export const STATISTICS_SET = "STATISTICS_SET"
export const STATISTICS_RESET = "STATISTICS_RESET"