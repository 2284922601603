import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from "reactstrap"

// import custom table component, constants
import CustomDatatable, { CLASSES, LABELS, TOTAL_PER_PAGE_SM, TOTAL_PER_PAGE_OPTIONS_SM } from 'components/lists/CustomDatatable';
import Loader from 'components/common/Loader';
import { calculateDuration, toDaysMinutesSeconds } from 'helpers/utilities';


const DurationTable = ({ opened, modalOption, modalClass, modalError, modalLoading, onClosed, onShowed, items }) => {

    const tableColumns = [
        {
            prop: "code",
            title: "Code",
            filterable: true,
            sortable: true,
            cellProps: {
                className: row => ("text-center")
            },
            cell: row => {
                return (
                    <span
                        className="fw-bold text-primary text-decoration-underline h-cursor"
                        onClick={() => onShowed(row)}
                    >
                        {row.code}
                    </span>
                );
            }
        },
        {
            prop: "start_point",
            title: "Enlèvement",
            cellProps: {
                className: row => ("text-center")
            },
            cell: row => {
                return (
                    <span className="ellipsis-text">
                        {row.start_point.address}
                    </span>
                );
            }
        },
        {
            prop: "delivery_point",
            title: "Livraison",
            cellProps: {
                className: row => ("text-center")
            },
            cell: row => {
                return (
                    <span className="ellipsis-text">
                        {row.delivery_point.address}
                    </span>
                );
            }
        },
        {
            prop: "created_at",
            title: "Durée de livraison",
            cellProps: {
                className: row => ("text-center")
            },
            cell: row => {
                return (
                    <span className="ellipsis-text">
                        {toDaysMinutesSeconds((row.completed_at - row.created_at))}
                    </span>
                );
            }
        },
    ];

    return (
        <React.Fragment>
            <Modal isOpen={opened} centered={true} className={`${modalClass} modal-fullscreen`} size="xl">
                <ModalHeader tag="h4">
                    Liste des courses avec durée de livraison
                </ModalHeader>
                <ModalBody>
                    {(modalLoading) ? (
                        <Row>
                            <Col xl="12">
                                <Loader color="primary" />
                            </Col>
                        </Row>
                    ) : (modalError && (modalError !== "")) ? (
                        <Row>
                            <Col xl="12">
                                <Alert color="danger">{modalError}</Alert>
                            </Col>
                        </Row>
                    ) : (
                        <CustomDatatable
                            tableHeaders={tableColumns}
                            tableBody={items}
                            rowsPerPage={TOTAL_PER_PAGE_SM}
                            rowsPerPageOption={TOTAL_PER_PAGE_OPTIONS_SM}
                            initialSort={{ prop: '', isAscending: true }}
                            labels={{ ...LABELS, entries: `sur ${items.length} courses` }}
                            classes={CLASSES}
                            leftOptions={{ leftAction: false, label: "", onClicked: {} }}
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-light"
                        onClick={() => onClosed()}
                    >
                        Fermer
                    </button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

DurationTable.propTypes = {
    opened: PropTypes.bool.isRequired,
    modalClass: PropTypes.string,
    modalOption: PropTypes.string,
    modalError: PropTypes.string,
    modalLoading: PropTypes.bool,
    onClosed: PropTypes.func.isRequired,
    items: PropTypes.array
}
DurationTable.defaultProps = {
    modalClass: "duration-list-modal",
    modalOption: "",
    modalError: "",
    modalLoading: false,
    items: []
}
export default DurationTable