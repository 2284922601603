import { CUSTOMERS_ERROR, CUSTOMERS_GET, CUSTOMERS_INSERT, CUSTOMERS_RESET, CUSTOMERS_SELECT, CUSTOMERS_SET, CUSTOMERS_SUCCESS, CUSTOMERS_UPDATE } from "./constants"


export const listCustomers = (partners_only, is_not_root, option) => ({
    type: CUSTOMERS_SELECT,
    payload: { partners_only, is_not_root, option },
})

export const showCustomer = (id, with_details, option) => ({
    type: CUSTOMERS_GET,
    payload: { id, with_details, option },
})

export const addCustomer = (data, option) => ({
    type: CUSTOMERS_INSERT,
    payload: { data, option },
})

export const editCustomer = (data, id, option) => ({
    type: CUSTOMERS_UPDATE,
    payload: { data, id, option },
})

export const successCustomers = (data, option) => ({
    type: CUSTOMERS_SUCCESS,
    payload: { data, option },
})

export const errorCustomers = (error = "", option = "") => ({
    type: CUSTOMERS_ERROR,
    payload: { error, option },
})

export const setCustomers = (data) => ({
    type: CUSTOMERS_SET,
    payload: { ...data },
})

export const resetCustomers = (all) => ({
    type: CUSTOMERS_RESET,
    payload: { all },
})