import { LOGGING_ERROR, LOGGING_RESET, LOGGING_SELECT, LOGGING_SET, LOGGING_SUCCESS } from "./constants"


const INITIAL_STATE = {
	loggings: [],
	error: "",
	option: "",
	loading: false,
	success: false
}

const journalisation = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case LOGGING_SELECT:
			return {
				...state,
				error: "",
				option: payload.option,
				loading: true,
				success: false
			}

		case LOGGING_SUCCESS:
			return {
				...state,
				loggings: payload.data.loggings ? payload.data.loggings.reverse() : state.loggings,
				option: payload.option,
				loading: false,
				success: true
			}

		case LOGGING_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case LOGGING_SET:
			return {
				...state,
				loggings: (payload.loggings) ? payload.loggings : state.loggings,
				error: (payload.error) ? payload.error : state.error,
				option: (payload.option) ? payload.option : state.option,
				loading: (payload.loading) ? payload.loading : state.loading,
				success: (payload.success) ? payload.success : state.success
			}

		case LOGGING_RESET:
			return {
				...state,
				loggings: (payload.all) ? INITIAL_STATE.loggings : state.loggings,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}
}

export default journalisation