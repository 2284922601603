import React from 'react'
import PropTypes from 'prop-types'

// import utilities
import { formatTimeToString } from 'helpers/utilities';


const Recorder = ({ record, handlers, showIndicator }) => {
    const { recordingMinutes, recordingSeconds, initRecording } = record;
    const { startRecording, saveRecording, cancelRecording } = handlers;

    return (
        <React.Fragment>
            <div className="recorder-container">
                <div className="recorder-controls">
                    {initRecording ? (
                        <button
                            type="button"
                            className="btn-recorder btn-save"
                            title="Enregistrer l'audio"
                            disabled={recordingSeconds === 0}
                            onClick={saveRecording}
                        >
                            <i className="bx bxs-save" />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn-recorder btn-start"
                            title="Commencer l'audio"
                            onClick={startRecording}
                        >
                            <i className="bx bxs-microphone" />
                        </button>
                    )}
                    {initRecording && (
                        <button
                            type="button"
                            className="btn-recorder btn-cancel ms-1"
                            title="Supprimer l'audio"
                            onClick={cancelRecording}
                        >
                            <i className="bx bx-x" />
                        </button>
                    )}
                </div>
                <div className="recorder-timer">
                    <div className="recording-time">
                        <span>{formatTimeToString(recordingMinutes)}</span>
                        <span>:</span>
                        <span>{formatTimeToString(recordingSeconds)}</span>
                    </div>
                    {showIndicator && initRecording && <div className="recording-indicator ms-1" />}
                </div>
            </div>
        </React.Fragment>
    )
}
Recorder.propTypes = {
    showIndicator: PropTypes.bool
}
Recorder.defaultProps = {
    showIndicator: false
}
export default Recorder