import React from 'react'
import PropTypes from 'prop-types'
import { getConstantObject } from 'helpers/utilities'
import { USER_AGENCY, USER_FACTOR } from 'helpers/constants'

const AgentHeader = ({ avatar, name, role, vehicle_type, contact_point }) => {

    const agent_role = role ? getConstantObject(role) : {}
    const agent_vehicule = vehicle_type ? getConstantObject(vehicle_type) : {}

    return (
        <React.Fragment>
            <div className="float-end mb-3">
                <div className="center-box">
                    <div className="d-flex align-items-center">
                        <div className="me-2">
                            <h4 className="text-truncate mb-2">
                                {name ? name : ""}
                            </h4>
                            {(role === USER_FACTOR.key) ? (
                                <p className="mb-0 fs-12 text-end">
                                    {USER_FACTOR.name} {" "} {" "}
                                    <i className={`${agent_vehicule.icon} text-muted`} />
                                </p>
                            ) : ((role === USER_AGENCY.key)) ? (
                                <p className="text-truncate mb-0 fs-12 text-end">
                                    {(contact_point) ? "Point contact" : "Point relais"}
                                </p>
                            ) : null}
                        </div>
                        {(avatar) ? (
                            <div className={`ms-2 avatar-sm img-badge bg-${agent_role.color}`}>
                                <img
                                    src={avatar}
                                    alt="agent avatar"
                                    className="rounded"
                                />
                            </div>
                        ) : (
                            <div className="ms-2 avatar-sm">
                                <span className={`rounded avatar-title fs-20 fw-bold text-center text-${agent_role.color} bg-soft bg-${agent_role.color}`}>
                                    {`${name.charAt(0).toUpperCase()}${name.charAt(1).toUpperCase()}`}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

AgentHeader.propTypes = {}
export default AgentHeader