import API, { ORDER_BASE_URL } from './baseService';

export function selectIssues(enabled_option) {
    return API.get(`${ORDER_BASE_URL}incidents/list?enabled_only=${enabled_option}`).then((response) => response).catch((error) => error);
}

export function getIssue(id) {
    return API.get(`${ORDER_BASE_URL}incidents/${id}`).then((response) => response).catch((error) => error);
}

export function insertIssue(data) {
    return API.post(`${ORDER_BASE_URL}incidents`, data).then((response) => response).catch((error) => error);
}

export function updateIssue(id, data) {
    return API.put(`${ORDER_BASE_URL}incidents/${id}`, data).then((response) => response).catch((error) => error);
}