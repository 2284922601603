import API, { ORDER_BASE_URL } from './baseService';

export function selectOrders(option = "current_user", for_user = false) {
    if (for_user) {
        return API.get(`${ORDER_BASE_URL}orders/of/user/${option}`).then((response) => response).catch((error) => error);
    } else if (option.toLowerCase() === "current_user") {
        return API.get(`${ORDER_BASE_URL}orders/current/user`).then((response) => response).catch((error) => error);
    } else {
        return API.get(`${ORDER_BASE_URL}orders/list?status=${option}`).then((response) => response).catch((error) => error);
    }
}

export function getOrder(order, option = "id") {
    let get_option = ((option === "id") ? "detail" : "code")
    return API.get(`${ORDER_BASE_URL}orders/${get_option}/${order}`).then((response) => response).catch((error) => error);
}

export function insertOrder(data) {
    let customConfigs = { headers: { 'content-type': 'multipart/form-data' } }
    return API.post(`${ORDER_BASE_URL}orders/new`, data, customConfigs).then((response) => response).catch((error) => error);
}

export function createOrder(data) {
    let customConfigs = { headers: { 'content-type': 'multipart/form-data' } }
    return API.post(`${ORDER_BASE_URL}orders/create`, data, customConfigs).then((response) => response).catch((error) => error);
}

export function updateOrder(id, data) {
    return API.put(`${ORDER_BASE_URL}orders/${id}`, data).then((response) => response).catch((error) => error);
}

export function attachOrder(id, data) {
    return API.put(`${ORDER_BASE_URL}manage/orders/${id}/assign`, data).then((response) => response).catch((error) => error);
}

export function forwardOrder(data, accept = false) {
    if (accept) {
        return API.put(`${ORDER_BASE_URL}forwards/accept`, data).then((response) => response).catch((error) => error);
    } else {
        return API.post(`${ORDER_BASE_URL}forwards/init`, data).then((response) => response).catch((error) => error);
    }
}

export function refundOrder(id, data) {
    return API.put(`${ORDER_BASE_URL}orders/refund/${id}`, data).then((response) => response).catch((error) => error);
}

export function paymentOrder(data) {
    return API.patch(`${ORDER_BASE_URL}orders/payment`, data).then((response) => response).catch((error) => error);
}

export function findOrder(started_at, ended_at, phone, partner) {
    return API.get(`${ORDER_BASE_URL}orders/find?start_date=${started_at}&end_date=${ended_at}&customer_phone=${phone}&partner=${partner}`).then((response) => response).catch((error) => error);
}

export function completeOrder(id, data) {
    return API.put(`${ORDER_BASE_URL}manage/orders/${id}/complete`, data).then((response) => response).catch((error) => error);
}

