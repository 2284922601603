import React from "react"
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert, Input, Label } from "reactstrap"

// import form components
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"

// Form Mask
import InputMask from "react-input-mask"

// import custom components
import Loader from '../../components/common/Loader'

// import constants
import { MASTERS_ADD, MASTERS_EDIT, PASSWORD_MESSAGE, MAX_TEXT_INPUT, MIN_TEXT_INPUT } from "helpers/constants"


const MasterForm = React.memo(({ opened, modalOption, modalClass, modalError, modalLoading, onClosed, onSubmited, onChecked, onGenerated, master }) => {

    return (
        <Modal isOpen={opened} centered={true} className={modalClass}>
            <ModalHeader tag="h4">
                {(modalOption === MASTERS_EDIT) ? "Modifier un super administrateur" : "Ajouter un super administrateur"}
            </ModalHeader>
            <AvForm onValidSubmit={onSubmited}>
                <ModalBody>
                    <Row>
                        {(modalError && modalError !== "") ? (
                            <Col xl="12">
                                <Alert color="danger">{modalError}</Alert>
                            </Col>
                        ) : null}
                        
                        <Col xl="6" className="mb-3">
                            <AvField
                                name="firstname"
                                label="Prénom"
                                type="text"
                                value={master.firstname || ""}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Le prénom est obligatoire.',
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`Le prénom a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                        </Col>

                        <Col xl="6" className="mb-3">
                            <AvField
                                name="lastname"
                                label="Nom"
                                type="text"
                                value={master.lastname || ""}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'Le nom est obligatoire.',
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`Le nom a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                        </Col>

                        <Col xl="6" className="mb-3">
                            <AvField
                                type="text"
                                name="phone"
                                label="Numéro de téléphone"
                                value={master.phone || ""}
                                mask="(+22\9) 99 99 99 99"
                                maskChar="-"
                                tag={[Input, InputMask]}
                                validate={{
                                    minLength: {
                                        value: 8,
                                        errorMessage: 'Le numéro de téléphone a au moins 8 caractères.'
                                    },
                                    required: {
                                        value: true,
                                        errorMessage: 'Le numéro de téléphone est obligatoire.',
                                    },
                                }}
                            />
                        </Col>

                        <Col xl="6" className="mb-3">
                            <AvField
                                name="email"
                                label="Email"
                                type="email"
                                value={master.email || ""}
                                validate={{
                                    email: {
                                        value: true,
                                        errorMessage: 'L\'email est incorrect.',
                                    },
                                    required: {
                                        value: true,
                                        errorMessage: 'L\'email est obligatoire.',
                                    },
                                }}
                            />
                        </Col>

                        <Col xl="12" className="mb-3">
                            <AvField
                                name="username"
                                label="Identifiant"
                                type="text"
                                value={master.username || ""}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: 'L\'identifiant est obligatoire.',
                                    },
                                    minLength: {
                                        value: MIN_TEXT_INPUT,
                                        errorMessage: (`L\'identifiant a au moins ${MIN_TEXT_INPUT} caractères.`)
                                    }
                                }}
                            />
                        </Col>

                        <Col xl="12" className="mb-3">
                            <AvGroup>
                                <Label> Mot de passe </Label>
                                <div className="input-group">
                                    <AvInput
                                        required={(modalOption === MASTERS_ADD)}
                                        name="password"
                                        label="Mot de passe"
                                        type="text"
                                        aria-describedby="password-help-block"
                                        aria-describedby="addon-generate-password"
                                        value={master.password || ""}
                                    />
                                    <span
                                        id="addon-generate-password"
                                        className="input-group-text h-cursor"
                                        onClick={() => onGenerated()}
                                    >
                                        <i className="text-muted bx bx-xs bx-revision" />
                                    </span>
                                    <span id="password-help-block" className="form-text">
                                        {PASSWORD_MESSAGE}
                                    </span>
                                </div>
                            </AvGroup>
                        </Col>

                        <Col xl="12" className="mb-1">
                            <div className="form-group">
                                <div className={`custom-check${(master.enabled) ? " active" : ""}`}>
                                    <div className="form-check form-switch form-switch-md check-control">
                                        <input
                                            name="enabled"
                                            type="checkbox"
                                            className="form-check-input"
                                            id="enabledSwitch"
                                            checked={master.enabled || false}
                                            onChange={(e) => onChecked(e)}
                                        />
                                        <label className="form-check-label" htmlFor="enabledSwitch">
                                            <span className={`${(master.enabled) ? "fw-bolder" : "fw-light"}`}>
                                                {(master.enabled) ? "Actif" : "Inactif"}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {(modalLoading) ? (
                        <Loader color={`${(modalOption === MASTERS_EDIT) ? "warning" : "info"}`} />
                    ) : (
                        <>
                            <button type="button" data-dismiss="modal" className="btn btn-light" onClick={() => onClosed()}>
                                Fermer
                            </button>
                            <button type="submit" className={`btn btn-${(modalOption === MASTERS_EDIT) ? "warning" : "info"} save-master`}>
                                {
                                    (modalOption === MASTERS_EDIT) ? "Modifier" : "Ajouter"
                                }
                            </button>
                        </>
                    )}
                </ModalFooter>
            </AvForm>
        </Modal>
    );

});
MasterForm.propTypes = {
    opened: PropTypes.bool.isRequired,
    modalClass: PropTypes.string,
    modalOption: PropTypes.string,
    modalError: PropTypes.string,
    modalLoading: PropTypes.bool,
    onChecked: PropTypes.func.isRequired,
    onClosed: PropTypes.func.isRequired,
    onSubmited: PropTypes.func.isRequired,
    onGenerated: PropTypes.func.isRequired,
    master: PropTypes.object
}
MasterForm.defaultProps = {
    modalClass: "masters-form-modal",
    modalOption: "",
    modalError: "",
    modalLoading: false,
    master: {}
}
export default MasterForm