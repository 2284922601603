import {
    INVOICES_SELECT, INVOICES_GET, INVOICES_UPDATE, INVOICES_RESET, INVOICES_SET, INVOICES_SUCCESS,
    INVOICES_ERROR, INVOICES_FIND, INVOICES_FILTER, INVOICES_INSERT, INVOICES_GENERATE, INVOICES_SET_PAID
} from "./constants"


export const listInvoices = (month, year, option) => ({
    type: INVOICES_SELECT,
    payload: { month, year, option },
})

export const showInvoice = (id, option) => ({
    type: INVOICES_GET,
    payload: { id, option },
})

export const searchInvoices = (reference, month, year, option) => ({
    type: INVOICES_FIND,
    payload: { reference, month, year, option },
})

export const addInvoice = (data, option) => ({
    type: INVOICES_INSERT,
    payload: { data, option },
})

export const editInvoice = (data, id, option) => ({
    type: INVOICES_UPDATE,
    payload: { data, id, option },
})

export const regenerateInvoice = (data, option) => ({
    type: INVOICES_GENERATE,
    payload: { data, option },
})

export const paidInvoice = (data, month, year, option) => ({
    type: INVOICES_SET_PAID,
    payload: { data, month, year, option },
})

export const successInvoices = (data, option) => ({
    type: INVOICES_SUCCESS,
    payload: { data, option },
})

export const errorInvoices = (error = "", option = "") => ({
    type: INVOICES_ERROR,
    payload: { error, option },
})

export const filterInvoices = (filter, reset = false) => ({
    type: INVOICES_FILTER,
    payload: { filter, reset },
})

export const setInvoices = (data) => ({
    type: INVOICES_SET,
    payload: { ...data },
})

export const resetInvoices = (all) => ({
    type: INVOICES_RESET,
    payload: { all },
})