import React from 'react'
import PropTypes from 'prop-types'

// import helmet
import { Helmet } from "react-helmet"

// import components
import { Container, Row, Col, Alert } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import { addUser, editUser, listUsers, resetUsers, setUsers } from 'store/actions'

// import router
import { withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import constants
import {
    APP_NAME, SUPERVISORS_ADD, SUPERVISORS_EDIT, SUPERVISORS_LIST, USER_SUPERVISOR
} from 'helpers/constants'

// import custom components
import SupervisorTable from './SupervisorTable'
import SupervisorForm from './SupervisorForm'

// import utilities
import {
    consoleErrorMessage, formatPhoneNumberForDisplay, formatPhoneNumberForRequest, generateRandomCode
} from 'helpers/utilities'


class Supervisors extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            formVisible: false
        }
    }

    componentDidMount() {
        try {// Load supervisors
            let { onListSupervisors } = this.props
            onListSupervisors(SUPERVISORS_LIST)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {
            //Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onResetSupervisors } = this.props

                if (success) {
                    // Close modals
                    if ([SUPERVISORS_ADD, SUPERVISORS_EDIT].includes(option)) this.onToggled()

                    // Display toasts
                    if (option === SUPERVISORS_ADD) {
                        toast.success("Nouveau superviseur ajouté avec succès.")
                    } else if (option === SUPERVISORS_EDIT) {
                        toast.success("Superviseur mis à jour avec succès.")
                    }

                    //Reset global state
                    onResetSupervisors(false)
                }
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetSupervisors(true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Toggle form modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "formVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On checked.
     * 
     * @param {*} event 
     */
    onChecked = (event) => {
        try {
            //Get target
            let { name, checked } = event.target

            //Get props
            let { supervisor, onSetSupervisors } = this.props

            //Store values
            onSetSupervisors({
                user: { ...supervisor, [name]: checked }
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On generated.
     * 
     */
    onGenerated = () => {
        try {
            //Get props
            let { supervisor, onSetSupervisors } = this.props

            //Store values
            onSetSupervisors({
                user: { ...supervisor, password: generateRandomCode(8, true) }
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
    * On submited.
    * 
    * @param {*} e 
    * @param {*} values 
    */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {//Start treatment
            let { onAddSupervisor, onEditSupervisor, option, supervisor } = this.props

            switch (option) {
                case SUPERVISORS_ADD:
                    onAddSupervisor({
                        firstname: values.firstname.trim(),
                        lastname: values.lastname.trim(),
                        phone: formatPhoneNumberForRequest(values.phone.trim()),
                        email: values.email.trim(),
                        username: values.username.trim(),
                        password: values.password.trim(),
                        enabled: supervisor.enabled
                    }, option)
                    break;
                case SUPERVISORS_EDIT:
                    onEditSupervisor({
                        firstname: values.firstname.trim(),
                        lastname: values.lastname.trim(),
                        phone: formatPhoneNumberForRequest(values.phone.trim()),
                        email: values.email.trim(),
                        username: values.username.trim(),
                        password: values.password.trim(),
                        enabled: supervisor.enabled
                    }, supervisor._id, option)
                    break;
                default:
                    console.log("UNKNOW MODAL OPTION " + option)
                    break;
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Add.
     * 
     */
    onAdding = () => {
        try {
            //Get props
            let { onSetSupervisors, onResetSupervisors } = this.props

            //Reset state
            onResetSupervisors(false)

            //Init state
            onSetSupervisors({ user: { enabled: true }, option: SUPERVISORS_ADD })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Edit.
     * 
     * @param {*} item 
     */
    onEditing = (item) => {
        try {
            //Get props
            let { onSetSupervisors, onResetSupervisors } = this.props

            //Reset state
            onResetSupervisors(false)

            //Init state
            onSetSupervisors({
                user: { ...item, phone: formatPhoneNumberForDisplay(item.phone) },
                option: SUPERVISORS_EDIT
            })

            //Show modal
            this.onToggled("formVisible", true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On loaded.
     * 
     */
    onLoaded = () => {
        try {
            let { supervisors, onResetSupervisors, onListSupervisors } = this.props
            if (supervisors.length > 0) onResetSupervisors(true)
            onListSupervisors(SUPERVISORS_LIST)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }


    render() {
        const { t, supervisors, supervisor, error, option, loading } = this.props
        const { formVisible } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Supervisors")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Supervisors")}
                            subtitle={(supervisors.length > 0) ? (`(${supervisors.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === SUPERVISORS_LIST)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : null}

                                {(loading && (option === SUPERVISORS_LIST)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <SupervisorTable
                                        items={supervisors}
                                        onAdding={this.onAdding}
                                        onEditing={this.onEditing}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(formVisible) ? (
                    <SupervisorForm
                        opened={formVisible}
                        option={[SUPERVISORS_ADD, SUPERVISORS_EDIT].includes(option) ? option : ""}
                        error={[SUPERVISORS_ADD, SUPERVISORS_EDIT].includes(option) ? error : ""}
                        loading={[SUPERVISORS_ADD, SUPERVISORS_EDIT].includes(option) ? loading : false}
                        onClosed={this.onToggled}
                        onSubmited={this.onSubmited}
                        onChecked={this.onChecked}
                        onGenerated={this.onGenerated}
                        supervisor={supervisor}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
Supervisors.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    supervisors: PropTypes.array,
    supervisor: PropTypes.object,
    onListSupervisors: PropTypes.func,
    onSetSupervisors: PropTypes.func,
    onResetSupervisors: PropTypes.func,
    onAddSupervisor: PropTypes.func,
    onEditSupervisor: PropTypes.func
}
const mapStateToProps = state => ({
    supervisors: state.utilisateurs.supervisors,
    supervisor: state.utilisateurs.user,
    error: state.utilisateurs.error,
    option: state.utilisateurs.option,
    success: state.utilisateurs.success,
    loading: state.utilisateurs.loading
})
const mapDispatchToProps = dispatch => ({
    onSetSupervisors: (data) => dispatch(setUsers(data)),
    onResetSupervisors: (all) => dispatch(resetUsers(all)),
    onListSupervisors: (option) => dispatch(listUsers(USER_SUPERVISOR, "", false, false, option)),
    onAddSupervisor: (data, option) => dispatch(addUser(data, USER_SUPERVISOR, option)),
    onEditSupervisor: (data, id, option) => dispatch(editUser(data, id, USER_SUPERVISOR, true, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Supervisors)))